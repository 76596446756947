/* eslint-disable */
// source: vivacity/core/occupancy_based_turning_movement.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
goog.exportSymbol('proto.vivacity.core.OccupancyBasedTurningMovement', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.OccupancyBasedTurningMovement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.OccupancyBasedTurningMovement.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.OccupancyBasedTurningMovement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.OccupancyBasedTurningMovement.displayName = 'proto.vivacity.core.OccupancyBasedTurningMovement';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.OccupancyBasedTurningMovement.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.OccupancyBasedTurningMovement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.OccupancyBasedTurningMovement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.OccupancyBasedTurningMovement.toObject = function(includeInstance, msg) {
  var f, obj = {
    startZoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endZoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    intermediateZoneIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    startTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    trackNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    classId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.OccupancyBasedTurningMovement;
  return proto.vivacity.core.OccupancyBasedTurningMovement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.OccupancyBasedTurningMovement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartZoneId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndZoneId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIntermediateZoneIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimestampMicroseconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndTimestampMicroseconds(value);
      break;
    case 6:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 10:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClassId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.OccupancyBasedTurningMovement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.OccupancyBasedTurningMovement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.OccupancyBasedTurningMovement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartZoneId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEndZoneId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIntermediateZoneIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
  f = message.getStartTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getEndTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getClassId();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional uint32 start_zone_id = 1;
 * @return {number}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getStartZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setStartZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 end_zone_id = 2;
 * @return {number}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getEndZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setEndZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated uint32 intermediate_zone_ids = 3;
 * @return {!Array<number>}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getIntermediateZoneIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setIntermediateZoneIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.addIntermediateZoneIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.clearIntermediateZoneIdsList = function() {
  return this.setIntermediateZoneIdsList([]);
};


/**
 * optional uint64 start_timestamp_microseconds = 4;
 * @return {number}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getStartTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setStartTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 end_timestamp_microseconds = 5;
 * @return {number}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getEndTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setEndTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional TracingContext tracing_ctx = 6;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 6));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
*/
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 restart_number = 7;
 * @return {number}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 track_number = 8;
 * @return {number}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 vision_program_id = 9;
 * @return {number}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional ClassifyingDetectorClassTypes class_id = 10;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.getClassId = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.OccupancyBasedTurningMovement} returns this
 */
proto.vivacity.core.OccupancyBasedTurningMovement.prototype.setClassId = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


goog.object.extend(exports, proto.vivacity.core);
