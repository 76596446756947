/* eslint-disable */
// source: vivacity/benchmarking/benchmark_run.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.vivacity.benchmarking.AnprResults', null, global);
goog.exportSymbol('proto.vivacity.benchmarking.BenchmarkRun', null, global);
goog.exportSymbol('proto.vivacity.benchmarking.BenchmarkRun.BenchmarkType', null, global);
goog.exportSymbol('proto.vivacity.benchmarking.CountsResults', null, global);
goog.exportSymbol('proto.vivacity.benchmarking.DetectorResults', null, global);
goog.exportSymbol('proto.vivacity.benchmarking.TrackerResults', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.benchmarking.DetectorResults = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.benchmarking.DetectorResults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.benchmarking.DetectorResults.displayName = 'proto.vivacity.benchmarking.DetectorResults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.benchmarking.TrackerResults = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.benchmarking.TrackerResults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.benchmarking.TrackerResults.displayName = 'proto.vivacity.benchmarking.TrackerResults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.benchmarking.CountsResults = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.benchmarking.CountsResults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.benchmarking.CountsResults.displayName = 'proto.vivacity.benchmarking.CountsResults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.benchmarking.AnprResults = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.benchmarking.AnprResults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.benchmarking.AnprResults.displayName = 'proto.vivacity.benchmarking.AnprResults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.benchmarking.BenchmarkRun = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.benchmarking.BenchmarkRun, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.benchmarking.BenchmarkRun.displayName = 'proto.vivacity.benchmarking.BenchmarkRun';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.benchmarking.DetectorResults.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.benchmarking.DetectorResults.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.benchmarking.DetectorResults} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.DetectorResults.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pipelineAccuracy: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    classificationAccuracy: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    detectorRecall: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    detectorAccuracy: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.benchmarking.DetectorResults}
 */
proto.vivacity.benchmarking.DetectorResults.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.benchmarking.DetectorResults;
  return proto.vivacity.benchmarking.DetectorResults.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.benchmarking.DetectorResults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.benchmarking.DetectorResults}
 */
proto.vivacity.benchmarking.DetectorResults.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReport(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPipelineAccuracy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClassificationAccuracy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDetectorRecall(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDetectorAccuracy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.benchmarking.DetectorResults.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.benchmarking.DetectorResults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.benchmarking.DetectorResults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.DetectorResults.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPipelineAccuracy();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getClassificationAccuracy();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDetectorRecall();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDetectorAccuracy();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string report = 1;
 * @return {string}
 */
proto.vivacity.benchmarking.DetectorResults.prototype.getReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.DetectorResults} returns this
 */
proto.vivacity.benchmarking.DetectorResults.prototype.setReport = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float pipeline_accuracy = 2;
 * @return {number}
 */
proto.vivacity.benchmarking.DetectorResults.prototype.getPipelineAccuracy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.DetectorResults} returns this
 */
proto.vivacity.benchmarking.DetectorResults.prototype.setPipelineAccuracy = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float classification_accuracy = 3;
 * @return {number}
 */
proto.vivacity.benchmarking.DetectorResults.prototype.getClassificationAccuracy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.DetectorResults} returns this
 */
proto.vivacity.benchmarking.DetectorResults.prototype.setClassificationAccuracy = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float detector_recall = 4;
 * @return {number}
 */
proto.vivacity.benchmarking.DetectorResults.prototype.getDetectorRecall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.DetectorResults} returns this
 */
proto.vivacity.benchmarking.DetectorResults.prototype.setDetectorRecall = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float detector_accuracy = 5;
 * @return {number}
 */
proto.vivacity.benchmarking.DetectorResults.prototype.getDetectorAccuracy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.DetectorResults} returns this
 */
proto.vivacity.benchmarking.DetectorResults.prototype.setDetectorAccuracy = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.benchmarking.TrackerResults.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.benchmarking.TrackerResults.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.benchmarking.TrackerResults} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.TrackerResults.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalIdSwitches: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalIdSwitchesRecallAdjusted: jspb.Message.getFieldWithDefault(msg, 3, 0),
    weightedMota: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    weightedMotp: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.benchmarking.TrackerResults}
 */
proto.vivacity.benchmarking.TrackerResults.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.benchmarking.TrackerResults;
  return proto.vivacity.benchmarking.TrackerResults.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.benchmarking.TrackerResults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.benchmarking.TrackerResults}
 */
proto.vivacity.benchmarking.TrackerResults.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReport(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalIdSwitches(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalIdSwitchesRecallAdjusted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightedMota(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightedMotp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.benchmarking.TrackerResults.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.benchmarking.TrackerResults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.benchmarking.TrackerResults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.TrackerResults.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalIdSwitches();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotalIdSwitchesRecallAdjusted();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getWeightedMota();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getWeightedMotp();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional string report = 1;
 * @return {string}
 */
proto.vivacity.benchmarking.TrackerResults.prototype.getReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.TrackerResults} returns this
 */
proto.vivacity.benchmarking.TrackerResults.prototype.setReport = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 total_id_switches = 2;
 * @return {number}
 */
proto.vivacity.benchmarking.TrackerResults.prototype.getTotalIdSwitches = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.TrackerResults} returns this
 */
proto.vivacity.benchmarking.TrackerResults.prototype.setTotalIdSwitches = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_id_switches_recall_adjusted = 3;
 * @return {number}
 */
proto.vivacity.benchmarking.TrackerResults.prototype.getTotalIdSwitchesRecallAdjusted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.TrackerResults} returns this
 */
proto.vivacity.benchmarking.TrackerResults.prototype.setTotalIdSwitchesRecallAdjusted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float weighted_mota = 4;
 * @return {number}
 */
proto.vivacity.benchmarking.TrackerResults.prototype.getWeightedMota = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.TrackerResults} returns this
 */
proto.vivacity.benchmarking.TrackerResults.prototype.setWeightedMota = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float weighted_motp = 5;
 * @return {number}
 */
proto.vivacity.benchmarking.TrackerResults.prototype.getWeightedMotp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.TrackerResults} returns this
 */
proto.vivacity.benchmarking.TrackerResults.prototype.setWeightedMotp = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.benchmarking.CountsResults.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.benchmarking.CountsResults.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.benchmarking.CountsResults} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.CountsResults.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: jspb.Message.getFieldWithDefault(msg, 1, ""),
    passRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    precision: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    recall: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.benchmarking.CountsResults}
 */
proto.vivacity.benchmarking.CountsResults.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.benchmarking.CountsResults;
  return proto.vivacity.benchmarking.CountsResults.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.benchmarking.CountsResults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.benchmarking.CountsResults}
 */
proto.vivacity.benchmarking.CountsResults.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReport(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPassRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrecision(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRecall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.benchmarking.CountsResults.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.benchmarking.CountsResults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.benchmarking.CountsResults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.CountsResults.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassRate();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getPrecision();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getRecall();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string report = 1;
 * @return {string}
 */
proto.vivacity.benchmarking.CountsResults.prototype.getReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.CountsResults} returns this
 */
proto.vivacity.benchmarking.CountsResults.prototype.setReport = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float pass_rate = 2;
 * @return {number}
 */
proto.vivacity.benchmarking.CountsResults.prototype.getPassRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.CountsResults} returns this
 */
proto.vivacity.benchmarking.CountsResults.prototype.setPassRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float precision = 3;
 * @return {number}
 */
proto.vivacity.benchmarking.CountsResults.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.CountsResults} returns this
 */
proto.vivacity.benchmarking.CountsResults.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float recall = 4;
 * @return {number}
 */
proto.vivacity.benchmarking.CountsResults.prototype.getRecall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.CountsResults} returns this
 */
proto.vivacity.benchmarking.CountsResults.prototype.setRecall = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.benchmarking.AnprResults.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.benchmarking.AnprResults.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.benchmarking.AnprResults} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.AnprResults.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meanJaroScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    pipelineAccuracy: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.benchmarking.AnprResults}
 */
proto.vivacity.benchmarking.AnprResults.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.benchmarking.AnprResults;
  return proto.vivacity.benchmarking.AnprResults.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.benchmarking.AnprResults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.benchmarking.AnprResults}
 */
proto.vivacity.benchmarking.AnprResults.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReport(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeanJaroScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPipelineAccuracy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.benchmarking.AnprResults.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.benchmarking.AnprResults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.benchmarking.AnprResults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.AnprResults.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeanJaroScore();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getPipelineAccuracy();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string report = 1;
 * @return {string}
 */
proto.vivacity.benchmarking.AnprResults.prototype.getReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.AnprResults} returns this
 */
proto.vivacity.benchmarking.AnprResults.prototype.setReport = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float mean_jaro_score = 2;
 * @return {number}
 */
proto.vivacity.benchmarking.AnprResults.prototype.getMeanJaroScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.AnprResults} returns this
 */
proto.vivacity.benchmarking.AnprResults.prototype.setMeanJaroScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float pipeline_accuracy = 3;
 * @return {number}
 */
proto.vivacity.benchmarking.AnprResults.prototype.getPipelineAccuracy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.AnprResults} returns this
 */
proto.vivacity.benchmarking.AnprResults.prototype.setPipelineAccuracy = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.benchmarking.BenchmarkRun.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.benchmarking.BenchmarkRun} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.BenchmarkRun.toObject = function(includeInstance, msg) {
  var f, obj = {
    runDate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    benchmarkType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cvModelWeightsHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supermarioVersion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gpuModel: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    runtimeConfig: jspb.Message.getFieldWithDefault(msg, 7, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    detectorResults: (f = msg.getDetectorResults()) && proto.vivacity.benchmarking.DetectorResults.toObject(includeInstance, f),
    trackerResults: (f = msg.getTrackerResults()) && proto.vivacity.benchmarking.TrackerResults.toObject(includeInstance, f),
    countsResults: (f = msg.getCountsResults()) && proto.vivacity.benchmarking.CountsResults.toObject(includeInstance, f),
    anprResults: (f = msg.getAnprResults()) && proto.vivacity.benchmarking.AnprResults.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.benchmarking.BenchmarkRun}
 */
proto.vivacity.benchmarking.BenchmarkRun.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.benchmarking.BenchmarkRun;
  return proto.vivacity.benchmarking.BenchmarkRun.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.benchmarking.BenchmarkRun} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.benchmarking.BenchmarkRun}
 */
proto.vivacity.benchmarking.BenchmarkRun.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRunDate(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.benchmarking.BenchmarkRun.BenchmarkType} */ (reader.readEnum());
      msg.setBenchmarkType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCvModelWeightsHash(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGpuModel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuntimeConfig(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    case 9:
      var value = new proto.vivacity.benchmarking.DetectorResults;
      reader.readMessage(value,proto.vivacity.benchmarking.DetectorResults.deserializeBinaryFromReader);
      msg.setDetectorResults(value);
      break;
    case 10:
      var value = new proto.vivacity.benchmarking.TrackerResults;
      reader.readMessage(value,proto.vivacity.benchmarking.TrackerResults.deserializeBinaryFromReader);
      msg.setTrackerResults(value);
      break;
    case 11:
      var value = new proto.vivacity.benchmarking.CountsResults;
      reader.readMessage(value,proto.vivacity.benchmarking.CountsResults.deserializeBinaryFromReader);
      msg.setCountsResults(value);
      break;
    case 12:
      var value = new proto.vivacity.benchmarking.AnprResults;
      reader.readMessage(value,proto.vivacity.benchmarking.AnprResults.deserializeBinaryFromReader);
      msg.setAnprResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.benchmarking.BenchmarkRun.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.benchmarking.BenchmarkRun} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.BenchmarkRun.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunDate();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBenchmarkType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCvModelWeightsHash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupermarioVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGpuModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRuntimeConfig();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getDetectorResults();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vivacity.benchmarking.DetectorResults.serializeBinaryToWriter
    );
  }
  f = message.getTrackerResults();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vivacity.benchmarking.TrackerResults.serializeBinaryToWriter
    );
  }
  f = message.getCountsResults();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.vivacity.benchmarking.CountsResults.serializeBinaryToWriter
    );
  }
  f = message.getAnprResults();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vivacity.benchmarking.AnprResults.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.benchmarking.BenchmarkRun.BenchmarkType = {
  DETECTOR: 0,
  TRACKER: 1,
  COUNTS: 2,
  ANPR: 3
};

/**
 * optional uint64 run_date = 1;
 * @return {number}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getRunDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.setRunDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BenchmarkType benchmark_type = 2;
 * @return {!proto.vivacity.benchmarking.BenchmarkRun.BenchmarkType}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getBenchmarkType = function() {
  return /** @type {!proto.vivacity.benchmarking.BenchmarkRun.BenchmarkType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.benchmarking.BenchmarkRun.BenchmarkType} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.setBenchmarkType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string cv_model_weights_hash = 3;
 * @return {string}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getCvModelWeightsHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.setCvModelWeightsHash = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string supermario_version = 4;
 * @return {string}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getSupermarioVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.setSupermarioVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string gpu_model = 5;
 * @return {string}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getGpuModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.setGpuModel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string runtime_config = 7;
 * @return {string}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getRuntimeConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.setRuntimeConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 duration = 8;
 * @return {number}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional DetectorResults detector_results = 9;
 * @return {?proto.vivacity.benchmarking.DetectorResults}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getDetectorResults = function() {
  return /** @type{?proto.vivacity.benchmarking.DetectorResults} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.benchmarking.DetectorResults, 9));
};


/**
 * @param {?proto.vivacity.benchmarking.DetectorResults|undefined} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
*/
proto.vivacity.benchmarking.BenchmarkRun.prototype.setDetectorResults = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.clearDetectorResults = function() {
  return this.setDetectorResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.hasDetectorResults = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TrackerResults tracker_results = 10;
 * @return {?proto.vivacity.benchmarking.TrackerResults}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getTrackerResults = function() {
  return /** @type{?proto.vivacity.benchmarking.TrackerResults} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.benchmarking.TrackerResults, 10));
};


/**
 * @param {?proto.vivacity.benchmarking.TrackerResults|undefined} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
*/
proto.vivacity.benchmarking.BenchmarkRun.prototype.setTrackerResults = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.clearTrackerResults = function() {
  return this.setTrackerResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.hasTrackerResults = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional CountsResults counts_results = 11;
 * @return {?proto.vivacity.benchmarking.CountsResults}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getCountsResults = function() {
  return /** @type{?proto.vivacity.benchmarking.CountsResults} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.benchmarking.CountsResults, 11));
};


/**
 * @param {?proto.vivacity.benchmarking.CountsResults|undefined} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
*/
proto.vivacity.benchmarking.BenchmarkRun.prototype.setCountsResults = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.clearCountsResults = function() {
  return this.setCountsResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.hasCountsResults = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional AnprResults anpr_results = 12;
 * @return {?proto.vivacity.benchmarking.AnprResults}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.getAnprResults = function() {
  return /** @type{?proto.vivacity.benchmarking.AnprResults} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.benchmarking.AnprResults, 12));
};


/**
 * @param {?proto.vivacity.benchmarking.AnprResults|undefined} value
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
*/
proto.vivacity.benchmarking.BenchmarkRun.prototype.setAnprResults = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.benchmarking.BenchmarkRun} returns this
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.clearAnprResults = function() {
  return this.setAnprResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.benchmarking.BenchmarkRun.prototype.hasAnprResults = function() {
  return jspb.Message.getField(this, 12) != null;
};


goog.object.extend(exports, proto.vivacity.benchmarking);
