/* eslint-disable */
// source: vivacity/core/movement.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_vector_pb = require('../../vivacity/core/vector_pb.js');
goog.object.extend(proto, vivacity_core_vector_pb);
goog.exportSymbol('proto.vivacity.core.Displacements', null, global);
goog.exportSymbol('proto.vivacity.core.Movement', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Movement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.Movement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Movement.displayName = 'proto.vivacity.core.Movement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Displacements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.Displacements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Displacements.displayName = 'proto.vivacity.core.Displacements';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Movement.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Movement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Movement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Movement.toObject = function(includeInstance, msg) {
  var f, obj = {
    perFrame: (f = msg.getPerFrame()) && proto.vivacity.core.Displacements.toObject(includeInstance, f),
    perSecond: (f = msg.getPerSecond()) && proto.vivacity.core.Displacements.toObject(includeInstance, f),
    shortTermAverage: (f = msg.getShortTermAverage()) && proto.vivacity.core.Displacements.toObject(includeInstance, f),
    longTermAverage: (f = msg.getLongTermAverage()) && proto.vivacity.core.Displacements.toObject(includeInstance, f),
    trackAverage: (f = msg.getTrackAverage()) && proto.vivacity.core.Displacements.toObject(includeInstance, f),
    trackTotalDistanceTravelled: (f = msg.getTrackTotalDistanceTravelled()) && proto.vivacity.core.Displacements.toObject(includeInstance, f),
    totalVectorDisplacement: (f = msg.getTotalVectorDisplacement()) && proto.vivacity.core.Displacements.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Movement}
 */
proto.vivacity.core.Movement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Movement;
  return proto.vivacity.core.Movement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Movement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Movement}
 */
proto.vivacity.core.Movement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.Displacements;
      reader.readMessage(value,proto.vivacity.core.Displacements.deserializeBinaryFromReader);
      msg.setPerFrame(value);
      break;
    case 2:
      var value = new proto.vivacity.core.Displacements;
      reader.readMessage(value,proto.vivacity.core.Displacements.deserializeBinaryFromReader);
      msg.setPerSecond(value);
      break;
    case 3:
      var value = new proto.vivacity.core.Displacements;
      reader.readMessage(value,proto.vivacity.core.Displacements.deserializeBinaryFromReader);
      msg.setShortTermAverage(value);
      break;
    case 4:
      var value = new proto.vivacity.core.Displacements;
      reader.readMessage(value,proto.vivacity.core.Displacements.deserializeBinaryFromReader);
      msg.setLongTermAverage(value);
      break;
    case 5:
      var value = new proto.vivacity.core.Displacements;
      reader.readMessage(value,proto.vivacity.core.Displacements.deserializeBinaryFromReader);
      msg.setTrackAverage(value);
      break;
    case 6:
      var value = new proto.vivacity.core.Displacements;
      reader.readMessage(value,proto.vivacity.core.Displacements.deserializeBinaryFromReader);
      msg.setTrackTotalDistanceTravelled(value);
      break;
    case 7:
      var value = new proto.vivacity.core.Displacements;
      reader.readMessage(value,proto.vivacity.core.Displacements.deserializeBinaryFromReader);
      msg.setTotalVectorDisplacement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Movement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Movement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Movement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Movement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerFrame();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.Displacements.serializeBinaryToWriter
    );
  }
  f = message.getPerSecond();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.Displacements.serializeBinaryToWriter
    );
  }
  f = message.getShortTermAverage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.core.Displacements.serializeBinaryToWriter
    );
  }
  f = message.getLongTermAverage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.core.Displacements.serializeBinaryToWriter
    );
  }
  f = message.getTrackAverage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vivacity.core.Displacements.serializeBinaryToWriter
    );
  }
  f = message.getTrackTotalDistanceTravelled();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vivacity.core.Displacements.serializeBinaryToWriter
    );
  }
  f = message.getTotalVectorDisplacement();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vivacity.core.Displacements.serializeBinaryToWriter
    );
  }
};


/**
 * optional Displacements per_frame = 1;
 * @return {?proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Movement.prototype.getPerFrame = function() {
  return /** @type{?proto.vivacity.core.Displacements} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Displacements, 1));
};


/**
 * @param {?proto.vivacity.core.Displacements|undefined} value
 * @return {!proto.vivacity.core.Movement} returns this
*/
proto.vivacity.core.Movement.prototype.setPerFrame = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Movement} returns this
 */
proto.vivacity.core.Movement.prototype.clearPerFrame = function() {
  return this.setPerFrame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Movement.prototype.hasPerFrame = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Displacements per_second = 2;
 * @return {?proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Movement.prototype.getPerSecond = function() {
  return /** @type{?proto.vivacity.core.Displacements} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Displacements, 2));
};


/**
 * @param {?proto.vivacity.core.Displacements|undefined} value
 * @return {!proto.vivacity.core.Movement} returns this
*/
proto.vivacity.core.Movement.prototype.setPerSecond = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Movement} returns this
 */
proto.vivacity.core.Movement.prototype.clearPerSecond = function() {
  return this.setPerSecond(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Movement.prototype.hasPerSecond = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Displacements short_term_average = 3;
 * @return {?proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Movement.prototype.getShortTermAverage = function() {
  return /** @type{?proto.vivacity.core.Displacements} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Displacements, 3));
};


/**
 * @param {?proto.vivacity.core.Displacements|undefined} value
 * @return {!proto.vivacity.core.Movement} returns this
*/
proto.vivacity.core.Movement.prototype.setShortTermAverage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Movement} returns this
 */
proto.vivacity.core.Movement.prototype.clearShortTermAverage = function() {
  return this.setShortTermAverage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Movement.prototype.hasShortTermAverage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Displacements long_term_average = 4;
 * @return {?proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Movement.prototype.getLongTermAverage = function() {
  return /** @type{?proto.vivacity.core.Displacements} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Displacements, 4));
};


/**
 * @param {?proto.vivacity.core.Displacements|undefined} value
 * @return {!proto.vivacity.core.Movement} returns this
*/
proto.vivacity.core.Movement.prototype.setLongTermAverage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Movement} returns this
 */
proto.vivacity.core.Movement.prototype.clearLongTermAverage = function() {
  return this.setLongTermAverage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Movement.prototype.hasLongTermAverage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Displacements track_average = 5;
 * @return {?proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Movement.prototype.getTrackAverage = function() {
  return /** @type{?proto.vivacity.core.Displacements} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Displacements, 5));
};


/**
 * @param {?proto.vivacity.core.Displacements|undefined} value
 * @return {!proto.vivacity.core.Movement} returns this
*/
proto.vivacity.core.Movement.prototype.setTrackAverage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Movement} returns this
 */
proto.vivacity.core.Movement.prototype.clearTrackAverage = function() {
  return this.setTrackAverage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Movement.prototype.hasTrackAverage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Displacements track_total_distance_travelled = 6;
 * @return {?proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Movement.prototype.getTrackTotalDistanceTravelled = function() {
  return /** @type{?proto.vivacity.core.Displacements} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Displacements, 6));
};


/**
 * @param {?proto.vivacity.core.Displacements|undefined} value
 * @return {!proto.vivacity.core.Movement} returns this
*/
proto.vivacity.core.Movement.prototype.setTrackTotalDistanceTravelled = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Movement} returns this
 */
proto.vivacity.core.Movement.prototype.clearTrackTotalDistanceTravelled = function() {
  return this.setTrackTotalDistanceTravelled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Movement.prototype.hasTrackTotalDistanceTravelled = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Displacements total_vector_displacement = 7;
 * @return {?proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Movement.prototype.getTotalVectorDisplacement = function() {
  return /** @type{?proto.vivacity.core.Displacements} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.Displacements, 7));
};


/**
 * @param {?proto.vivacity.core.Displacements|undefined} value
 * @return {!proto.vivacity.core.Movement} returns this
*/
proto.vivacity.core.Movement.prototype.setTotalVectorDisplacement = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Movement} returns this
 */
proto.vivacity.core.Movement.prototype.clearTotalVectorDisplacement = function() {
  return this.setTotalVectorDisplacement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Movement.prototype.hasTotalVectorDisplacement = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Displacements.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Displacements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Displacements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Displacements.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageSpace: (f = msg.getImageSpace()) && vivacity_core_vector_pb.VectorF.toObject(includeInstance, f),
    undistorted: (f = msg.getUndistorted()) && vivacity_core_vector_pb.VectorF.toObject(includeInstance, f),
    gps: (f = msg.getGps()) && vivacity_core_vector_pb.VectorF.toObject(includeInstance, f),
    localCartesianMeters: (f = msg.getLocalCartesianMeters()) && vivacity_core_vector_pb.VectorF.toObject(includeInstance, f),
    imageSpace64: (f = msg.getImageSpace64()) && vivacity_core_vector_pb.VectorD.toObject(includeInstance, f),
    undistorted64: (f = msg.getUndistorted64()) && vivacity_core_vector_pb.VectorD.toObject(includeInstance, f),
    gps64: (f = msg.getGps64()) && vivacity_core_vector_pb.VectorD.toObject(includeInstance, f),
    localCartesianMeters64: (f = msg.getLocalCartesianMeters64()) && vivacity_core_vector_pb.VectorD.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Displacements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Displacements;
  return proto.vivacity.core.Displacements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Displacements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Displacements}
 */
proto.vivacity.core.Displacements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_vector_pb.VectorF;
      reader.readMessage(value,vivacity_core_vector_pb.VectorF.deserializeBinaryFromReader);
      msg.setImageSpace(value);
      break;
    case 2:
      var value = new vivacity_core_vector_pb.VectorF;
      reader.readMessage(value,vivacity_core_vector_pb.VectorF.deserializeBinaryFromReader);
      msg.setUndistorted(value);
      break;
    case 3:
      var value = new vivacity_core_vector_pb.VectorF;
      reader.readMessage(value,vivacity_core_vector_pb.VectorF.deserializeBinaryFromReader);
      msg.setGps(value);
      break;
    case 4:
      var value = new vivacity_core_vector_pb.VectorF;
      reader.readMessage(value,vivacity_core_vector_pb.VectorF.deserializeBinaryFromReader);
      msg.setLocalCartesianMeters(value);
      break;
    case 5:
      var value = new vivacity_core_vector_pb.VectorD;
      reader.readMessage(value,vivacity_core_vector_pb.VectorD.deserializeBinaryFromReader);
      msg.setImageSpace64(value);
      break;
    case 6:
      var value = new vivacity_core_vector_pb.VectorD;
      reader.readMessage(value,vivacity_core_vector_pb.VectorD.deserializeBinaryFromReader);
      msg.setUndistorted64(value);
      break;
    case 7:
      var value = new vivacity_core_vector_pb.VectorD;
      reader.readMessage(value,vivacity_core_vector_pb.VectorD.deserializeBinaryFromReader);
      msg.setGps64(value);
      break;
    case 8:
      var value = new vivacity_core_vector_pb.VectorD;
      reader.readMessage(value,vivacity_core_vector_pb.VectorD.deserializeBinaryFromReader);
      msg.setLocalCartesianMeters64(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Displacements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Displacements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Displacements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Displacements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageSpace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_core_vector_pb.VectorF.serializeBinaryToWriter
    );
  }
  f = message.getUndistorted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_core_vector_pb.VectorF.serializeBinaryToWriter
    );
  }
  f = message.getGps();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_vector_pb.VectorF.serializeBinaryToWriter
    );
  }
  f = message.getLocalCartesianMeters();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_vector_pb.VectorF.serializeBinaryToWriter
    );
  }
  f = message.getImageSpace64();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_core_vector_pb.VectorD.serializeBinaryToWriter
    );
  }
  f = message.getUndistorted64();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_vector_pb.VectorD.serializeBinaryToWriter
    );
  }
  f = message.getGps64();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_core_vector_pb.VectorD.serializeBinaryToWriter
    );
  }
  f = message.getLocalCartesianMeters64();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_vector_pb.VectorD.serializeBinaryToWriter
    );
  }
};


/**
 * optional VectorF image_space = 1;
 * @return {?proto.vivacity.core.VectorF}
 */
proto.vivacity.core.Displacements.prototype.getImageSpace = function() {
  return /** @type{?proto.vivacity.core.VectorF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorF, 1));
};


/**
 * @param {?proto.vivacity.core.VectorF|undefined} value
 * @return {!proto.vivacity.core.Displacements} returns this
*/
proto.vivacity.core.Displacements.prototype.setImageSpace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Displacements} returns this
 */
proto.vivacity.core.Displacements.prototype.clearImageSpace = function() {
  return this.setImageSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Displacements.prototype.hasImageSpace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional VectorF undistorted = 2;
 * @return {?proto.vivacity.core.VectorF}
 */
proto.vivacity.core.Displacements.prototype.getUndistorted = function() {
  return /** @type{?proto.vivacity.core.VectorF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorF, 2));
};


/**
 * @param {?proto.vivacity.core.VectorF|undefined} value
 * @return {!proto.vivacity.core.Displacements} returns this
*/
proto.vivacity.core.Displacements.prototype.setUndistorted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Displacements} returns this
 */
proto.vivacity.core.Displacements.prototype.clearUndistorted = function() {
  return this.setUndistorted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Displacements.prototype.hasUndistorted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VectorF gps = 3;
 * @return {?proto.vivacity.core.VectorF}
 */
proto.vivacity.core.Displacements.prototype.getGps = function() {
  return /** @type{?proto.vivacity.core.VectorF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorF, 3));
};


/**
 * @param {?proto.vivacity.core.VectorF|undefined} value
 * @return {!proto.vivacity.core.Displacements} returns this
*/
proto.vivacity.core.Displacements.prototype.setGps = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Displacements} returns this
 */
proto.vivacity.core.Displacements.prototype.clearGps = function() {
  return this.setGps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Displacements.prototype.hasGps = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional VectorF local_cartesian_meters = 4;
 * @return {?proto.vivacity.core.VectorF}
 */
proto.vivacity.core.Displacements.prototype.getLocalCartesianMeters = function() {
  return /** @type{?proto.vivacity.core.VectorF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorF, 4));
};


/**
 * @param {?proto.vivacity.core.VectorF|undefined} value
 * @return {!proto.vivacity.core.Displacements} returns this
*/
proto.vivacity.core.Displacements.prototype.setLocalCartesianMeters = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Displacements} returns this
 */
proto.vivacity.core.Displacements.prototype.clearLocalCartesianMeters = function() {
  return this.setLocalCartesianMeters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Displacements.prototype.hasLocalCartesianMeters = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional VectorD image_space64 = 5;
 * @return {?proto.vivacity.core.VectorD}
 */
proto.vivacity.core.Displacements.prototype.getImageSpace64 = function() {
  return /** @type{?proto.vivacity.core.VectorD} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorD, 5));
};


/**
 * @param {?proto.vivacity.core.VectorD|undefined} value
 * @return {!proto.vivacity.core.Displacements} returns this
*/
proto.vivacity.core.Displacements.prototype.setImageSpace64 = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Displacements} returns this
 */
proto.vivacity.core.Displacements.prototype.clearImageSpace64 = function() {
  return this.setImageSpace64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Displacements.prototype.hasImageSpace64 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional VectorD undistorted64 = 6;
 * @return {?proto.vivacity.core.VectorD}
 */
proto.vivacity.core.Displacements.prototype.getUndistorted64 = function() {
  return /** @type{?proto.vivacity.core.VectorD} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorD, 6));
};


/**
 * @param {?proto.vivacity.core.VectorD|undefined} value
 * @return {!proto.vivacity.core.Displacements} returns this
*/
proto.vivacity.core.Displacements.prototype.setUndistorted64 = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Displacements} returns this
 */
proto.vivacity.core.Displacements.prototype.clearUndistorted64 = function() {
  return this.setUndistorted64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Displacements.prototype.hasUndistorted64 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional VectorD gps64 = 7;
 * @return {?proto.vivacity.core.VectorD}
 */
proto.vivacity.core.Displacements.prototype.getGps64 = function() {
  return /** @type{?proto.vivacity.core.VectorD} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorD, 7));
};


/**
 * @param {?proto.vivacity.core.VectorD|undefined} value
 * @return {!proto.vivacity.core.Displacements} returns this
*/
proto.vivacity.core.Displacements.prototype.setGps64 = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Displacements} returns this
 */
proto.vivacity.core.Displacements.prototype.clearGps64 = function() {
  return this.setGps64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Displacements.prototype.hasGps64 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional VectorD local_cartesian_meters64 = 8;
 * @return {?proto.vivacity.core.VectorD}
 */
proto.vivacity.core.Displacements.prototype.getLocalCartesianMeters64 = function() {
  return /** @type{?proto.vivacity.core.VectorD} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorD, 8));
};


/**
 * @param {?proto.vivacity.core.VectorD|undefined} value
 * @return {!proto.vivacity.core.Displacements} returns this
*/
proto.vivacity.core.Displacements.prototype.setLocalCartesianMeters64 = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.Displacements} returns this
 */
proto.vivacity.core.Displacements.prototype.clearLocalCartesianMeters64 = function() {
  return this.setLocalCartesianMeters64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.Displacements.prototype.hasLocalCartesianMeters64 = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.vivacity.core);
