/* eslint-disable */
// source: vivacity/core/alerts.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
goog.exportSymbol('proto.vivacity.core.AlertManagerAlert', null, global);
goog.exportSymbol('proto.vivacity.core.AlertManagerWebHookMessage', null, global);
goog.exportSymbol('proto.vivacity.core.AlertMessage', null, global);
goog.exportSymbol('proto.vivacity.core.AlertMessage.AlertName', null, global);
goog.exportSymbol('proto.vivacity.core.AlertMessage.AlertType', null, global);
goog.exportSymbol('proto.vivacity.core.AlertNotification', null, global);
goog.exportSymbol('proto.vivacity.core.AlertNotification.AlertType', null, global);
goog.exportSymbol('proto.vivacity.core.AlertNotificationBatch', null, global);
goog.exportSymbol('proto.vivacity.core.AlertNotificationStatus', null, global);
goog.exportSymbol('proto.vivacity.core.HardwareNotification', null, global);
goog.exportSymbol('proto.vivacity.core.HardwareNotification.HardwareNotificationType', null, global);
goog.exportSymbol('proto.vivacity.core.Threshold', null, global);
goog.exportSymbol('proto.vivacity.core.Threshold.Operator', null, global);
goog.exportSymbol('proto.vivacity.core.ViewPointNotification', null, global);
goog.exportSymbol('proto.vivacity.core.ViewPointNotification.ViewPointNotificationType', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalCongestionNotification', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalCongestionNotification.CongestionType', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalFlowAlertNotification', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalFlowAlertNotification.ThresholdType', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalOccupancyAlertNotification', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalOccupancyAlertNotification.ThresholdType', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalSpeedAlertNotification', null, global);
goog.exportSymbol('proto.vivacity.core.ZonalSpeedAlertNotification.ThresholdType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Threshold = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.Threshold, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Threshold.displayName = 'proto.vivacity.core.Threshold';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AlertMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.AlertMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.AlertMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AlertMessage.displayName = 'proto.vivacity.core.AlertMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AlertManagerWebHookMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.AlertManagerWebHookMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.AlertManagerWebHookMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AlertManagerWebHookMessage.displayName = 'proto.vivacity.core.AlertManagerWebHookMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AlertManagerAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.AlertManagerAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AlertManagerAlert.displayName = 'proto.vivacity.core.AlertManagerAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AlertNotificationBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.AlertNotificationBatch.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.AlertNotificationBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AlertNotificationBatch.displayName = 'proto.vivacity.core.AlertNotificationBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AlertNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.AlertNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AlertNotification.displayName = 'proto.vivacity.core.AlertNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ZonalSpeedAlertNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ZonalSpeedAlertNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ZonalSpeedAlertNotification.displayName = 'proto.vivacity.core.ZonalSpeedAlertNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ZonalOccupancyAlertNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ZonalOccupancyAlertNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ZonalOccupancyAlertNotification.displayName = 'proto.vivacity.core.ZonalOccupancyAlertNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ZonalFlowAlertNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ZonalFlowAlertNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ZonalFlowAlertNotification.displayName = 'proto.vivacity.core.ZonalFlowAlertNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ZonalCongestionNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ZonalCongestionNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ZonalCongestionNotification.displayName = 'proto.vivacity.core.ZonalCongestionNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ViewPointNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ViewPointNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ViewPointNotification.displayName = 'proto.vivacity.core.ViewPointNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.HardwareNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.HardwareNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.HardwareNotification.displayName = 'proto.vivacity.core.HardwareNotification';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Threshold.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Threshold.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Threshold} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Threshold.toObject = function(includeInstance, msg) {
  var f, obj = {
    thresholdName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valueFromMeta: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 3, 0),
    thresholdValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    framesBeforeTriggeringAlert: jspb.Message.getFieldWithDefault(msg, 5, 0),
    framesBeforeClearingAlert: jspb.Message.getFieldWithDefault(msg, 6, 0),
    averageValueAcrossWindow: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Threshold}
 */
proto.vivacity.core.Threshold.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Threshold;
  return proto.vivacity.core.Threshold.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Threshold} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Threshold}
 */
proto.vivacity.core.Threshold.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThresholdName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFromMeta(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.Threshold.Operator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThresholdValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFramesBeforeTriggeringAlert(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFramesBeforeClearingAlert(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAverageValueAcrossWindow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Threshold.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Threshold.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Threshold} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Threshold.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThresholdName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValueFromMeta();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getThresholdValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getFramesBeforeTriggeringAlert();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getFramesBeforeClearingAlert();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAverageValueAcrossWindow();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.Threshold.Operator = {
  UNKNOWN: 0,
  LESS_THAN: 1,
  GREATER_THAN: 2,
  EQUAL_TO: 3,
  LESS_THAN_OR_EQUAL_TO: 4,
  GREATER_THAN_OR_EQUAL_TO: 5
};

/**
 * optional string threshold_name = 1;
 * @return {string}
 */
proto.vivacity.core.Threshold.prototype.getThresholdName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.Threshold} returns this
 */
proto.vivacity.core.Threshold.prototype.setThresholdName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value_from_meta = 2;
 * @return {string}
 */
proto.vivacity.core.Threshold.prototype.getValueFromMeta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.Threshold} returns this
 */
proto.vivacity.core.Threshold.prototype.setValueFromMeta = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Operator operator = 3;
 * @return {!proto.vivacity.core.Threshold.Operator}
 */
proto.vivacity.core.Threshold.prototype.getOperator = function() {
  return /** @type {!proto.vivacity.core.Threshold.Operator} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.Threshold.Operator} value
 * @return {!proto.vivacity.core.Threshold} returns this
 */
proto.vivacity.core.Threshold.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double threshold_value = 4;
 * @return {number}
 */
proto.vivacity.core.Threshold.prototype.getThresholdValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Threshold} returns this
 */
proto.vivacity.core.Threshold.prototype.setThresholdValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional uint32 frames_before_triggering_alert = 5;
 * @return {number}
 */
proto.vivacity.core.Threshold.prototype.getFramesBeforeTriggeringAlert = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Threshold} returns this
 */
proto.vivacity.core.Threshold.prototype.setFramesBeforeTriggeringAlert = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 frames_before_clearing_alert = 6;
 * @return {number}
 */
proto.vivacity.core.Threshold.prototype.getFramesBeforeClearingAlert = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Threshold} returns this
 */
proto.vivacity.core.Threshold.prototype.setFramesBeforeClearingAlert = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double average_value_across_window = 7;
 * @return {number}
 */
proto.vivacity.core.Threshold.prototype.getAverageValueAcrossWindow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.Threshold} returns this
 */
proto.vivacity.core.Threshold.prototype.setAverageValueAcrossWindow = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.AlertMessage.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AlertMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AlertMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AlertMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alertName: jspb.Message.getFieldWithDefault(msg, 2, 0),
    alertType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    thresholdsList: jspb.Message.toObjectList(msg.getThresholdsList(),
    proto.vivacity.core.Threshold.toObject, includeInstance),
    frameTimeMicroseconds: jspb.Message.getFieldWithDefault(msg, 5, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AlertMessage}
 */
proto.vivacity.core.AlertMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AlertMessage;
  return proto.vivacity.core.AlertMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AlertMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AlertMessage}
 */
proto.vivacity.core.AlertMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.AlertMessage.AlertName} */ (reader.readEnum());
      msg.setAlertName(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.AlertMessage.AlertType} */ (reader.readEnum());
      msg.setAlertType(value);
      break;
    case 4:
      var value = new proto.vivacity.core.Threshold;
      reader.readMessage(value,proto.vivacity.core.Threshold.deserializeBinaryFromReader);
      msg.addThresholds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameTimeMicroseconds(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AlertMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AlertMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AlertMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlertName();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAlertType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getThresholdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vivacity.core.Threshold.serializeBinaryToWriter
    );
  }
  f = message.getFrameTimeMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.AlertMessage.AlertType = {
  UNKNOWN: 0,
  TRIGGER: 1,
  CLEAR: 2,
  REPEAT: 3
};

/**
 * @enum {number}
 */
proto.vivacity.core.AlertMessage.AlertName = {
  UNKNOWN_ALERT: 0,
  STOPPED_VEHICLE_ALERT: 1,
  SPEED_ALERT: 2,
  FLOW_ALERT: 3,
  OCCUPANCY_ALERT: 4,
  MOVED_CAMERA_ALERT: 5,
  FROZEN_FEED_ALERT: 6,
  BROKEN_FEED_ALERT: 7
};

/**
 * optional string alert_id = 1;
 * @return {string}
 */
proto.vivacity.core.AlertMessage.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertMessage} returns this
 */
proto.vivacity.core.AlertMessage.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AlertName alert_name = 2;
 * @return {!proto.vivacity.core.AlertMessage.AlertName}
 */
proto.vivacity.core.AlertMessage.prototype.getAlertName = function() {
  return /** @type {!proto.vivacity.core.AlertMessage.AlertName} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.AlertMessage.AlertName} value
 * @return {!proto.vivacity.core.AlertMessage} returns this
 */
proto.vivacity.core.AlertMessage.prototype.setAlertName = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional AlertType alert_type = 3;
 * @return {!proto.vivacity.core.AlertMessage.AlertType}
 */
proto.vivacity.core.AlertMessage.prototype.getAlertType = function() {
  return /** @type {!proto.vivacity.core.AlertMessage.AlertType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.AlertMessage.AlertType} value
 * @return {!proto.vivacity.core.AlertMessage} returns this
 */
proto.vivacity.core.AlertMessage.prototype.setAlertType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated Threshold thresholds = 4;
 * @return {!Array<!proto.vivacity.core.Threshold>}
 */
proto.vivacity.core.AlertMessage.prototype.getThresholdsList = function() {
  return /** @type{!Array<!proto.vivacity.core.Threshold>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.Threshold, 4));
};


/**
 * @param {!Array<!proto.vivacity.core.Threshold>} value
 * @return {!proto.vivacity.core.AlertMessage} returns this
*/
proto.vivacity.core.AlertMessage.prototype.setThresholdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vivacity.core.Threshold=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Threshold}
 */
proto.vivacity.core.AlertMessage.prototype.addThresholds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vivacity.core.Threshold, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AlertMessage} returns this
 */
proto.vivacity.core.AlertMessage.prototype.clearThresholdsList = function() {
  return this.setThresholdsList([]);
};


/**
 * optional uint64 frame_time_microseconds = 5;
 * @return {number}
 */
proto.vivacity.core.AlertMessage.prototype.getFrameTimeMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertMessage} returns this
 */
proto.vivacity.core.AlertMessage.prototype.setFrameTimeMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 vision_program_id = 6;
 * @return {number}
 */
proto.vivacity.core.AlertMessage.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertMessage} returns this
 */
proto.vivacity.core.AlertMessage.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 zone_id = 7;
 * @return {number}
 */
proto.vivacity.core.AlertMessage.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertMessage} returns this
 */
proto.vivacity.core.AlertMessage.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.AlertManagerWebHookMessage.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AlertManagerWebHookMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AlertManagerWebHookMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertManagerWebHookMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiver: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    proto.vivacity.core.AlertManagerAlert.toObject, includeInstance),
    grouplabelsMap: (f = msg.getGrouplabelsMap()) ? f.toObject(includeInstance, undefined) : [],
    commonlabelsMap: (f = msg.getCommonlabelsMap()) ? f.toObject(includeInstance, undefined) : [],
    commonannotationsMap: (f = msg.getCommonannotationsMap()) ? f.toObject(includeInstance, undefined) : [],
    externalurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    version: jspb.Message.getFieldWithDefault(msg, 8, ""),
    groupkey: jspb.Message.getFieldWithDefault(msg, 9, ""),
    truncatedalerts: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage}
 */
proto.vivacity.core.AlertManagerWebHookMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AlertManagerWebHookMessage;
  return proto.vivacity.core.AlertManagerWebHookMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AlertManagerWebHookMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage}
 */
proto.vivacity.core.AlertManagerWebHookMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiver(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto.vivacity.core.AlertManagerAlert;
      reader.readMessage(value,proto.vivacity.core.AlertManagerAlert.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    case 4:
      var value = msg.getGrouplabelsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = msg.getCommonlabelsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = msg.getCommonannotationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalurl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupkey(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTruncatedalerts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AlertManagerWebHookMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AlertManagerWebHookMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertManagerWebHookMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiver();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.core.AlertManagerAlert.serializeBinaryToWriter
    );
  }
  f = message.getGrouplabelsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getCommonlabelsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getCommonannotationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getExternalurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGroupkey();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTruncatedalerts();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
};


/**
 * optional string receiver = 1;
 * @return {string}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getReceiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.setReceiver = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AlertManagerAlert alerts = 3;
 * @return {!Array<!proto.vivacity.core.AlertManagerAlert>}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.vivacity.core.AlertManagerAlert>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.AlertManagerAlert, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.AlertManagerAlert>} value
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
*/
proto.vivacity.core.AlertManagerWebHookMessage.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.AlertManagerAlert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AlertManagerAlert}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.AlertManagerAlert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};


/**
 * map<string, string> groupLabels = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getGrouplabelsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.clearGrouplabelsMap = function() {
  this.getGrouplabelsMap().clear();
  return this;};


/**
 * map<string, string> commonLabels = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getCommonlabelsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.clearCommonlabelsMap = function() {
  this.getCommonlabelsMap().clear();
  return this;};


/**
 * map<string, string> commonAnnotations = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getCommonannotationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.clearCommonannotationsMap = function() {
  this.getCommonannotationsMap().clear();
  return this;};


/**
 * optional string externalURL = 7;
 * @return {string}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getExternalurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.setExternalurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string version = 8;
 * @return {string}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string groupKey = 9;
 * @return {string}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getGroupkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.setGroupkey = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint64 truncatedAlerts = 10;
 * @return {number}
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.getTruncatedalerts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertManagerWebHookMessage} returns this
 */
proto.vivacity.core.AlertManagerWebHookMessage.prototype.setTruncatedalerts = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AlertManagerAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AlertManagerAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AlertManagerAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertManagerAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelsMap: (f = msg.getLabelsMap()) ? f.toObject(includeInstance, undefined) : [],
    annotationsMap: (f = msg.getAnnotationsMap()) ? f.toObject(includeInstance, undefined) : [],
    startsat: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endsat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    generatorurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fingerprint: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AlertManagerAlert}
 */
proto.vivacity.core.AlertManagerAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AlertManagerAlert;
  return proto.vivacity.core.AlertManagerAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AlertManagerAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AlertManagerAlert}
 */
proto.vivacity.core.AlertManagerAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = msg.getLabelsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = msg.getAnnotationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartsat(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndsat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeneratorurl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFingerprint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AlertManagerAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AlertManagerAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AlertManagerAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertManagerAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getAnnotationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getStartsat();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getEndsat();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getGeneratorurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFingerprint();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.vivacity.core.AlertManagerAlert.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertManagerAlert} returns this
 */
proto.vivacity.core.AlertManagerAlert.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> labels = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.vivacity.core.AlertManagerAlert.prototype.getLabelsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.AlertManagerAlert} returns this
 */
proto.vivacity.core.AlertManagerAlert.prototype.clearLabelsMap = function() {
  this.getLabelsMap().clear();
  return this;};


/**
 * map<string, string> annotations = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.vivacity.core.AlertManagerAlert.prototype.getAnnotationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.AlertManagerAlert} returns this
 */
proto.vivacity.core.AlertManagerAlert.prototype.clearAnnotationsMap = function() {
  this.getAnnotationsMap().clear();
  return this;};


/**
 * optional uint64 startsAt = 4;
 * @return {number}
 */
proto.vivacity.core.AlertManagerAlert.prototype.getStartsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertManagerAlert} returns this
 */
proto.vivacity.core.AlertManagerAlert.prototype.setStartsat = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 endsAt = 5;
 * @return {number}
 */
proto.vivacity.core.AlertManagerAlert.prototype.getEndsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertManagerAlert} returns this
 */
proto.vivacity.core.AlertManagerAlert.prototype.setEndsat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string generatorURL = 6;
 * @return {string}
 */
proto.vivacity.core.AlertManagerAlert.prototype.getGeneratorurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertManagerAlert} returns this
 */
proto.vivacity.core.AlertManagerAlert.prototype.setGeneratorurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fingerprint = 7;
 * @return {string}
 */
proto.vivacity.core.AlertManagerAlert.prototype.getFingerprint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertManagerAlert} returns this
 */
proto.vivacity.core.AlertManagerAlert.prototype.setFingerprint = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.AlertNotificationBatch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AlertNotificationBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AlertNotificationBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AlertNotificationBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertNotificationBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    proto.vivacity.core.AlertNotification.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AlertNotificationBatch}
 */
proto.vivacity.core.AlertNotificationBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AlertNotificationBatch;
  return proto.vivacity.core.AlertNotificationBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AlertNotificationBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AlertNotificationBatch}
 */
proto.vivacity.core.AlertNotificationBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.AlertNotification;
      reader.readMessage(value,proto.vivacity.core.AlertNotification.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.AlertNotificationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AlertNotificationBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AlertNotificationBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AlertNotificationBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertNotificationBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.core.AlertNotification.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AlertNotification alerts = 1;
 * @return {!Array<!proto.vivacity.core.AlertNotification>}
 */
proto.vivacity.core.AlertNotificationBatch.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.vivacity.core.AlertNotification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.AlertNotification, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.AlertNotification>} value
 * @return {!proto.vivacity.core.AlertNotificationBatch} returns this
*/
proto.vivacity.core.AlertNotificationBatch.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.AlertNotification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AlertNotification}
 */
proto.vivacity.core.AlertNotificationBatch.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.AlertNotification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AlertNotificationBatch} returns this
 */
proto.vivacity.core.AlertNotificationBatch.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};


/**
 * optional AlertNotificationStatus status = 2;
 * @return {!proto.vivacity.core.AlertNotificationStatus}
 */
proto.vivacity.core.AlertNotificationBatch.prototype.getStatus = function() {
  return /** @type {!proto.vivacity.core.AlertNotificationStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.AlertNotificationStatus} value
 * @return {!proto.vivacity.core.AlertNotificationBatch} returns this
 */
proto.vivacity.core.AlertNotificationBatch.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TracingContext tracing_ctx = 3;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.AlertNotificationBatch.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 3));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.AlertNotificationBatch} returns this
*/
proto.vivacity.core.AlertNotificationBatch.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AlertNotificationBatch} returns this
 */
proto.vivacity.core.AlertNotificationBatch.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AlertNotificationBatch.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AlertNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AlertNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AlertNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    fingerprint: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alertRuleId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    startTimeMicro: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endTimeMicro: jspb.Message.getFieldWithDefault(msg, 5, 0),
    query: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    speed: (f = msg.getSpeed()) && proto.vivacity.core.ZonalSpeedAlertNotification.toObject(includeInstance, f),
    occupancy: (f = msg.getOccupancy()) && proto.vivacity.core.ZonalOccupancyAlertNotification.toObject(includeInstance, f),
    flow: (f = msg.getFlow()) && proto.vivacity.core.ZonalFlowAlertNotification.toObject(includeInstance, f),
    congestion: (f = msg.getCongestion()) && proto.vivacity.core.ZonalCongestionNotification.toObject(includeInstance, f),
    viewPoint: (f = msg.getViewPoint()) && proto.vivacity.core.ViewPointNotification.toObject(includeInstance, f),
    hardware: (f = msg.getHardware()) && proto.vivacity.core.HardwareNotification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AlertNotification}
 */
proto.vivacity.core.AlertNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AlertNotification;
  return proto.vivacity.core.AlertNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AlertNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AlertNotification}
 */
proto.vivacity.core.AlertNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFingerprint(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAlertRuleId(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.AlertNotification.AlertType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimeMicro(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndTimeMicro(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 7:
      var value = /** @type {!proto.vivacity.core.AlertNotificationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = new proto.vivacity.core.ZonalSpeedAlertNotification;
      reader.readMessage(value,proto.vivacity.core.ZonalSpeedAlertNotification.deserializeBinaryFromReader);
      msg.setSpeed(value);
      break;
    case 9:
      var value = new proto.vivacity.core.ZonalOccupancyAlertNotification;
      reader.readMessage(value,proto.vivacity.core.ZonalOccupancyAlertNotification.deserializeBinaryFromReader);
      msg.setOccupancy(value);
      break;
    case 10:
      var value = new proto.vivacity.core.ZonalFlowAlertNotification;
      reader.readMessage(value,proto.vivacity.core.ZonalFlowAlertNotification.deserializeBinaryFromReader);
      msg.setFlow(value);
      break;
    case 11:
      var value = new proto.vivacity.core.ZonalCongestionNotification;
      reader.readMessage(value,proto.vivacity.core.ZonalCongestionNotification.deserializeBinaryFromReader);
      msg.setCongestion(value);
      break;
    case 12:
      var value = new proto.vivacity.core.ViewPointNotification;
      reader.readMessage(value,proto.vivacity.core.ViewPointNotification.deserializeBinaryFromReader);
      msg.setViewPoint(value);
      break;
    case 13:
      var value = new proto.vivacity.core.HardwareNotification;
      reader.readMessage(value,proto.vivacity.core.HardwareNotification.deserializeBinaryFromReader);
      msg.setHardware(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AlertNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AlertNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AlertNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AlertNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFingerprint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlertRuleId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStartTimeMicro();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getEndTimeMicro();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSpeed();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vivacity.core.ZonalSpeedAlertNotification.serializeBinaryToWriter
    );
  }
  f = message.getOccupancy();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vivacity.core.ZonalOccupancyAlertNotification.serializeBinaryToWriter
    );
  }
  f = message.getFlow();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vivacity.core.ZonalFlowAlertNotification.serializeBinaryToWriter
    );
  }
  f = message.getCongestion();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.vivacity.core.ZonalCongestionNotification.serializeBinaryToWriter
    );
  }
  f = message.getViewPoint();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vivacity.core.ViewPointNotification.serializeBinaryToWriter
    );
  }
  f = message.getHardware();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.vivacity.core.HardwareNotification.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.AlertNotification.AlertType = {
  UNKNOWN: 0,
  ZONAL_SPEED: 1,
  ZONAL_OCCUPANCY: 2,
  ZONAL_FLOW: 3,
  ZONAL_CONGESTION: 4,
  VIEW_POINT_NOTIFICATION: 5,
  HARDWARE_NOTIFICATION: 6
};

/**
 * optional string fingerprint = 1;
 * @return {string}
 */
proto.vivacity.core.AlertNotification.prototype.getFingerprint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.setFingerprint = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 alert_rule_id = 2;
 * @return {number}
 */
proto.vivacity.core.AlertNotification.prototype.getAlertRuleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.setAlertRuleId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AlertType type = 3;
 * @return {!proto.vivacity.core.AlertNotification.AlertType}
 */
proto.vivacity.core.AlertNotification.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.AlertNotification.AlertType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.AlertNotification.AlertType} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint64 start_time_micro = 4;
 * @return {number}
 */
proto.vivacity.core.AlertNotification.prototype.getStartTimeMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.setStartTimeMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 end_time_micro = 5;
 * @return {number}
 */
proto.vivacity.core.AlertNotification.prototype.getEndTimeMicro = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.setEndTimeMicro = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string query = 6;
 * @return {string}
 */
proto.vivacity.core.AlertNotification.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional AlertNotificationStatus status = 7;
 * @return {!proto.vivacity.core.AlertNotificationStatus}
 */
proto.vivacity.core.AlertNotification.prototype.getStatus = function() {
  return /** @type {!proto.vivacity.core.AlertNotificationStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.vivacity.core.AlertNotificationStatus} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional ZonalSpeedAlertNotification speed = 8;
 * @return {?proto.vivacity.core.ZonalSpeedAlertNotification}
 */
proto.vivacity.core.AlertNotification.prototype.getSpeed = function() {
  return /** @type{?proto.vivacity.core.ZonalSpeedAlertNotification} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.ZonalSpeedAlertNotification, 8));
};


/**
 * @param {?proto.vivacity.core.ZonalSpeedAlertNotification|undefined} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
*/
proto.vivacity.core.AlertNotification.prototype.setSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.clearSpeed = function() {
  return this.setSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AlertNotification.prototype.hasSpeed = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ZonalOccupancyAlertNotification occupancy = 9;
 * @return {?proto.vivacity.core.ZonalOccupancyAlertNotification}
 */
proto.vivacity.core.AlertNotification.prototype.getOccupancy = function() {
  return /** @type{?proto.vivacity.core.ZonalOccupancyAlertNotification} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.ZonalOccupancyAlertNotification, 9));
};


/**
 * @param {?proto.vivacity.core.ZonalOccupancyAlertNotification|undefined} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
*/
proto.vivacity.core.AlertNotification.prototype.setOccupancy = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.clearOccupancy = function() {
  return this.setOccupancy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AlertNotification.prototype.hasOccupancy = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ZonalFlowAlertNotification flow = 10;
 * @return {?proto.vivacity.core.ZonalFlowAlertNotification}
 */
proto.vivacity.core.AlertNotification.prototype.getFlow = function() {
  return /** @type{?proto.vivacity.core.ZonalFlowAlertNotification} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.ZonalFlowAlertNotification, 10));
};


/**
 * @param {?proto.vivacity.core.ZonalFlowAlertNotification|undefined} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
*/
proto.vivacity.core.AlertNotification.prototype.setFlow = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.clearFlow = function() {
  return this.setFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AlertNotification.prototype.hasFlow = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ZonalCongestionNotification congestion = 11;
 * @return {?proto.vivacity.core.ZonalCongestionNotification}
 */
proto.vivacity.core.AlertNotification.prototype.getCongestion = function() {
  return /** @type{?proto.vivacity.core.ZonalCongestionNotification} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.ZonalCongestionNotification, 11));
};


/**
 * @param {?proto.vivacity.core.ZonalCongestionNotification|undefined} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
*/
proto.vivacity.core.AlertNotification.prototype.setCongestion = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.clearCongestion = function() {
  return this.setCongestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AlertNotification.prototype.hasCongestion = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ViewPointNotification view_point = 12;
 * @return {?proto.vivacity.core.ViewPointNotification}
 */
proto.vivacity.core.AlertNotification.prototype.getViewPoint = function() {
  return /** @type{?proto.vivacity.core.ViewPointNotification} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.ViewPointNotification, 12));
};


/**
 * @param {?proto.vivacity.core.ViewPointNotification|undefined} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
*/
proto.vivacity.core.AlertNotification.prototype.setViewPoint = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.clearViewPoint = function() {
  return this.setViewPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AlertNotification.prototype.hasViewPoint = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional HardwareNotification hardware = 13;
 * @return {?proto.vivacity.core.HardwareNotification}
 */
proto.vivacity.core.AlertNotification.prototype.getHardware = function() {
  return /** @type{?proto.vivacity.core.HardwareNotification} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.HardwareNotification, 13));
};


/**
 * @param {?proto.vivacity.core.HardwareNotification|undefined} value
 * @return {!proto.vivacity.core.AlertNotification} returns this
*/
proto.vivacity.core.AlertNotification.prototype.setHardware = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AlertNotification} returns this
 */
proto.vivacity.core.AlertNotification.prototype.clearHardware = function() {
  return this.setHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AlertNotification.prototype.hasHardware = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ZonalSpeedAlertNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ZonalSpeedAlertNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalSpeedAlertNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    speedMetersPerSecond: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ZonalSpeedAlertNotification}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ZonalSpeedAlertNotification;
  return proto.vivacity.core.ZonalSpeedAlertNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ZonalSpeedAlertNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ZonalSpeedAlertNotification}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ZonalSpeedAlertNotification.ThresholdType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpeedMetersPerSecond(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ZonalSpeedAlertNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ZonalSpeedAlertNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalSpeedAlertNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSpeedMetersPerSecond();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.ThresholdType = {
  SPEED_ABOVE_THRESHOLD: 0,
  SPEED_BELOW_THRESHOLD: 1
};

/**
 * optional ThresholdType type = 1;
 * @return {!proto.vivacity.core.ZonalSpeedAlertNotification.ThresholdType}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.ZonalSpeedAlertNotification.ThresholdType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ZonalSpeedAlertNotification.ThresholdType} value
 * @return {!proto.vivacity.core.ZonalSpeedAlertNotification} returns this
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 zone_id = 2;
 * @return {number}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalSpeedAlertNotification} returns this
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double speed_meters_per_second = 4;
 * @return {number}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.getSpeedMetersPerSecond = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalSpeedAlertNotification} returns this
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.setSpeedMetersPerSecond = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double threshold = 5;
 * @return {number}
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalSpeedAlertNotification} returns this
 */
proto.vivacity.core.ZonalSpeedAlertNotification.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ZonalOccupancyAlertNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ZonalOccupancyAlertNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    occupancyValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ZonalOccupancyAlertNotification}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ZonalOccupancyAlertNotification;
  return proto.vivacity.core.ZonalOccupancyAlertNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ZonalOccupancyAlertNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ZonalOccupancyAlertNotification}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ZonalOccupancyAlertNotification.ThresholdType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOccupancyValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ZonalOccupancyAlertNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ZonalOccupancyAlertNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getOccupancyValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.ThresholdType = {
  OCCUPANCY_ABOVE_THRESHOLD: 0,
  OCCUPANCY_BELOW_THRESHOLD: 1
};

/**
 * optional ThresholdType type = 1;
 * @return {!proto.vivacity.core.ZonalOccupancyAlertNotification.ThresholdType}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.ZonalOccupancyAlertNotification.ThresholdType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ZonalOccupancyAlertNotification.ThresholdType} value
 * @return {!proto.vivacity.core.ZonalOccupancyAlertNotification} returns this
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 zone_id = 2;
 * @return {number}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalOccupancyAlertNotification} returns this
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double occupancy_value = 4;
 * @return {number}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.getOccupancyValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalOccupancyAlertNotification} returns this
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.setOccupancyValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double threshold = 5;
 * @return {number}
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalOccupancyAlertNotification} returns this
 */
proto.vivacity.core.ZonalOccupancyAlertNotification.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ZonalFlowAlertNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ZonalFlowAlertNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalFlowAlertNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    flowValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ZonalFlowAlertNotification}
 */
proto.vivacity.core.ZonalFlowAlertNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ZonalFlowAlertNotification;
  return proto.vivacity.core.ZonalFlowAlertNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ZonalFlowAlertNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ZonalFlowAlertNotification}
 */
proto.vivacity.core.ZonalFlowAlertNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ZonalFlowAlertNotification.ThresholdType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFlowValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ZonalFlowAlertNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ZonalFlowAlertNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalFlowAlertNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFlowValue();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.ZonalFlowAlertNotification.ThresholdType = {
  FLOW_ABOVE_THRESHOLD: 0,
  FLOW_BELOW_THRESHOLD: 1
};

/**
 * optional ThresholdType type = 1;
 * @return {!proto.vivacity.core.ZonalFlowAlertNotification.ThresholdType}
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.ZonalFlowAlertNotification.ThresholdType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ZonalFlowAlertNotification.ThresholdType} value
 * @return {!proto.vivacity.core.ZonalFlowAlertNotification} returns this
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 zone_id = 2;
 * @return {number}
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalFlowAlertNotification} returns this
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double flow_value = 4;
 * @return {number}
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.getFlowValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalFlowAlertNotification} returns this
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.setFlowValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double threshold = 5;
 * @return {number}
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalFlowAlertNotification} returns this
 */
proto.vivacity.core.ZonalFlowAlertNotification.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ZonalCongestionNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ZonalCongestionNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ZonalCongestionNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalCongestionNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ZonalCongestionNotification}
 */
proto.vivacity.core.ZonalCongestionNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ZonalCongestionNotification;
  return proto.vivacity.core.ZonalCongestionNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ZonalCongestionNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ZonalCongestionNotification}
 */
proto.vivacity.core.ZonalCongestionNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ZonalCongestionNotification.CongestionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ZonalCongestionNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ZonalCongestionNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ZonalCongestionNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ZonalCongestionNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.ZonalCongestionNotification.CongestionType = {
  FLOW_AND_OCCUPANCY_BASED: 0,
  SPEED_AND_OCCUPANCY_BASED: 1
};

/**
 * optional CongestionType type = 1;
 * @return {!proto.vivacity.core.ZonalCongestionNotification.CongestionType}
 */
proto.vivacity.core.ZonalCongestionNotification.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.ZonalCongestionNotification.CongestionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ZonalCongestionNotification.CongestionType} value
 * @return {!proto.vivacity.core.ZonalCongestionNotification} returns this
 */
proto.vivacity.core.ZonalCongestionNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 zone_id = 2;
 * @return {number}
 */
proto.vivacity.core.ZonalCongestionNotification.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ZonalCongestionNotification} returns this
 */
proto.vivacity.core.ZonalCongestionNotification.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ViewPointNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ViewPointNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ViewPointNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ViewPointNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    viewPointId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ViewPointNotification}
 */
proto.vivacity.core.ViewPointNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ViewPointNotification;
  return proto.vivacity.core.ViewPointNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ViewPointNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ViewPointNotification}
 */
proto.vivacity.core.ViewPointNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ViewPointNotification.ViewPointNotificationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewPointId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ViewPointNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ViewPointNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ViewPointNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ViewPointNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getViewPointId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.ViewPointNotification.ViewPointNotificationType = {
  UNKNOWN_VIEW_POINT_STATUS: 0,
  VIEW_POINT_DOWN: 1,
  VIEW_POINT_UP: 2
};

/**
 * optional ViewPointNotificationType type = 1;
 * @return {!proto.vivacity.core.ViewPointNotification.ViewPointNotificationType}
 */
proto.vivacity.core.ViewPointNotification.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.ViewPointNotification.ViewPointNotificationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ViewPointNotification.ViewPointNotificationType} value
 * @return {!proto.vivacity.core.ViewPointNotification} returns this
 */
proto.vivacity.core.ViewPointNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 view_point_id = 2;
 * @return {number}
 */
proto.vivacity.core.ViewPointNotification.prototype.getViewPointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ViewPointNotification} returns this
 */
proto.vivacity.core.ViewPointNotification.prototype.setViewPointId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 hardware_id = 3;
 * @return {number}
 */
proto.vivacity.core.ViewPointNotification.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ViewPointNotification} returns this
 */
proto.vivacity.core.ViewPointNotification.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.HardwareNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.HardwareNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.HardwareNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.HardwareNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.HardwareNotification}
 */
proto.vivacity.core.HardwareNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.HardwareNotification;
  return proto.vivacity.core.HardwareNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.HardwareNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.HardwareNotification}
 */
proto.vivacity.core.HardwareNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.HardwareNotification.HardwareNotificationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.HardwareNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.HardwareNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.HardwareNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.HardwareNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.HardwareNotification.HardwareNotificationType = {
  UNKNOWN_HARDWARE_STATUS: 0,
  HARDWARE_EXTERNAL_POWER_LOSS: 1,
  HARDWARE_EXTERNAL_POWER_RETURNED: 2,
  HARDWARE_DOWN: 3,
  HARDWARE_UP: 4
};

/**
 * optional HardwareNotificationType type = 1;
 * @return {!proto.vivacity.core.HardwareNotification.HardwareNotificationType}
 */
proto.vivacity.core.HardwareNotification.prototype.getType = function() {
  return /** @type {!proto.vivacity.core.HardwareNotification.HardwareNotificationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.HardwareNotification.HardwareNotificationType} value
 * @return {!proto.vivacity.core.HardwareNotification} returns this
 */
proto.vivacity.core.HardwareNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 hardware_id = 2;
 * @return {number}
 */
proto.vivacity.core.HardwareNotification.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.HardwareNotification} returns this
 */
proto.vivacity.core.HardwareNotification.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.vivacity.core.AlertNotificationStatus = {
  UNKNOWN_ALERT_NOTIFICATION_STATUS: 0,
  ALERT_NOTIFICATION_FIRING: 1,
  ALERT_NOTIFICATION_RESOLVED: 2
};

goog.object.extend(exports, proto.vivacity.core);
