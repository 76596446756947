import React, { FC, Fragment, useMemo } from "react";
import { observer } from "mobx-react";
import { BitmapText, Container } from "react-pixi-fiber/index";
import * as PIXI from "pixi.js";
import { StoresContext } from "../../../contexts/stores.context";
import { classLookup } from "../../../workers/utils";

const fontName = "TotalsFont";
const fontNameRed = "TotalsFontRed";
const fontNameRight = "TotalsFontRight";
const fontNameRightRed = "TotalsFontRightRed";

const fontSize = 24;
const lineHeight = 32;

const textStyle = {
  fill: 0xffffff,
  fontSize: fontSize,
  dropShadow: true,
  dropShadowAlpha: 0.4,
  dropShadowDistance: 2,
  dropShadowBlur: 4,
};

const textStyleRed = {
  fill: 0xff0000,
  fontSize: fontSize,
  dropShadow: true,
  dropShadowAlpha: 0.4,
  dropShadowDistance: 2,
  dropShadowBlur: 4,
};

const textStyleRightAlign = {
  fill: 0xffffff,
  fontSize: fontSize,
  dropShadow: true,
  dropShadowAlpha: 0.4,
  dropShadowDistance: 2,
  dropShadowBlur: 4,
};

const textStyleRightAlignRed = {
  fill: 0xff0000,
  fontSize: fontSize,
  dropShadow: true,
  dropShadowAlpha: 0.4,
  dropShadowDistance: 2,
  dropShadowBlur: 4,
};

const textStyleWithName = {
  ...textStyle,
  fontName: fontName,
};

const textStyleRedWithName = {
  ...textStyleRed,
  fontName: fontNameRed,
  fontWeight: "bold",
};

const textStyleRightAlignWithName = {
  ...textStyleRightAlign,
  fontName: fontNameRight,
  align: "right",
};

const textStyleRightAlignRedWithName = {
  ...textStyleRightAlignRed,
  fontName: fontNameRightRed,
  align: "right",
  fontWeight: "bold",
};

PIXI.BitmapFont.from(fontName, textStyle, {
  chars: [["a", "z"], ["0", "9"], ["A", "Z"], " .!?-:"],
});

PIXI.BitmapFont.from(fontNameRed, textStyleRed, {
  chars: [["a", "z"], ["0", "9"], ["A", "Z"], " .!?-:/"],
});

PIXI.BitmapFont.from(fontNameRight, textStyleRightAlign, {
  chars: [["a", "z"], ["0", "9"], ["A", "Z"], " .!?-:/"],
});

PIXI.BitmapFont.from(fontNameRightRed, textStyleRightAlignRed, {
  chars: [["a", "z"], ["0", "9"], ["A", "Z"], " .!?-:/"],
});

const rightAnchor = new PIXI.Point(1, 0);

const xColOffsetHuman = 240;
const xColOffsetCV = 180;

const yOffsetCols = 130;
const yOffsetDirectionHeaders = 70;
const yOffsetCVHumanHeaders = 100;
const classNamesOffset = 150;

export const RunningTotals: FC<{ storesContext: StoresContext; width: number }> = observer(
  ({ storesContext: { urlStore, playerUIStore }, width }) => {
    const allCountlineCVTotals = playerUIStore.currentCVRunCrossingsRunningTotals;
    const allCountlineHumanTotals = playerUIStore.currentValidationRunCrossingsRunningTotals;

    const selectedCountlineCVTotals = allCountlineCVTotals.get(urlStore.selectedCountline ?? 0);
    const selectedCountlineHumanTotals = allCountlineHumanTotals.get(urlStore.selectedCountline ?? 0);

    const clockwiseCVTotals = selectedCountlineCVTotals?.get(true);
    const antiClockwiseCVTotals = selectedCountlineCVTotals?.get(false);
    const clockwiseHumanTotals = selectedCountlineHumanTotals?.get(true);
    const antiClockwiseHumanTotals = selectedCountlineHumanTotals?.get(false);

    const clockwiseCVTotalsColumn: JSX.Element[] = [];
    const anticlockwiseCVTotalsColumn: JSX.Element[] = [];
    const clockwiseHumanTotalsColumn: JSX.Element[] = [];
    const anticlockwiseHumanTotalsColumn: JSX.Element[] = [];
    const classesClockwiseColumn: JSX.Element[] = [];
    const classesAnticlockwiseColumn: JSX.Element[] = [];

    const classes = Array.from(clockwiseCVTotals?.keys() ?? []).sort((a, b) => a - b);

    if (clockwiseCVTotals && antiClockwiseCVTotals) {
      classes.forEach((classID, index) => {
        classesClockwiseColumn.push(
          <BitmapText
            key={"class-cw-" + classID}
            x={classNamesOffset}
            y={yOffsetCols + index * lineHeight}
            anchor={rightAnchor}
            fontName={fontNameRight}
            text={classLookup[classID]}
            style={textStyleRightAlignWithName}
            alpha={playerUIStore.runningTotalsTextAlpha}
          />
        );
        classesAnticlockwiseColumn.push(
          <BitmapText
            key={"class-acw-" + classID}
            x={width - classNamesOffset}
            y={yOffsetCols + index * lineHeight}
            fontName={fontName}
            text={classLookup[classID]}
            style={textStyleWithName}
            alpha={playerUIStore.runningTotalsTextAlpha}
          />
        );
        clockwiseCVTotalsColumn.push(
          <BitmapText
            fontName={fontNameRight}
            text={`${clockwiseCVTotals.get(classID)}`}
            style={textStyleRightAlignWithName}
            x={xColOffsetCV}
            y={yOffsetCols + index * lineHeight}
            alpha={playerUIStore.runningTotalsTextAlpha}
            key={"cw-cv-" + classID}
          />
        );
      });

      classes.forEach((classID, index) => {
        anticlockwiseCVTotalsColumn.push(
          <BitmapText
            fontName={fontNameRight}
            text={`${antiClockwiseCVTotals.get(classID)}`}
            style={textStyleRightAlignWithName}
            anchor={rightAnchor}
            x={width - xColOffsetCV}
            y={yOffsetCols + index * lineHeight}
            alpha={playerUIStore.runningTotalsTextAlpha}
            key={"acw-cv-" + classID}
          />
        );
      });
    }
    if (clockwiseHumanTotals && antiClockwiseHumanTotals) {
      classes.forEach((classID, index) => {
        const chosenFont =
          clockwiseHumanTotals.get(classID) === clockwiseCVTotals?.get(classID) ? fontNameRight : fontNameRightRed;
        const chosenStyle =
          clockwiseHumanTotals.get(classID) === clockwiseCVTotals?.get(classID)
            ? textStyleRightAlignWithName
            : textStyleRightAlignRedWithName;
        clockwiseHumanTotalsColumn.push(
          <BitmapText
            fontName={chosenFont}
            text={`${clockwiseHumanTotals.get(classID)}`}
            style={chosenStyle}
            x={xColOffsetHuman}
            y={yOffsetCols + index * lineHeight}
            alpha={playerUIStore.runningTotalsTextAlpha}
            key={"cw-human-" + classID}
          />
        );
      });

      classes.forEach((classID, index) => {
        const chosenFont =
          antiClockwiseHumanTotals.get(classID) === antiClockwiseCVTotals?.get(classID)
            ? fontNameRight
            : fontNameRightRed;
        const chosenStyle =
          antiClockwiseHumanTotals.get(classID) === antiClockwiseCVTotals?.get(classID)
            ? textStyleRightAlignWithName
            : textStyleRightAlignRedWithName;
        anticlockwiseHumanTotalsColumn.push(
          <BitmapText
            fontName={chosenFont}
            text={`${antiClockwiseHumanTotals.get(classID)}`}
            style={chosenStyle}
            anchor={rightAnchor}
            x={width - xColOffsetHuman}
            y={yOffsetCols + index * lineHeight}
            alpha={playerUIStore.runningTotalsTextAlpha}
            key={"acw-human-" + classID}
          />
        );
      });
    }

    if (urlStore.selectedComputerVisionRun === undefined || urlStore.selectedCountline === undefined) {
      return null;
    }

    return (
      <Container>
        <BitmapText
          x={20}
          y={40}
          fontName={fontName}
          text={`Countline ${urlStore.selectedCountline}`}
          style={textStyleWithName}
          alpha={playerUIStore.runningTotalsTextAlpha}
        />
        <BitmapText
          x={xColOffsetCV}
          y={yOffsetDirectionHeaders}
          fontName={fontName}
          text={`Clockwise`}
          style={textStyleWithName}
          alpha={playerUIStore.runningTotalsTextAlpha}
        />
        <BitmapText
          x={width - xColOffsetCV}
          y={yOffsetDirectionHeaders}
          anchor={rightAnchor}
          fontName={fontNameRight}
          text={`Anticlockwise`}
          style={textStyleRightAlignWithName}
          alpha={playerUIStore.runningTotalsTextAlpha}
        />
        <BitmapText
          x={xColOffsetCV}
          y={yOffsetCVHumanHeaders}
          fontName={fontName}
          text={`CV`}
          style={textStyleWithName}
          alpha={playerUIStore.runningTotalsTextAlpha}
        />
        <BitmapText
          x={xColOffsetHuman}
          y={yOffsetCVHumanHeaders}
          fontName={fontName}
          text={`${urlStore.selectedValidationRun && urlStore.selectedCountline ? "Human" : ""}`}
          style={textStyleWithName}
          alpha={playerUIStore.runningTotalsTextAlpha}
        />
        <BitmapText
          x={width - xColOffsetCV}
          y={yOffsetCVHumanHeaders}
          anchor={rightAnchor}
          fontName={fontNameRight}
          text={`CV`}
          style={textStyleRightAlignWithName}
          alpha={playerUIStore.runningTotalsTextAlpha}
        />
        <BitmapText
          x={width - xColOffsetHuman}
          y={yOffsetCVHumanHeaders}
          anchor={rightAnchor}
          fontName={fontNameRight}
          text={`${urlStore.selectedValidationRun && urlStore.selectedCountline ? "  Human" : ""}`}
          style={textStyleRightAlignWithName}
          alpha={playerUIStore.runningTotalsTextAlpha}
        />
        {classesClockwiseColumn}
        {classesAnticlockwiseColumn}
        {clockwiseCVTotalsColumn}
        {clockwiseHumanTotalsColumn}
        {anticlockwiseCVTotalsColumn}
        {anticlockwiseHumanTotalsColumn}
      </Container>
    );
  }
);
