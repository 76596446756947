/* eslint-disable */
// source: vivacity/config_v2/hardware.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_config_v2_primitive_wrappers_pb = require('../../vivacity/config_v2/primitive_wrappers_pb.js');
goog.object.extend(proto, vivacity_config_v2_primitive_wrappers_pb);
goog.exportSymbol('proto.vivacity.config_v2.Hardware', null, global);
goog.exportSymbol('proto.vivacity.config_v2.Hardware.Architecture', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.Hardware = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.Hardware, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.Hardware.displayName = 'proto.vivacity.config_v2.Hardware';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.Hardware.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.Hardware.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.Hardware} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Hardware.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.toObject(includeInstance, f),
    deviceuid: (f = msg.getDeviceuid()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    macAddress: (f = msg.getMacAddress()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    architecture: jspb.Message.getFieldWithDefault(msg, 4, 0),
    serialNumber: (f = msg.getSerialNumber()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    smbiosUuid: (f = msg.getSmbiosUuid()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    deprecatedProjectName: (f = msg.getDeprecatedProjectName()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    deprecatedSensorNumber: (f = msg.getDeprecatedSensorNumber()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    hardwareLocation: (f = msg.getHardwareLocation()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.toObject(includeInstance, f),
    extraValues: (f = msg.getExtraValues()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    lensNames: (f = msg.getLensNames()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.toObject(includeInstance, f),
    devManDeviceName: (f = msg.getDevManDeviceName()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    flashingMachineHostname: (f = msg.getFlashingMachineHostname()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    tegraChipId: (f = msg.getTegraChipId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    eeprom: (f = msg.getEeprom()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    doNotMonitor: (f = msg.getDoNotMonitor()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    notes: (f = msg.getNotes()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    nvTracker: (f = msg.getNvTracker()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.toObject(includeInstance, f),
    supermarioImageBase: (f = msg.getSupermarioImageBase()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    supermarioImageTag: (f = msg.getSupermarioImageTag()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    supermarioHttpPort: (f = msg.getSupermarioHttpPort()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.toObject(includeInstance, f),
    doNotMonitorReason: (f = msg.getDoNotMonitorReason()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    alertIfUp: (f = msg.getAlertIfUp()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.toObject(includeInstance, f),
    alertIfUpReason: (f = msg.getAlertIfUpReason()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    hwmanVersion: (f = msg.getHwmanVersion()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.toObject(includeInstance, f),
    mrpCoNumbers: (f = msg.getMrpCoNumbers()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.Hardware}
 */
proto.vivacity.config_v2.Hardware.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.Hardware;
  return proto.vivacity.config_v2.Hardware.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.Hardware} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.Hardware}
 */
proto.vivacity.config_v2.Hardware.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setDeviceuid(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setMacAddress(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config_v2.Hardware.Architecture} */ (reader.readEnum());
      msg.setArchitecture(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setSerialNumber(value);
      break;
    case 6:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setSmbiosUuid(value);
      break;
    case 7:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setDeprecatedProjectName(value);
      break;
    case 8:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setDeprecatedSensorNumber(value);
      break;
    case 9:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.deserializeBinaryFromReader);
      msg.setHardwareLocation(value);
      break;
    case 10:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setExtraValues(value);
      break;
    case 11:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.deserializeBinaryFromReader);
      msg.setLensNames(value);
      break;
    case 12:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setDevManDeviceName(value);
      break;
    case 13:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setFlashingMachineHostname(value);
      break;
    case 14:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setTegraChipId(value);
      break;
    case 15:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setEeprom(value);
      break;
    case 16:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setDoNotMonitor(value);
      break;
    case 17:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 18:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setNotes(value);
      break;
    case 19:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.deserializeBinaryFromReader);
      msg.setNvTracker(value);
      break;
    case 20:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setSupermarioImageBase(value);
      break;
    case 21:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setSupermarioImageTag(value);
      break;
    case 22:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.deserializeBinaryFromReader);
      msg.setSupermarioHttpPort(value);
      break;
    case 23:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setDoNotMonitorReason(value);
      break;
    case 24:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.deserializeBinaryFromReader);
      msg.setAlertIfUp(value);
      break;
    case 25:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setAlertIfUpReason(value);
      break;
    case 26:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.deserializeBinaryFromReader);
      msg.setHwmanVersion(value);
      break;
    case 27:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array.deserializeBinaryFromReader);
      msg.setMrpCoNumbers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.Hardware.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.Hardware.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.Hardware} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.Hardware.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id.serializeBinaryToWriter
    );
  }
  f = message.getDeviceuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getMacAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.vivacity.config_v2.Hardware.Architecture} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSerialNumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSmbiosUuid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getDeprecatedProjectName();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDeprecatedSensorNumber();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getHardwareLocation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point.serializeBinaryToWriter
    );
  }
  f = message.getExtraValues();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = message.getLensNames();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray.serializeBinaryToWriter
    );
  }
  f = message.getDevManDeviceName();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFlashingMachineHostname();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTegraChipId();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEeprom();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDoNotMonitor();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNvTracker();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue.serializeBinaryToWriter
    );
  }
  f = message.getSupermarioImageBase();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSupermarioImageTag();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSupermarioHttpPort();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getDoNotMonitorReason();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAlertIfUp();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAlertIfUpReason();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHwmanVersion();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMrpCoNumbers();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config_v2.Hardware.Architecture = {
  UNKNOWN: 0,
  AMD64: 1,
  ARM64: 2
};

/**
 * optional PrimitiveWrapper.PrimaryKey.Id id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id}
 */
proto.vivacity.config_v2.Hardware.prototype.getId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.PrimaryKey.Id, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.PrimaryKey.Id|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.UUIDValue deviceuid = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getDeviceuid = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setDeviceuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearDeviceuid = function() {
  return this.setDeviceuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasDeviceuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.StringValue mac_address = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getMacAddress = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setMacAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearMacAddress = function() {
  return this.setMacAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasMacAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Architecture architecture = 4;
 * @return {!proto.vivacity.config_v2.Hardware.Architecture}
 */
proto.vivacity.config_v2.Hardware.prototype.getArchitecture = function() {
  return /** @type {!proto.vivacity.config_v2.Hardware.Architecture} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config_v2.Hardware.Architecture} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.setArchitecture = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearArchitecture = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasArchitecture = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.StringValue serial_number = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getSerialNumber = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setSerialNumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearSerialNumber = function() {
  return this.setSerialNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasSerialNumber = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrimitiveWrapper.UUIDValue smbios_uuid = 6;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getSmbiosUuid = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 6));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setSmbiosUuid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearSmbiosUuid = function() {
  return this.setSmbiosUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasSmbiosUuid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PrimitiveWrapper.StringValue deprecated_project_name = 7;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getDeprecatedProjectName = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 7));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setDeprecatedProjectName = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearDeprecatedProjectName = function() {
  return this.setDeprecatedProjectName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasDeprecatedProjectName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value deprecated_sensor_number = 8;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.Hardware.prototype.getDeprecatedSensorNumber = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 8));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setDeprecatedSensorNumber = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearDeprecatedSensorNumber = function() {
  return this.setDeprecatedSensorNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasDeprecatedSensorNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional PrimitiveWrapper.Geography.Point hardware_location = 9;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point}
 */
proto.vivacity.config_v2.Hardware.prototype.getHardwareLocation = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.Geography.Point, 9));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.Geography.Point|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setHardwareLocation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearHardwareLocation = function() {
  return this.setHardwareLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasHardwareLocation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue extra_values = 10;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getExtraValues = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 10));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setExtraValues = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearExtraValues = function() {
  return this.setExtraValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasExtraValues = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PrimitiveWrapper.StringArray lens_names = 11;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray}
 */
proto.vivacity.config_v2.Hardware.prototype.getLensNames = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringArray} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringArray, 11));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringArray|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setLensNames = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearLensNames = function() {
  return this.setLensNames(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasLensNames = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PrimitiveWrapper.StringValue dev_man_device_name = 12;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getDevManDeviceName = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 12));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setDevManDeviceName = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearDevManDeviceName = function() {
  return this.setDevManDeviceName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasDevManDeviceName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PrimitiveWrapper.StringValue flashing_machine_hostname = 13;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getFlashingMachineHostname = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 13));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setFlashingMachineHostname = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearFlashingMachineHostname = function() {
  return this.setFlashingMachineHostname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasFlashingMachineHostname = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PrimitiveWrapper.StringValue tegra_chip_id = 14;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getTegraChipId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 14));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setTegraChipId = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearTegraChipId = function() {
  return this.setTegraChipId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasTegraChipId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PrimitiveWrapper.StringValue eeprom = 15;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getEeprom = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 15));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setEeprom = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearEeprom = function() {
  return this.setEeprom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasEeprom = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue do_not_monitor = 16;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getDoNotMonitor = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 16));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setDoNotMonitor = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearDoNotMonitor = function() {
  return this.setDoNotMonitor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasDoNotMonitor = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PrimitiveWrapper.StringValue description = 17;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getDescription = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 17));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PrimitiveWrapper.StringValue notes = 18;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getNotes = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 18));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setNotes = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearNotes = function() {
  return this.setNotes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasNotes = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PrimitiveWrapper.JsonValue nv_tracker = 19;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getNvTracker = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.JsonValue, 19));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.JsonValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setNvTracker = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearNvTracker = function() {
  return this.setNvTracker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasNvTracker = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PrimitiveWrapper.StringValue supermario_image_base = 20;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getSupermarioImageBase = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 20));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setSupermarioImageBase = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearSupermarioImageBase = function() {
  return this.setSupermarioImageBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasSupermarioImageBase = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PrimitiveWrapper.StringValue supermario_image_tag = 21;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getSupermarioImageTag = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 21));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setSupermarioImageTag = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearSupermarioImageTag = function() {
  return this.setSupermarioImageTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasSupermarioImageTag = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Value supermario_http_port = 22;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value}
 */
proto.vivacity.config_v2.Hardware.prototype.getSupermarioHttpPort = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Value, 22));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Value|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setSupermarioHttpPort = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearSupermarioHttpPort = function() {
  return this.setSupermarioHttpPort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasSupermarioHttpPort = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional PrimitiveWrapper.StringValue do_not_monitor_reason = 23;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getDoNotMonitorReason = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 23));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setDoNotMonitorReason = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearDoNotMonitorReason = function() {
  return this.setDoNotMonitorReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasDoNotMonitorReason = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PrimitiveWrapper.BoolValue alert_if_up = 24;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getAlertIfUp = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.BoolValue, 24));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.BoolValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setAlertIfUp = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearAlertIfUp = function() {
  return this.setAlertIfUp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasAlertIfUp = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PrimitiveWrapper.StringValue alert_if_up_reason = 25;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getAlertIfUpReason = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 25));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setAlertIfUpReason = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearAlertIfUpReason = function() {
  return this.setAlertIfUpReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasAlertIfUpReason = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional PrimitiveWrapper.StringValue hwman_version = 26;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue}
 */
proto.vivacity.config_v2.Hardware.prototype.getHwmanVersion = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.StringValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.StringValue, 26));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.StringValue|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setHwmanVersion = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearHwmanVersion = function() {
  return this.setHwmanVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasHwmanVersion = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional PrimitiveWrapper.UInt32Array mrp_co_numbers = 27;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array}
 */
proto.vivacity.config_v2.Hardware.prototype.getMrpCoNumbers = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UInt32Array, 27));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UInt32Array|undefined} value
 * @return {!proto.vivacity.config_v2.Hardware} returns this
*/
proto.vivacity.config_v2.Hardware.prototype.setMrpCoNumbers = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.Hardware} returns this
 */
proto.vivacity.config_v2.Hardware.prototype.clearMrpCoNumbers = function() {
  return this.setMrpCoNumbers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.Hardware.prototype.hasMrpCoNumbers = function() {
  return jspb.Message.getField(this, 27) != null;
};


goog.object.extend(exports, proto.vivacity.config_v2);
