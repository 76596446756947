/* eslint-disable */
// source: vivacity/benchmarking/cv_model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.vivacity.benchmarking.CvModel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.benchmarking.CvModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.benchmarking.CvModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.benchmarking.CvModel.displayName = 'proto.vivacity.benchmarking.CvModel';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.benchmarking.CvModel.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.benchmarking.CvModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.benchmarking.CvModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.CvModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    resWidth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    resHeight: jspb.Message.getFieldWithDefault(msg, 5, 0),
    weightsLocation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    configLocation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    md5Hash: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.benchmarking.CvModel}
 */
proto.vivacity.benchmarking.CvModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.benchmarking.CvModel;
  return proto.vivacity.benchmarking.CvModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.benchmarking.CvModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.benchmarking.CvModel}
 */
proto.vivacity.benchmarking.CvModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setResWidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setResHeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeightsLocation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigLocation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMd5Hash(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.benchmarking.CvModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.benchmarking.CvModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.benchmarking.CvModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.benchmarking.CvModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResWidth();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getResHeight();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getWeightsLocation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getConfigLocation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMd5Hash();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.vivacity.benchmarking.CvModel.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_type = 2;
 * @return {string}
 */
proto.vivacity.benchmarking.CvModel.prototype.getModelType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setModelType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vivacity.benchmarking.CvModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 res_width = 4;
 * @return {number}
 */
proto.vivacity.benchmarking.CvModel.prototype.getResWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setResWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 res_height = 5;
 * @return {number}
 */
proto.vivacity.benchmarking.CvModel.prototype.getResHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setResHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string weights_location = 6;
 * @return {string}
 */
proto.vivacity.benchmarking.CvModel.prototype.getWeightsLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setWeightsLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string config_location = 7;
 * @return {string}
 */
proto.vivacity.benchmarking.CvModel.prototype.getConfigLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setConfigLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string md5_hash = 8;
 * @return {string}
 */
proto.vivacity.benchmarking.CvModel.prototype.getMd5Hash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setMd5Hash = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string user_id = 9;
 * @return {string}
 */
proto.vivacity.benchmarking.CvModel.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.benchmarking.CvModel} returns this
 */
proto.vivacity.benchmarking.CvModel.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


goog.object.extend(exports, proto.vivacity.benchmarking);
