/* eslint-disable */
// source: vivacity/config/instation_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.vivacity.config.ControllerStreamControlSchedule', null, global);
goog.exportSymbol('proto.vivacity.config.Detoxicity', null, global);
goog.exportSymbol('proto.vivacity.config.Velocity', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.Velocity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.Velocity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.Velocity.displayName = 'proto.vivacity.config.Velocity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.Detoxicity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.Detoxicity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.Detoxicity.displayName = 'proto.vivacity.config.Detoxicity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ControllerStreamControlSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ControllerStreamControlSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ControllerStreamControlSchedule.displayName = 'proto.vivacity.config.ControllerStreamControlSchedule';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.Velocity.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.Velocity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.Velocity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Velocity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    supermarioImageTag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    useMultimodal: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    activeAgentId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    useAgent: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    detoxicityId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    supermarioPipelineVersionTag: jspb.Message.getFieldWithDefault(msg, 8, ""),
    useZonesClassified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    useZonesAggregated: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    useZonesClaggregated: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    useZonesDirectional: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    useZonesSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    useZonesStopped: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    useCountlinesClassified: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    useCountlinesAggregated: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    useCountlinesClaggregated: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    useCountlinesSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    extraValuesJson: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.Velocity}
 */
proto.vivacity.config.Velocity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.Velocity;
  return proto.vivacity.config.Velocity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.Velocity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.Velocity}
 */
proto.vivacity.config.Velocity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioImageTag(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseMultimodal(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActiveAgentId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseAgent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDetoxicityId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioPipelineVersionTag(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseZonesClassified(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseZonesAggregated(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseZonesClaggregated(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseZonesDirectional(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseZonesSpeed(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseZonesStopped(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseCountlinesClassified(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseCountlinesAggregated(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseCountlinesClaggregated(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseCountlinesSpeed(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.Velocity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.Velocity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.Velocity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Velocity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRegionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSupermarioImageTag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUseMultimodal();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getActiveAgentId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getUseAgent();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDetoxicityId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getSupermarioPipelineVersionTag();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUseZonesClassified();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUseZonesAggregated();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getUseZonesClaggregated();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getUseZonesDirectional();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getUseZonesSpeed();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getUseZonesStopped();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getUseCountlinesClassified();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getUseCountlinesAggregated();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getUseCountlinesClaggregated();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getUseCountlinesSpeed();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getExtraValuesJson();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.Velocity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 region_id = 2;
 * @return {number}
 */
proto.vivacity.config.Velocity.prototype.getRegionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string supermario_image_tag = 3;
 * @return {string}
 */
proto.vivacity.config.Velocity.prototype.getSupermarioImageTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setSupermarioImageTag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool use_multimodal = 4;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseMultimodal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseMultimodal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional uint32 active_agent_id = 5;
 * @return {number}
 */
proto.vivacity.config.Velocity.prototype.getActiveAgentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setActiveAgentId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool use_agent = 6;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseAgent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseAgent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint32 detoxicity_id = 7;
 * @return {number}
 */
proto.vivacity.config.Velocity.prototype.getDetoxicityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setDetoxicityId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string supermario_pipeline_version_tag = 8;
 * @return {string}
 */
proto.vivacity.config.Velocity.prototype.getSupermarioPipelineVersionTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setSupermarioPipelineVersionTag = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool use_zones_classified = 9;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseZonesClassified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseZonesClassified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool use_zones_aggregated = 10;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseZonesAggregated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseZonesAggregated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool use_zones_claggregated = 11;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseZonesClaggregated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseZonesClaggregated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool use_zones_directional = 12;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseZonesDirectional = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseZonesDirectional = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool use_zones_speed = 13;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseZonesSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseZonesSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool use_zones_stopped = 14;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseZonesStopped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseZonesStopped = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool use_countlines_classified = 15;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseCountlinesClassified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseCountlinesClassified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool use_countlines_aggregated = 16;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseCountlinesAggregated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseCountlinesAggregated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool use_countlines_claggregated = 17;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseCountlinesClaggregated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseCountlinesClaggregated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool use_countlines_speed = 18;
 * @return {boolean}
 */
proto.vivacity.config.Velocity.prototype.getUseCountlinesSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setUseCountlinesSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string extra_values_json = 19;
 * @return {string}
 */
proto.vivacity.config.Velocity.prototype.getExtraValuesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Velocity} returns this
 */
proto.vivacity.config.Velocity.prototype.setExtraValuesJson = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.Detoxicity.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.Detoxicity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.Detoxicity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Detoxicity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    supermarioImageTag: jspb.Message.getFieldWithDefault(msg, 3, ""),
    useZeroFilling: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    supermarioPipelineVersionTag: jspb.Message.getFieldWithDefault(msg, 5, ""),
    extraValuesJson: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.Detoxicity}
 */
proto.vivacity.config.Detoxicity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.Detoxicity;
  return proto.vivacity.config.Detoxicity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.Detoxicity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.Detoxicity}
 */
proto.vivacity.config.Detoxicity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioImageTag(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseZeroFilling(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioPipelineVersionTag(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.Detoxicity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.Detoxicity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.Detoxicity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.Detoxicity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRegionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSupermarioImageTag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUseZeroFilling();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSupermarioPipelineVersionTag();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExtraValuesJson();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.Detoxicity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Detoxicity} returns this
 */
proto.vivacity.config.Detoxicity.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 region_id = 2;
 * @return {number}
 */
proto.vivacity.config.Detoxicity.prototype.getRegionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.Detoxicity} returns this
 */
proto.vivacity.config.Detoxicity.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string supermario_image_tag = 3;
 * @return {string}
 */
proto.vivacity.config.Detoxicity.prototype.getSupermarioImageTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Detoxicity} returns this
 */
proto.vivacity.config.Detoxicity.prototype.setSupermarioImageTag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool use_zero_filling = 4;
 * @return {boolean}
 */
proto.vivacity.config.Detoxicity.prototype.getUseZeroFilling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.Detoxicity} returns this
 */
proto.vivacity.config.Detoxicity.prototype.setUseZeroFilling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string supermario_pipeline_version_tag = 5;
 * @return {string}
 */
proto.vivacity.config.Detoxicity.prototype.getSupermarioPipelineVersionTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Detoxicity} returns this
 */
proto.vivacity.config.Detoxicity.prototype.setSupermarioPipelineVersionTag = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string extra_values_json = 6;
 * @return {string}
 */
proto.vivacity.config.Detoxicity.prototype.getExtraValuesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.Detoxicity} returns this
 */
proto.vivacity.config.Detoxicity.prototype.setExtraValuesJson = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ControllerStreamControlSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ControllerStreamControlSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ControllerStreamControlSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    controllerStreamId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTimeUtc: (f = msg.getStartTimeUtc()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTimeUtc: (f = msg.getEndTimeUtc()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    supportEngineerEmailAddress: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule}
 */
proto.vivacity.config.ControllerStreamControlSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ControllerStreamControlSchedule;
  return proto.vivacity.config.ControllerStreamControlSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ControllerStreamControlSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule}
 */
proto.vivacity.config.ControllerStreamControlSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setControllerStreamId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTimeUtc(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTimeUtc(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupportEngineerEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ControllerStreamControlSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ControllerStreamControlSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ControllerStreamControlSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getControllerStreamId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStartTimeUtc();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTimeUtc();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSupportEngineerEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule} returns this
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 controller_stream_id = 2;
 * @return {number}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.getControllerStreamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule} returns this
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.setControllerStreamId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp start_time_utc = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.getStartTimeUtc = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule} returns this
*/
proto.vivacity.config.ControllerStreamControlSchedule.prototype.setStartTimeUtc = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule} returns this
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.clearStartTimeUtc = function() {
  return this.setStartTimeUtc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.hasStartTimeUtc = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end_time_utc = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.getEndTimeUtc = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule} returns this
*/
proto.vivacity.config.ControllerStreamControlSchedule.prototype.setEndTimeUtc = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule} returns this
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.clearEndTimeUtc = function() {
  return this.setEndTimeUtc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.hasEndTimeUtc = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule} returns this
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string support_engineer_email_address = 6;
 * @return {string}
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.getSupportEngineerEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ControllerStreamControlSchedule} returns this
 */
proto.vivacity.config.ControllerStreamControlSchedule.prototype.setSupportEngineerEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


goog.object.extend(exports, proto.vivacity.config);
