/* eslint-disable */
// source: vivacity/core/viu_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_controller_state_pb = require('../../vivacity/core/controller_state_pb.js');
goog.object.extend(proto, vivacity_core_controller_state_pb);
var vivacity_core_zonal_features_pb = require('../../vivacity/core/zonal_features_pb.js');
goog.object.extend(proto, vivacity_core_zonal_features_pb);
goog.exportSymbol('proto.vivacity.core.AgentActions', null, global);
goog.exportSymbol('proto.vivacity.core.VIUMessage', null, global);
goog.exportSymbol('proto.vivacity.core.VIUMessage.MessageType', null, global);
goog.exportSymbol('proto.vivacity.core.VIUMessage.VIUComponent', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.VIUMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.VIUMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.VIUMessage.displayName = 'proto.vivacity.core.VIUMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AgentActions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.AgentActions.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.AgentActions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AgentActions.displayName = 'proto.vivacity.core.AgentActions';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.VIUMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.VIUMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.VIUMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.VIUMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    viuId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    destinationTopic: jspb.Message.getFieldWithDefault(msg, 6, ""),
    partitionKey: jspb.Message.getFieldWithDefault(msg, 7, ""),
    viuComponent: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sourceChannel: jspb.Message.getFieldWithDefault(msg, 9, ""),
    controllerState: (f = msg.getControllerState()) && vivacity_core_controller_state_pb.ControllerState.toObject(includeInstance, f),
    zoneOrientedFeatures: (f = msg.getZoneOrientedFeatures()) && vivacity_core_zonal_features_pb.ZonalFeatures.toObject(includeInstance, f),
    rawBytesMessage: msg.getRawBytesMessage_asB64(),
    rawStringMessage: jspb.Message.getFieldWithDefault(msg, 101, ""),
    jsonMessage: jspb.Message.getFieldWithDefault(msg, 102, ""),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.VIUMessage}
 */
proto.vivacity.core.VIUMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.VIUMessage;
  return proto.vivacity.core.VIUMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.VIUMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.VIUMessage}
 */
proto.vivacity.core.VIUMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.VIUMessage.MessageType} */ (reader.readEnum());
      msg.setMessageType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViuId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProjectId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationTopic(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartitionKey(value);
      break;
    case 8:
      var value = /** @type {!proto.vivacity.core.VIUMessage.VIUComponent} */ (reader.readEnum());
      msg.setViuComponent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceChannel(value);
      break;
    case 11:
      var value = new vivacity_core_controller_state_pb.ControllerState;
      reader.readMessage(value,vivacity_core_controller_state_pb.ControllerState.deserializeBinaryFromReader);
      msg.setControllerState(value);
      break;
    case 12:
      var value = new vivacity_core_zonal_features_pb.ZonalFeatures;
      reader.readMessage(value,vivacity_core_zonal_features_pb.ZonalFeatures.deserializeBinaryFromReader);
      msg.setZoneOrientedFeatures(value);
      break;
    case 100:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRawBytesMessage(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawStringMessage(value);
      break;
    case 102:
      var value = /** @type {string} */ (reader.readString());
      msg.setJsonMessage(value);
      break;
    case 110:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.VIUMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.VIUMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.VIUMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.VIUMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessageVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getViuId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getDestinationTopic();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPartitionKey();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getViuComponent();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getSourceChannel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getControllerState();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_core_controller_state_pb.ControllerState.serializeBinaryToWriter
    );
  }
  f = message.getZoneOrientedFeatures();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_core_zonal_features_pb.ZonalFeatures.serializeBinaryToWriter
    );
  }
  f = message.getRawBytesMessage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      100,
      f
    );
  }
  f = message.getRawStringMessage();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getJsonMessage();
  if (f.length > 0) {
    writer.writeString(
      102,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.VIUMessage.MessageType = {
  UNKNOWN: 0,
  CONTROLLER_STATE: 11,
  OCCUPANCY_MESSAGE: 12,
  RAW_STRING_MESSAGE: 100,
  RAW_BYTES_MESSAGE: 101,
  JSON_MESSAGE: 102
};

/**
 * @enum {number}
 */
proto.vivacity.core.VIUMessage.VIUComponent = {
  UNKNOWN_COMPONENT: 0,
  SOLICITY: 20,
  DETOXICITY: 40,
  VELOCITY: 60,
  DETERMINISTIFIER: 80,
  PACEMAKER: 100,
  SUPERVISOR: 120,
  BILGE_PUMP: 140,
  MIOPYC_SERVICE: 160,
  S4TC_BILGE_PUMP_LIU_IO_CONNECTOR: 180,
  SJ_BILGE_PUMP_LIU_IO_CONNECTOR: 200
};

/**
 * optional MessageType message_type = 1;
 * @return {!proto.vivacity.core.VIUMessage.MessageType}
 */
proto.vivacity.core.VIUMessage.prototype.getMessageType = function() {
  return /** @type {!proto.vivacity.core.VIUMessage.MessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.VIUMessage.MessageType} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message_version = 2;
 * @return {string}
 */
proto.vivacity.core.VIUMessage.prototype.getMessageVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setMessageVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 viu_id = 3;
 * @return {number}
 */
proto.vivacity.core.VIUMessage.prototype.getViuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setViuId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 project_id = 4;
 * @return {number}
 */
proto.vivacity.core.VIUMessage.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 timestamp = 5;
 * @return {number}
 */
proto.vivacity.core.VIUMessage.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string destination_topic = 6;
 * @return {string}
 */
proto.vivacity.core.VIUMessage.prototype.getDestinationTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setDestinationTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string partition_key = 7;
 * @return {string}
 */
proto.vivacity.core.VIUMessage.prototype.getPartitionKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setPartitionKey = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional VIUComponent viu_component = 8;
 * @return {!proto.vivacity.core.VIUMessage.VIUComponent}
 */
proto.vivacity.core.VIUMessage.prototype.getViuComponent = function() {
  return /** @type {!proto.vivacity.core.VIUMessage.VIUComponent} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vivacity.core.VIUMessage.VIUComponent} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setViuComponent = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string source_channel = 9;
 * @return {string}
 */
proto.vivacity.core.VIUMessage.prototype.getSourceChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setSourceChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional ControllerState controller_state = 11;
 * @return {?proto.vivacity.core.ControllerState}
 */
proto.vivacity.core.VIUMessage.prototype.getControllerState = function() {
  return /** @type{?proto.vivacity.core.ControllerState} */ (
    jspb.Message.getWrapperField(this, vivacity_core_controller_state_pb.ControllerState, 11));
};


/**
 * @param {?proto.vivacity.core.ControllerState|undefined} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
*/
proto.vivacity.core.VIUMessage.prototype.setControllerState = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.clearControllerState = function() {
  return this.setControllerState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VIUMessage.prototype.hasControllerState = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ZonalFeatures zone_oriented_features = 12;
 * @return {?proto.vivacity.core.ZonalFeatures}
 */
proto.vivacity.core.VIUMessage.prototype.getZoneOrientedFeatures = function() {
  return /** @type{?proto.vivacity.core.ZonalFeatures} */ (
    jspb.Message.getWrapperField(this, vivacity_core_zonal_features_pb.ZonalFeatures, 12));
};


/**
 * @param {?proto.vivacity.core.ZonalFeatures|undefined} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
*/
proto.vivacity.core.VIUMessage.prototype.setZoneOrientedFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.clearZoneOrientedFeatures = function() {
  return this.setZoneOrientedFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VIUMessage.prototype.hasZoneOrientedFeatures = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bytes raw_bytes_message = 100;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.VIUMessage.prototype.getRawBytesMessage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * optional bytes raw_bytes_message = 100;
 * This is a type-conversion wrapper around `getRawBytesMessage()`
 * @return {string}
 */
proto.vivacity.core.VIUMessage.prototype.getRawBytesMessage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRawBytesMessage()));
};


/**
 * optional bytes raw_bytes_message = 100;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRawBytesMessage()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.VIUMessage.prototype.getRawBytesMessage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRawBytesMessage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setRawBytesMessage = function(value) {
  return jspb.Message.setProto3BytesField(this, 100, value);
};


/**
 * optional string raw_string_message = 101;
 * @return {string}
 */
proto.vivacity.core.VIUMessage.prototype.getRawStringMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setRawStringMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional string json_message = 102;
 * @return {string}
 */
proto.vivacity.core.VIUMessage.prototype.getJsonMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 102, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.setJsonMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 102, value);
};


/**
 * optional TracingContext tracing_ctx = 110;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.VIUMessage.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 110));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.VIUMessage} returns this
*/
proto.vivacity.core.VIUMessage.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VIUMessage} returns this
 */
proto.vivacity.core.VIUMessage.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VIUMessage.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 110) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.AgentActions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AgentActions.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AgentActions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AgentActions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AgentActions.toObject = function(includeInstance, msg) {
  var f, obj = {
    viuMessagesList: jspb.Message.toObjectList(msg.getViuMessagesList(),
    proto.vivacity.core.VIUMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AgentActions}
 */
proto.vivacity.core.AgentActions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AgentActions;
  return proto.vivacity.core.AgentActions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AgentActions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AgentActions}
 */
proto.vivacity.core.AgentActions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.VIUMessage;
      reader.readMessage(value,proto.vivacity.core.VIUMessage.deserializeBinaryFromReader);
      msg.addViuMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AgentActions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AgentActions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AgentActions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AgentActions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViuMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.core.VIUMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VIUMessage viu_messages = 1;
 * @return {!Array<!proto.vivacity.core.VIUMessage>}
 */
proto.vivacity.core.AgentActions.prototype.getViuMessagesList = function() {
  return /** @type{!Array<!proto.vivacity.core.VIUMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.VIUMessage, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.VIUMessage>} value
 * @return {!proto.vivacity.core.AgentActions} returns this
*/
proto.vivacity.core.AgentActions.prototype.setViuMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.VIUMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.VIUMessage}
 */
proto.vivacity.core.AgentActions.prototype.addViuMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.VIUMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AgentActions} returns this
 */
proto.vivacity.core.AgentActions.prototype.clearViuMessagesList = function() {
  return this.setViuMessagesList([]);
};


goog.object.extend(exports, proto.vivacity.core);
