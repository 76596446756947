/* eslint-disable */
// source: vivacity/core/classifying_detector_class_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.vivacity.core.ClassConfidence', null, global);
goog.exportSymbol('proto.vivacity.core.ClassifyingDetectorClassTypes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ClassConfidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ClassConfidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ClassConfidence.displayName = 'proto.vivacity.core.ClassConfidence';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ClassConfidence.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ClassConfidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ClassConfidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassConfidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    classType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    classConfidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ClassConfidence}
 */
proto.vivacity.core.ClassConfidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ClassConfidence;
  return proto.vivacity.core.ClassConfidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ClassConfidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ClassConfidence}
 */
proto.vivacity.core.ClassConfidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClassType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClassConfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ClassConfidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ClassConfidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ClassConfidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassConfidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getClassConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional ClassifyingDetectorClassTypes class_type = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.ClassConfidence.prototype.getClassType = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.ClassConfidence} returns this
 */
proto.vivacity.core.ClassConfidence.prototype.setClassType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float class_confidence = 2;
 * @return {number}
 */
proto.vivacity.core.ClassConfidence.prototype.getClassConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassConfidence} returns this
 */
proto.vivacity.core.ClassConfidence.prototype.setClassConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.vivacity.core.ClassifyingDetectorClassTypes = {
  UNKNOWN_CLASS_TYPE: 0,
  PEDESTRIAN: 1,
  CYCLIST: 2,
  MOTORBIKE: 3,
  CAR: 4,
  TAXI: 5,
  VAN: 6,
  MINIBUS: 7,
  BUS: 8,
  RIGID: 9,
  TRUCK: 10,
  EMERGENCY_CAR: 11,
  EMERGENCY_VAN: 12,
  FIRE_ENGINE: 13,
  WHEELCHAIR: 14,
  MOBILITY_SCOOTER: 15,
  PUSHCHAIR: 16,
  JOGGER: 17,
  E_SCOOTER: 18,
  PUSH_SCOOTER: 19,
  RENTAL_BIKE: 20,
  CARGO_COURIER_BIKE: 21,
  TAXI_ELECTRIC: 22,
  TAXI_OTHER: 23,
  VAN_SMALL: 24,
  VAN_LUTON: 25,
  BUS_COACH: 26,
  BUS_LONDON: 27,
  TOWED_TRAILER: 28,
  TRACTOR: 29,
  AGRICULTURAL_VEHICLE: 30,
  HORSE_RIDER: 31,
  DOG: 32,
  TAXI_CAB_YELLOW: 33,
  BUS_SCHOOL: 34,
  TRAM: 35,
  TOWED_RECREATIONAL_VEHICLE: 36,
  RECREATIONAL_VEHICLE: 37,
  PICKUP_TRUCK_SMALL: 38,
  PICKUP_TRUCK_LARGE: 39,
  LICENSE_PLATE: 60,
  POWERED_WATERCRAFT: 70,
  UNPOWERED_WATERCRAFT: 71,
  PLAYER: 100,
  MAINTENANCE_VEHICLE: 101,
  LAWNMOWER: 102,
  OCR_A: 10000,
  OCR_B: 10001,
  OCR_C: 10002,
  OCR_D: 10003,
  OCR_E: 10004,
  OCR_F: 10005,
  OCR_G: 10006,
  OCR_H: 10007,
  OCR_I: 10008,
  OCR_J: 10009,
  OCR_K: 10010,
  OCR_L: 10011,
  OCR_M: 10012,
  OCR_N: 10013,
  OCR_O: 10014,
  OCR_P: 10015,
  OCR_Q: 10016,
  OCR_R: 10017,
  OCR_S: 10018,
  OCR_T: 10019,
  OCR_U: 10020,
  OCR_V: 10021,
  OCR_W: 10022,
  OCR_X: 10023,
  OCR_Y: 10024,
  OCR_Z: 10025,
  OCR_0: 10026,
  OCR_1: 10027,
  OCR_2: 10028,
  OCR_3: 10029,
  OCR_4: 10030,
  OCR_5: 10031,
  OCR_6: 10032,
  OCR_7: 10033,
  OCR_8: 10034,
  OCR_9: 10035
};

goog.object.extend(exports, proto.vivacity.core);
