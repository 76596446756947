/* eslint-disable */
// source: vivacity/core/snappi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_scene_capture_trigger_reasons_pb = require('../../vivacity/core/scene_capture_trigger_reasons_pb.js');
goog.object.extend(proto, vivacity_core_scene_capture_trigger_reasons_pb);
goog.exportSymbol('proto.vivacity.core.SnappiImageCapture', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiImageCapture.SnappiImageType', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiImageCapture.UpdateType', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiImageCaptureSnapshotRecorded', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiImageCaptureSnapshotRequested', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiImageCaptureUploadComplete', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCapture', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCapture.UpdateType', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCaptureRecordingRequested', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCaptureRecordingRequested.VideoType', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCaptureRecordingStarted', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCaptureRecordingStarted.VideoType', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCaptureRecordingUpdate', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCaptureUploadComplete', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCaptureUploadCreated', null, global);
goog.exportSymbol('proto.vivacity.core.SnappiVideoCaptureUploadUpdate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiVideoCapture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiVideoCapture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiVideoCapture.displayName = 'proto.vivacity.core.SnappiVideoCapture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.vivacity.core.SnappiVideoCaptureRecordingRequested, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiVideoCaptureRecordingRequested.displayName = 'proto.vivacity.core.SnappiVideoCaptureRecordingRequested';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.vivacity.core.SnappiVideoCaptureRecordingStarted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiVideoCaptureRecordingStarted.displayName = 'proto.vivacity.core.SnappiVideoCaptureRecordingStarted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiVideoCaptureRecordingUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.displayName = 'proto.vivacity.core.SnappiVideoCaptureRecordingUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiVideoCaptureUploadCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiVideoCaptureUploadCreated.displayName = 'proto.vivacity.core.SnappiVideoCaptureUploadCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiVideoCaptureUploadUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiVideoCaptureUploadUpdate.displayName = 'proto.vivacity.core.SnappiVideoCaptureUploadUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiVideoCaptureUploadComplete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiVideoCaptureUploadComplete.displayName = 'proto.vivacity.core.SnappiVideoCaptureUploadComplete';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiImageCapture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiImageCapture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiImageCapture.displayName = 'proto.vivacity.core.SnappiImageCapture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiImageCaptureSnapshotRequested, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiImageCaptureSnapshotRequested.displayName = 'proto.vivacity.core.SnappiImageCaptureSnapshotRequested';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiImageCaptureSnapshotRecorded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.displayName = 'proto.vivacity.core.SnappiImageCaptureSnapshotRecorded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.SnappiImageCaptureUploadComplete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.SnappiImageCaptureUploadComplete.displayName = 'proto.vivacity.core.SnappiImageCaptureUploadComplete';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiVideoCapture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiVideoCapture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCapture.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requestId: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    videoId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordingRequested: (f = msg.getRecordingRequested()) && proto.vivacity.core.SnappiVideoCaptureRecordingRequested.toObject(includeInstance, f),
    recordingStarted: (f = msg.getRecordingStarted()) && proto.vivacity.core.SnappiVideoCaptureRecordingStarted.toObject(includeInstance, f),
    recordingUpdate: (f = msg.getRecordingUpdate()) && proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.toObject(includeInstance, f),
    uploadCreated: (f = msg.getUploadCreated()) && proto.vivacity.core.SnappiVideoCaptureUploadCreated.toObject(includeInstance, f),
    uploadUpdate: (f = msg.getUploadUpdate()) && proto.vivacity.core.SnappiVideoCaptureUploadUpdate.toObject(includeInstance, f),
    uploadComplete: (f = msg.getUploadComplete()) && proto.vivacity.core.SnappiVideoCaptureUploadComplete.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiVideoCapture}
 */
proto.vivacity.core.SnappiVideoCapture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiVideoCapture;
  return proto.vivacity.core.SnappiVideoCapture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiVideoCapture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiVideoCapture}
 */
proto.vivacity.core.SnappiVideoCapture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.SnappiVideoCapture.UpdateType} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRequestId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVideoId(value);
      break;
    case 4:
      var value = new proto.vivacity.core.SnappiVideoCaptureRecordingRequested;
      reader.readMessage(value,proto.vivacity.core.SnappiVideoCaptureRecordingRequested.deserializeBinaryFromReader);
      msg.setRecordingRequested(value);
      break;
    case 5:
      var value = new proto.vivacity.core.SnappiVideoCaptureRecordingStarted;
      reader.readMessage(value,proto.vivacity.core.SnappiVideoCaptureRecordingStarted.deserializeBinaryFromReader);
      msg.setRecordingStarted(value);
      break;
    case 6:
      var value = new proto.vivacity.core.SnappiVideoCaptureRecordingUpdate;
      reader.readMessage(value,proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.deserializeBinaryFromReader);
      msg.setRecordingUpdate(value);
      break;
    case 7:
      var value = new proto.vivacity.core.SnappiVideoCaptureUploadCreated;
      reader.readMessage(value,proto.vivacity.core.SnappiVideoCaptureUploadCreated.deserializeBinaryFromReader);
      msg.setUploadCreated(value);
      break;
    case 8:
      var value = new proto.vivacity.core.SnappiVideoCaptureUploadUpdate;
      reader.readMessage(value,proto.vivacity.core.SnappiVideoCaptureUploadUpdate.deserializeBinaryFromReader);
      msg.setUploadUpdate(value);
      break;
    case 9:
      var value = new proto.vivacity.core.SnappiVideoCaptureUploadComplete;
      reader.readMessage(value,proto.vivacity.core.SnappiVideoCaptureUploadComplete.deserializeBinaryFromReader);
      msg.setUploadComplete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiVideoCapture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiVideoCapture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCapture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRequestId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getVideoId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getRecordingRequested();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.core.SnappiVideoCaptureRecordingRequested.serializeBinaryToWriter
    );
  }
  f = message.getRecordingStarted();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vivacity.core.SnappiVideoCaptureRecordingStarted.serializeBinaryToWriter
    );
  }
  f = message.getRecordingUpdate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.serializeBinaryToWriter
    );
  }
  f = message.getUploadCreated();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vivacity.core.SnappiVideoCaptureUploadCreated.serializeBinaryToWriter
    );
  }
  f = message.getUploadUpdate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vivacity.core.SnappiVideoCaptureUploadUpdate.serializeBinaryToWriter
    );
  }
  f = message.getUploadComplete();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vivacity.core.SnappiVideoCaptureUploadComplete.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.SnappiVideoCapture.UpdateType = {
  UNKNOWN: 0,
  RECORDING_REQUESTED: 1,
  RECORDING_STARTED: 2,
  RECORDING_UPDATE: 3,
  UPLOAD_CREATED: 4,
  UPLOAD_UPDATE: 5,
  UPLOAD_COMPLETE: 6
};

/**
 * optional UpdateType update_type = 1;
 * @return {!proto.vivacity.core.SnappiVideoCapture.UpdateType}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getUpdateType = function() {
  return /** @type {!proto.vivacity.core.SnappiVideoCapture.UpdateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.SnappiVideoCapture.UpdateType} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.setUpdateType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 request_id = 2;
 * @return {string}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 video_id = 3;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getVideoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional SnappiVideoCaptureRecordingRequested recording_requested = 4;
 * @return {?proto.vivacity.core.SnappiVideoCaptureRecordingRequested}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getRecordingRequested = function() {
  return /** @type{?proto.vivacity.core.SnappiVideoCaptureRecordingRequested} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.SnappiVideoCaptureRecordingRequested, 4));
};


/**
 * @param {?proto.vivacity.core.SnappiVideoCaptureRecordingRequested|undefined} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
*/
proto.vivacity.core.SnappiVideoCapture.prototype.setRecordingRequested = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.clearRecordingRequested = function() {
  return this.setRecordingRequested(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.hasRecordingRequested = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SnappiVideoCaptureRecordingStarted recording_started = 5;
 * @return {?proto.vivacity.core.SnappiVideoCaptureRecordingStarted}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getRecordingStarted = function() {
  return /** @type{?proto.vivacity.core.SnappiVideoCaptureRecordingStarted} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.SnappiVideoCaptureRecordingStarted, 5));
};


/**
 * @param {?proto.vivacity.core.SnappiVideoCaptureRecordingStarted|undefined} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
*/
proto.vivacity.core.SnappiVideoCapture.prototype.setRecordingStarted = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.clearRecordingStarted = function() {
  return this.setRecordingStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.hasRecordingStarted = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SnappiVideoCaptureRecordingUpdate recording_update = 6;
 * @return {?proto.vivacity.core.SnappiVideoCaptureRecordingUpdate}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getRecordingUpdate = function() {
  return /** @type{?proto.vivacity.core.SnappiVideoCaptureRecordingUpdate} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.SnappiVideoCaptureRecordingUpdate, 6));
};


/**
 * @param {?proto.vivacity.core.SnappiVideoCaptureRecordingUpdate|undefined} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
*/
proto.vivacity.core.SnappiVideoCapture.prototype.setRecordingUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.clearRecordingUpdate = function() {
  return this.setRecordingUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.hasRecordingUpdate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SnappiVideoCaptureUploadCreated upload_created = 7;
 * @return {?proto.vivacity.core.SnappiVideoCaptureUploadCreated}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getUploadCreated = function() {
  return /** @type{?proto.vivacity.core.SnappiVideoCaptureUploadCreated} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.SnappiVideoCaptureUploadCreated, 7));
};


/**
 * @param {?proto.vivacity.core.SnappiVideoCaptureUploadCreated|undefined} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
*/
proto.vivacity.core.SnappiVideoCapture.prototype.setUploadCreated = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.clearUploadCreated = function() {
  return this.setUploadCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.hasUploadCreated = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SnappiVideoCaptureUploadUpdate upload_update = 8;
 * @return {?proto.vivacity.core.SnappiVideoCaptureUploadUpdate}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getUploadUpdate = function() {
  return /** @type{?proto.vivacity.core.SnappiVideoCaptureUploadUpdate} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.SnappiVideoCaptureUploadUpdate, 8));
};


/**
 * @param {?proto.vivacity.core.SnappiVideoCaptureUploadUpdate|undefined} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
*/
proto.vivacity.core.SnappiVideoCapture.prototype.setUploadUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.clearUploadUpdate = function() {
  return this.setUploadUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.hasUploadUpdate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SnappiVideoCaptureUploadComplete upload_complete = 9;
 * @return {?proto.vivacity.core.SnappiVideoCaptureUploadComplete}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.getUploadComplete = function() {
  return /** @type{?proto.vivacity.core.SnappiVideoCaptureUploadComplete} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.SnappiVideoCaptureUploadComplete, 9));
};


/**
 * @param {?proto.vivacity.core.SnappiVideoCaptureUploadComplete|undefined} value
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
*/
proto.vivacity.core.SnappiVideoCapture.prototype.setUploadComplete = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.SnappiVideoCapture} returns this
 */
proto.vivacity.core.SnappiVideoCapture.prototype.clearUploadComplete = function() {
  return this.setUploadComplete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.SnappiVideoCapture.prototype.hasUploadComplete = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiVideoCaptureRecordingRequested.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bitrate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    buffers: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frameRateNumerator: jspb.Message.getFieldWithDefault(msg, 4, 0),
    frameRateDenominator: jspb.Message.getFieldWithDefault(msg, 5, 0),
    height: jspb.Message.getFieldWithDefault(msg, 6, 0),
    width: jspb.Message.getFieldWithDefault(msg, 7, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 8, 0),
    videoType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    meta: jspb.Message.getFieldWithDefault(msg, 10, ""),
    requestedAtMicroseconds: jspb.Message.getFieldWithDefault(msg, 11, 0),
    shouldRetain: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    deprecatedRequestedBy: jspb.Message.getFieldWithDefault(msg, 1000, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiVideoCaptureRecordingRequested;
  return proto.vivacity.core.SnappiVideoCaptureRecordingRequested.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBitrate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBuffers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrameRateNumerator(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrameRateDenominator(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {!proto.vivacity.core.SceneCaptureTriggerReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 9:
      var value = /** @type {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested.VideoType} */ (reader.readEnum());
      msg.setVideoType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeta(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestedAtMicroseconds(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldRetain(value);
      break;
    case 1000:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedRequestedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiVideoCaptureRecordingRequested.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBitrate();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBuffers();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFrameRateNumerator();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getFrameRateDenominator();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getVideoType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMeta();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRequestedAtMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getShouldRetain();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDeprecatedRequestedBy();
  if (f.length > 0) {
    writer.writeString(
      1000,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.VideoType = {
  UNKNOWN: 0,
  RAW: 1,
  RAW_MJPEG: 2,
  TRACKING: 3
};

/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 bitrate = 2;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 buffers = 3;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getBuffers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setBuffers = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 frame_rate_numerator = 4;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getFrameRateNumerator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setFrameRateNumerator = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 frame_rate_denominator = 5;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getFrameRateDenominator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setFrameRateDenominator = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 height = 6;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 width = 7;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional SceneCaptureTriggerReason reason = 8;
 * @return {!proto.vivacity.core.SceneCaptureTriggerReason}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getReason = function() {
  return /** @type {!proto.vivacity.core.SceneCaptureTriggerReason} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vivacity.core.SceneCaptureTriggerReason} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional VideoType video_type = 9;
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested.VideoType}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getVideoType = function() {
  return /** @type {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested.VideoType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested.VideoType} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setVideoType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string meta = 10;
 * @return {string}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getMeta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setMeta = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint64 requested_at_microseconds = 11;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getRequestedAtMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setRequestedAtMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool should_retain = 12;
 * @return {boolean}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getShouldRetain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setShouldRetain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string deprecated_requested_by = 1000;
 * @return {string}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.getDeprecatedRequestedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1000, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingRequested} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingRequested.prototype.setDeprecatedRequestedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 1000, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiVideoCaptureRecordingStarted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bitrate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    buffers: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frameRateNumerator: jspb.Message.getFieldWithDefault(msg, 4, 0),
    frameRateDenominator: jspb.Message.getFieldWithDefault(msg, 5, 0),
    height: jspb.Message.getFieldWithDefault(msg, 6, 0),
    width: jspb.Message.getFieldWithDefault(msg, 7, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 8, 0),
    videoType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    meta: jspb.Message.getFieldWithDefault(msg, 10, ""),
    requestedAtMicroseconds: jspb.Message.getFieldWithDefault(msg, 11, 0),
    shouldRetain: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    deprecatedRequestedBy: jspb.Message.getFieldWithDefault(msg, 1000, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiVideoCaptureRecordingStarted;
  return proto.vivacity.core.SnappiVideoCaptureRecordingStarted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBitrate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBuffers(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrameRateNumerator(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrameRateDenominator(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {!proto.vivacity.core.SceneCaptureTriggerReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 9:
      var value = /** @type {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted.VideoType} */ (reader.readEnum());
      msg.setVideoType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeta(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestedAtMicroseconds(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldRetain(value);
      break;
    case 1000:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeprecatedRequestedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiVideoCaptureRecordingStarted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBitrate();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBuffers();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFrameRateNumerator();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getFrameRateDenominator();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getVideoType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMeta();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRequestedAtMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getShouldRetain();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDeprecatedRequestedBy();
  if (f.length > 0) {
    writer.writeString(
      1000,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.VideoType = {
  UNKNOWN: 0,
  RAW: 1,
  RAW_MJPEG: 2,
  TRACKING: 3
};

/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 bitrate = 2;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 buffers = 3;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getBuffers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setBuffers = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 frame_rate_numerator = 4;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getFrameRateNumerator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setFrameRateNumerator = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 frame_rate_denominator = 5;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getFrameRateDenominator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setFrameRateDenominator = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 height = 6;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 width = 7;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional SceneCaptureTriggerReason reason = 8;
 * @return {!proto.vivacity.core.SceneCaptureTriggerReason}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getReason = function() {
  return /** @type {!proto.vivacity.core.SceneCaptureTriggerReason} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vivacity.core.SceneCaptureTriggerReason} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional VideoType video_type = 9;
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted.VideoType}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getVideoType = function() {
  return /** @type {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted.VideoType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted.VideoType} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setVideoType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string meta = 10;
 * @return {string}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getMeta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setMeta = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint64 requested_at_microseconds = 11;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getRequestedAtMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setRequestedAtMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool should_retain = 12;
 * @return {boolean}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getShouldRetain = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setShouldRetain = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string deprecated_requested_by = 1000;
 * @return {string}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.getDeprecatedRequestedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1000, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingStarted} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingStarted.prototype.setDeprecatedRequestedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 1000, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    thumbnail: msg.getThumbnail_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingUpdate}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiVideoCaptureRecordingUpdate;
  return proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingUpdate}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgress(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiVideoCaptureRecordingUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getThumbnail_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional float progress = 1;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingUpdate} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional bytes thumbnail = 2;
 * @return {!(string|Uint8Array)}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.prototype.getThumbnail = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes thumbnail = 2;
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {string}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.prototype.getThumbnail_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getThumbnail()));
};


/**
 * optional bytes thumbnail = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getThumbnail()`
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.prototype.getThumbnail_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getThumbnail()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureRecordingUpdate} returns this
 */
proto.vivacity.core.SnappiVideoCaptureRecordingUpdate.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiVideoCaptureUploadCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    bucketPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startFrame: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endFrame: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadCreated}
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiVideoCaptureUploadCreated;
  return proto.vivacity.core.SnappiVideoCaptureUploadCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadCreated}
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucketPath(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartFrame(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndFrame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiVideoCaptureUploadCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBucketPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartFrame();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEndFrame();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string bucket_path = 1;
 * @return {string}
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.prototype.getBucketPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadCreated} returns this
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.prototype.setBucketPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 start_frame = 2;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.prototype.getStartFrame = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadCreated} returns this
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.prototype.setStartFrame = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 end_frame = 3;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.prototype.getEndFrame = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadCreated} returns this
 */
proto.vivacity.core.SnappiVideoCaptureUploadCreated.prototype.setEndFrame = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiVideoCaptureUploadUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totalBytes: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadUpdate}
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiVideoCaptureUploadUpdate;
  return proto.vivacity.core.SnappiVideoCaptureUploadUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadUpdate}
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalBytes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiVideoCaptureUploadUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getTotalBytes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional float progress = 1;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadUpdate} returns this
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional uint32 total_bytes = 2;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.prototype.getTotalBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadUpdate} returns this
 */
proto.vivacity.core.SnappiVideoCaptureUploadUpdate.prototype.setTotalBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiVideoCaptureUploadComplete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadComplete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.toObject = function(includeInstance, msg) {
  var f, obj = {
    startMicroseconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endMicroseconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalBytes: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadComplete}
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiVideoCaptureUploadComplete;
  return proto.vivacity.core.SnappiVideoCaptureUploadComplete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadComplete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadComplete}
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartMicroseconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndMicroseconds(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalBytes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiVideoCaptureUploadComplete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiVideoCaptureUploadComplete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getEndMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTotalBytes();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint64 start_microseconds = 1;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.prototype.getStartMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadComplete} returns this
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.prototype.setStartMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 end_microseconds = 2;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.prototype.getEndMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadComplete} returns this
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.prototype.setEndMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_bytes = 3;
 * @return {number}
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.prototype.getTotalBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiVideoCaptureUploadComplete} returns this
 */
proto.vivacity.core.SnappiVideoCaptureUploadComplete.prototype.setTotalBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiImageCapture.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiImageCapture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiImageCapture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiImageCapture.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requestId: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    imageId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    height: jspb.Message.getFieldWithDefault(msg, 5, 0),
    width: jspb.Message.getFieldWithDefault(msg, 6, 0),
    captureEvents: (f = msg.getCaptureEvents()) && vivacity_core_scene_capture_trigger_reasons_pb.ClassTriggeredSceneCaptureEvents.toObject(includeInstance, f),
    imageType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    meta: jspb.Message.getFieldWithDefault(msg, 9, ""),
    uploadComplete: (f = msg.getUploadComplete()) && proto.vivacity.core.SnappiImageCaptureUploadComplete.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiImageCapture}
 */
proto.vivacity.core.SnappiImageCapture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiImageCapture;
  return proto.vivacity.core.SnappiImageCapture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiImageCapture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiImageCapture}
 */
proto.vivacity.core.SnappiImageCapture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.SnappiImageCapture.UpdateType} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readUint64String());
      msg.setRequestId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setImageId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 7:
      var value = new vivacity_core_scene_capture_trigger_reasons_pb.ClassTriggeredSceneCaptureEvents;
      reader.readMessage(value,vivacity_core_scene_capture_trigger_reasons_pb.ClassTriggeredSceneCaptureEvents.deserializeBinaryFromReader);
      msg.setCaptureEvents(value);
      break;
    case 8:
      var value = /** @type {!proto.vivacity.core.SnappiImageCapture.SnappiImageType} */ (reader.readEnum());
      msg.setImageType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeta(value);
      break;
    case 10:
      var value = new proto.vivacity.core.SnappiImageCaptureUploadComplete;
      reader.readMessage(value,proto.vivacity.core.SnappiImageCaptureUploadComplete.deserializeBinaryFromReader);
      msg.setUploadComplete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiImageCapture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiImageCapture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiImageCapture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiImageCapture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRequestId();
  if (parseInt(f, 10) !== 0) {
    writer.writeUint64String(
      2,
      f
    );
  }
  f = message.getImageId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCaptureEvents();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_core_scene_capture_trigger_reasons_pb.ClassTriggeredSceneCaptureEvents.serializeBinaryToWriter
    );
  }
  f = message.getImageType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMeta();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUploadComplete();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vivacity.core.SnappiImageCaptureUploadComplete.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.SnappiImageCapture.UpdateType = {
  UNKNOWN: 0,
  SNAPSHOT_REQUESTED: 1,
  SNAPSHOT_RECORDED: 2,
  UPLOAD_COMPLETE: 3
};

/**
 * @enum {number}
 */
proto.vivacity.core.SnappiImageCapture.SnappiImageType = {
  UNKNOWN_IMAGE_TYPE: 0,
  PNG: 1,
  JPEG: 2
};

/**
 * optional UpdateType update_type = 1;
 * @return {!proto.vivacity.core.SnappiImageCapture.UpdateType}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getUpdateType = function() {
  return /** @type {!proto.vivacity.core.SnappiImageCapture.UpdateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.SnappiImageCapture.UpdateType} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.setUpdateType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint64 request_id = 2;
 * @return {string}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional uint64 image_id = 3;
 * @return {number}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getImageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.setImageId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 vision_program_id = 4;
 * @return {number}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 height = 5;
 * @return {number}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 width = 6;
 * @return {number}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional ClassTriggeredSceneCaptureEvents capture_events = 7;
 * @return {?proto.vivacity.core.ClassTriggeredSceneCaptureEvents}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getCaptureEvents = function() {
  return /** @type{?proto.vivacity.core.ClassTriggeredSceneCaptureEvents} */ (
    jspb.Message.getWrapperField(this, vivacity_core_scene_capture_trigger_reasons_pb.ClassTriggeredSceneCaptureEvents, 7));
};


/**
 * @param {?proto.vivacity.core.ClassTriggeredSceneCaptureEvents|undefined} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
*/
proto.vivacity.core.SnappiImageCapture.prototype.setCaptureEvents = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.clearCaptureEvents = function() {
  return this.setCaptureEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.SnappiImageCapture.prototype.hasCaptureEvents = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SnappiImageType image_type = 8;
 * @return {!proto.vivacity.core.SnappiImageCapture.SnappiImageType}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getImageType = function() {
  return /** @type {!proto.vivacity.core.SnappiImageCapture.SnappiImageType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vivacity.core.SnappiImageCapture.SnappiImageType} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.setImageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string meta = 9;
 * @return {string}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getMeta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.setMeta = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional SnappiImageCaptureUploadComplete upload_complete = 10;
 * @return {?proto.vivacity.core.SnappiImageCaptureUploadComplete}
 */
proto.vivacity.core.SnappiImageCapture.prototype.getUploadComplete = function() {
  return /** @type{?proto.vivacity.core.SnappiImageCaptureUploadComplete} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.SnappiImageCaptureUploadComplete, 10));
};


/**
 * @param {?proto.vivacity.core.SnappiImageCaptureUploadComplete|undefined} value
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
*/
proto.vivacity.core.SnappiImageCapture.prototype.setUploadComplete = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.SnappiImageCapture} returns this
 */
proto.vivacity.core.SnappiImageCapture.prototype.clearUploadComplete = function() {
  return this.setUploadComplete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.SnappiImageCapture.prototype.hasUploadComplete = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiImageCaptureSnapshotRequested.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiImageCaptureSnapshotRequested} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestedAtMicroseconds: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiImageCaptureSnapshotRequested}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiImageCaptureSnapshotRequested;
  return proto.vivacity.core.SnappiImageCaptureSnapshotRequested.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiImageCaptureSnapshotRequested} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiImageCaptureSnapshotRequested}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRequestedAtMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiImageCaptureSnapshotRequested.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiImageCaptureSnapshotRequested} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestedAtMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 requested_at_microseconds = 1;
 * @return {number}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested.prototype.getRequestedAtMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiImageCaptureSnapshotRequested} returns this
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRequested.prototype.setRequestedAtMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiImageCaptureSnapshotRecorded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordedAtMicroseconds: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiImageCaptureSnapshotRecorded}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiImageCaptureSnapshotRecorded;
  return proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiImageCaptureSnapshotRecorded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiImageCaptureSnapshotRecorded}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRecordedAtMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiImageCaptureSnapshotRecorded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordedAtMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 recorded_at_microseconds = 1;
 * @return {number}
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.prototype.getRecordedAtMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiImageCaptureSnapshotRecorded} returns this
 */
proto.vivacity.core.SnappiImageCaptureSnapshotRecorded.prototype.setRecordedAtMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.SnappiImageCaptureUploadComplete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.SnappiImageCaptureUploadComplete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.toObject = function(includeInstance, msg) {
  var f, obj = {
    bucketPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uploadedAtMicroseconds: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.SnappiImageCaptureUploadComplete}
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.SnappiImageCaptureUploadComplete;
  return proto.vivacity.core.SnappiImageCaptureUploadComplete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.SnappiImageCaptureUploadComplete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.SnappiImageCaptureUploadComplete}
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucketPath(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUploadedAtMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.SnappiImageCaptureUploadComplete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.SnappiImageCaptureUploadComplete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBucketPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUploadedAtMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string bucket_path = 1;
 * @return {string}
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.prototype.getBucketPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.SnappiImageCaptureUploadComplete} returns this
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.prototype.setBucketPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 uploaded_at_microseconds = 2;
 * @return {number}
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.prototype.getUploadedAtMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.SnappiImageCaptureUploadComplete} returns this
 */
proto.vivacity.core.SnappiImageCaptureUploadComplete.prototype.setUploadedAtMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.vivacity.core);
