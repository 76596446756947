/* eslint-disable */
// source: vivacity/core/detector_tracker_frame.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_track_head_pb = require('../../vivacity/core/track_head_pb.js');
goog.object.extend(proto, vivacity_core_track_head_pb);
var vivacity_core_nvosd_params_pb = require('../../vivacity/core/nvosd_params_pb.js');
goog.object.extend(proto, vivacity_core_nvosd_params_pb);
var vivacity_core_tensor_pb = require('../../vivacity/core/tensor_pb.js');
goog.object.extend(proto, vivacity_core_tensor_pb);
var vivacity_core_zonal_features_pb = require('../../vivacity/core/zonal_features_pb.js');
goog.object.extend(proto, vivacity_core_zonal_features_pb);
var vivacity_core_gpu_histogram_pb = require('../../vivacity/core/gpu_histogram_pb.js');
goog.object.extend(proto, vivacity_core_gpu_histogram_pb);
var vivacity_core_glare_analysis_pb = require('../../vivacity/core/glare_analysis_pb.js');
goog.object.extend(proto, vivacity_core_glare_analysis_pb);
var vivacity_core_image_degradation_pb = require('../../vivacity/core/image_degradation_pb.js');
goog.object.extend(proto, vivacity_core_image_degradation_pb);
goog.exportSymbol('proto.vivacity.core.AssembledTrack', null, global);
goog.exportSymbol('proto.vivacity.core.AssembledTrackBatch', null, global);
goog.exportSymbol('proto.vivacity.core.BrokenTrackStitchingMessage', null, global);
goog.exportSymbol('proto.vivacity.core.DetectorTrackerFrame', null, global);
goog.exportSymbol('proto.vivacity.core.DetectorTrackerFrame.VideoID', null, global);
goog.exportSymbol('proto.vivacity.core.FragmentedDetectorTrackerFrame', null, global);
goog.exportSymbol('proto.vivacity.core.FrameMetadata', null, global);
goog.exportSymbol('proto.vivacity.core.TrackSegment', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DetectorTrackerFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.DetectorTrackerFrame.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.DetectorTrackerFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DetectorTrackerFrame.displayName = 'proto.vivacity.core.DetectorTrackerFrame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.FrameMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.FrameMetadata.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.FrameMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.FrameMetadata.displayName = 'proto.vivacity.core.FrameMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.FragmentedDetectorTrackerFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.FragmentedDetectorTrackerFrame.displayName = 'proto.vivacity.core.FragmentedDetectorTrackerFrame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AssembledTrack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.AssembledTrack.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.AssembledTrack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AssembledTrack.displayName = 'proto.vivacity.core.AssembledTrack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AssembledTrackBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.AssembledTrackBatch.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.AssembledTrackBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AssembledTrackBatch.displayName = 'proto.vivacity.core.AssembledTrackBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TrackSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.TrackSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TrackSegment.displayName = 'proto.vivacity.core.TrackSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.BrokenTrackStitchingMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.BrokenTrackStitchingMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.BrokenTrackStitchingMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.BrokenTrackStitchingMessage.displayName = 'proto.vivacity.core.BrokenTrackStitchingMessage';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.DetectorTrackerFrame.repeatedFields_ = [7,9,10,12,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DetectorTrackerFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DetectorTrackerFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectorTrackerFrame.toObject = function(includeInstance, msg) {
  var f, obj = {
    frameNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    frameTimeMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    videoId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    videoPath: jspb.Message.getFieldWithDefault(msg, 5, ""),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trackHeadsList: jspb.Message.toObjectList(msg.getTrackHeadsList(),
    vivacity_core_track_head_pb.TrackHead.toObject, includeInstance),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    zoneOrientedFeaturesList: jspb.Message.toObjectList(msg.getZoneOrientedFeaturesList(),
    vivacity_core_zonal_features_pb.ZonalFeatures.toObject, includeInstance),
    trackSegmentsList: jspb.Message.toObjectList(msg.getTrackSegmentsList(),
    proto.vivacity.core.TrackSegment.toObject, includeInstance),
    trackTailsList: jspb.Message.toObjectList(msg.getTrackTailsList(),
    proto.vivacity.core.AssembledTrack.toObject, includeInstance),
    metadata: (f = msg.getMetadata()) && proto.vivacity.core.FrameMetadata.toObject(includeInstance, f),
    trackTerminationsList: jspb.Message.toObjectList(msg.getTrackTerminationsList(),
    vivacity_core_track_head_pb.TrackHead.toObject, includeInstance),
    nearMissesMap: (f = msg.getNearMissesMap()) ? f.toObject(includeInstance, proto.vivacity.core.NearMissStatusV2.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DetectorTrackerFrame}
 */
proto.vivacity.core.DetectorTrackerFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DetectorTrackerFrame;
  return proto.vivacity.core.DetectorTrackerFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DetectorTrackerFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DetectorTrackerFrame}
 */
proto.vivacity.core.DetectorTrackerFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrameNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameTimeMicroseconds(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.core.DetectorTrackerFrame.VideoID} */ (reader.readEnum());
      msg.setVideoId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideoPath(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 7:
      var value = new vivacity_core_track_head_pb.TrackHead;
      reader.readMessage(value,vivacity_core_track_head_pb.TrackHead.deserializeBinaryFromReader);
      msg.addTrackHeads(value);
      break;
    case 8:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 9:
      var value = new vivacity_core_zonal_features_pb.ZonalFeatures;
      reader.readMessage(value,vivacity_core_zonal_features_pb.ZonalFeatures.deserializeBinaryFromReader);
      msg.addZoneOrientedFeatures(value);
      break;
    case 10:
      var value = new proto.vivacity.core.TrackSegment;
      reader.readMessage(value,proto.vivacity.core.TrackSegment.deserializeBinaryFromReader);
      msg.addTrackSegments(value);
      break;
    case 12:
      var value = new proto.vivacity.core.AssembledTrack;
      reader.readMessage(value,proto.vivacity.core.AssembledTrack.deserializeBinaryFromReader);
      msg.addTrackTails(value);
      break;
    case 13:
      var value = new proto.vivacity.core.FrameMetadata;
      reader.readMessage(value,proto.vivacity.core.FrameMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 15:
      var value = new vivacity_core_track_head_pb.TrackHead;
      reader.readMessage(value,vivacity_core_track_head_pb.TrackHead.deserializeBinaryFromReader);
      msg.addTrackTerminations(value);
      break;
    case 16:
      var value = msg.getNearMissesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint64, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.NearMissStatusV2.deserializeBinaryFromReader, 0, new proto.vivacity.core.NearMissStatusV2());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DetectorTrackerFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DetectorTrackerFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectorTrackerFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFrameTimeMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getVideoId();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVideoPath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTrackHeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      vivacity_core_track_head_pb.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getZoneOrientedFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      vivacity_core_zonal_features_pb.ZonalFeatures.serializeBinaryToWriter
    );
  }
  f = message.getTrackSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.vivacity.core.TrackSegment.serializeBinaryToWriter
    );
  }
  f = message.getTrackTailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.vivacity.core.AssembledTrack.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.vivacity.core.FrameMetadata.serializeBinaryToWriter
    );
  }
  f = message.getTrackTerminationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      vivacity_core_track_head_pb.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getNearMissesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeUint64, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.NearMissStatusV2.serializeBinaryToWriter);
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.DetectorTrackerFrame.VideoID = {
  UNKNOWN_VIDEO_ID: 0,
  CAM0_MJPEG_YUYV: 1,
  CAM1_MJPEG_YUYV: 2,
  FILE: 3
};

/**
 * optional uint32 frame_number = 1;
 * @return {number}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 restart_number = 2;
 * @return {number}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 frame_time_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getFrameTimeMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setFrameTimeMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional VideoID video_id = 4;
 * @return {!proto.vivacity.core.DetectorTrackerFrame.VideoID}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getVideoId = function() {
  return /** @type {!proto.vivacity.core.DetectorTrackerFrame.VideoID} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.core.DetectorTrackerFrame.VideoID} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string video_path = 5;
 * @return {string}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getVideoPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setVideoPath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 vision_program_id = 6;
 * @return {number}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated TrackHead track_heads = 7;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTrackHeadsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_track_head_pb.TrackHead, 7));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTrackHeadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addTrackHeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTrackHeadsList = function() {
  return this.setTrackHeadsList([]);
};


/**
 * optional TracingContext tracing_ctx = 8;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 8));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated ZonalFeatures zone_oriented_features = 9;
 * @return {!Array<!proto.vivacity.core.ZonalFeatures>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getZoneOrientedFeaturesList = function() {
  return /** @type{!Array<!proto.vivacity.core.ZonalFeatures>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_zonal_features_pb.ZonalFeatures, 9));
};


/**
 * @param {!Array<!proto.vivacity.core.ZonalFeatures>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setZoneOrientedFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.vivacity.core.ZonalFeatures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ZonalFeatures}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addZoneOrientedFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.vivacity.core.ZonalFeatures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearZoneOrientedFeaturesList = function() {
  return this.setZoneOrientedFeaturesList([]);
};


/**
 * repeated TrackSegment track_segments = 10;
 * @return {!Array<!proto.vivacity.core.TrackSegment>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTrackSegmentsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TrackSegment, 10));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackSegment>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTrackSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.vivacity.core.TrackSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackSegment}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addTrackSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.vivacity.core.TrackSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTrackSegmentsList = function() {
  return this.setTrackSegmentsList([]);
};


/**
 * repeated AssembledTrack track_tails = 12;
 * @return {!Array<!proto.vivacity.core.AssembledTrack>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTrackTailsList = function() {
  return /** @type{!Array<!proto.vivacity.core.AssembledTrack>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.AssembledTrack, 12));
};


/**
 * @param {!Array<!proto.vivacity.core.AssembledTrack>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTrackTailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.vivacity.core.AssembledTrack=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AssembledTrack}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addTrackTails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.vivacity.core.AssembledTrack, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTrackTailsList = function() {
  return this.setTrackTailsList([]);
};


/**
 * optional FrameMetadata metadata = 13;
 * @return {?proto.vivacity.core.FrameMetadata}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getMetadata = function() {
  return /** @type{?proto.vivacity.core.FrameMetadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.FrameMetadata, 13));
};


/**
 * @param {?proto.vivacity.core.FrameMetadata|undefined} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated TrackHead track_terminations = 15;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTrackTerminationsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_track_head_pb.TrackHead, 15));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTrackTerminationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addTrackTerminations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTrackTerminationsList = function() {
  return this.setTrackTerminationsList([]);
};


/**
 * map<uint64, NearMissStatusV2> near_misses = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.NearMissStatusV2>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getNearMissesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.NearMissStatusV2>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      proto.vivacity.core.NearMissStatusV2));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearNearMissesMap = function() {
  this.getNearMissesMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.FrameMetadata.repeatedFields_ = [1,3,7,9,12,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.FrameMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.FrameMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.FrameMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FrameMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    outputTensorsList: jspb.Message.toObjectList(msg.getOutputTensorsList(),
    vivacity_core_tensor_pb.Tensor.toObject, includeInstance),
    opticalFlow: (f = msg.getOpticalFlow()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    displayMetaList: jspb.Message.toObjectList(msg.getDisplayMetaList(),
    vivacity_core_nvosd_params_pb.NvDsDisplayMeta.toObject, includeInstance),
    height: jspb.Message.getFieldWithDefault(msg, 4, 0),
    width: jspb.Message.getFieldWithDefault(msg, 5, 0),
    predictionsMap: (f = msg.getPredictionsMap()) ? f.toObject(includeInstance, proto.vivacity.core.TrackHead.toObject) : [],
    deletedDuplicateDetectionsList: jspb.Message.toObjectList(msg.getDeletedDuplicateDetectionsList(),
    vivacity_core_track_head_pb.TrackHead.toObject, includeInstance),
    totalIouMatchScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lowConfidenceDetectionsList: jspb.Message.toObjectList(msg.getLowConfidenceDetectionsList(),
    vivacity_core_track_head_pb.TrackHead.toObject, includeInstance),
    trackIdCounter: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalTrackLength: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deletedMaskedTrackHeadsList: jspb.Message.toObjectList(msg.getDeletedMaskedTrackHeadsList(),
    vivacity_core_track_head_pb.TrackHead.toObject, includeInstance),
    gpuHistogramsList: jspb.Message.toObjectList(msg.getGpuHistogramsList(),
    vivacity_core_gpu_histogram_pb.GpuHistogram.toObject, includeInstance),
    glareAnalysisList: jspb.Message.toObjectList(msg.getGlareAnalysisList(),
    vivacity_core_glare_analysis_pb.GlareAnalysis.toObject, includeInstance),
    imageDegradation: (f = msg.getImageDegradation()) && vivacity_core_image_degradation_pb.ImageDegradation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.FrameMetadata}
 */
proto.vivacity.core.FrameMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.FrameMetadata;
  return proto.vivacity.core.FrameMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.FrameMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.FrameMetadata}
 */
proto.vivacity.core.FrameMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.addOutputTensors(value);
      break;
    case 2:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setOpticalFlow(value);
      break;
    case 3:
      var value = new vivacity_core_nvosd_params_pb.NvDsDisplayMeta;
      reader.readMessage(value,vivacity_core_nvosd_params_pb.NvDsDisplayMeta.deserializeBinaryFromReader);
      msg.addDisplayMeta(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 6:
      var value = msg.getPredictionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.TrackHead.deserializeBinaryFromReader, 0, new proto.vivacity.core.TrackHead());
         });
      break;
    case 7:
      var value = new vivacity_core_track_head_pb.TrackHead;
      reader.readMessage(value,vivacity_core_track_head_pb.TrackHead.deserializeBinaryFromReader);
      msg.addDeletedDuplicateDetections(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalIouMatchScore(value);
      break;
    case 9:
      var value = new vivacity_core_track_head_pb.TrackHead;
      reader.readMessage(value,vivacity_core_track_head_pb.TrackHead.deserializeBinaryFromReader);
      msg.addLowConfidenceDetections(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrackIdCounter(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalTrackLength(value);
      break;
    case 12:
      var value = new vivacity_core_track_head_pb.TrackHead;
      reader.readMessage(value,vivacity_core_track_head_pb.TrackHead.deserializeBinaryFromReader);
      msg.addDeletedMaskedTrackHeads(value);
      break;
    case 13:
      var value = new vivacity_core_gpu_histogram_pb.GpuHistogram;
      reader.readMessage(value,vivacity_core_gpu_histogram_pb.GpuHistogram.deserializeBinaryFromReader);
      msg.addGpuHistograms(value);
      break;
    case 14:
      var value = new vivacity_core_glare_analysis_pb.GlareAnalysis;
      reader.readMessage(value,vivacity_core_glare_analysis_pb.GlareAnalysis.deserializeBinaryFromReader);
      msg.addGlareAnalysis(value);
      break;
    case 15:
      var value = new vivacity_core_image_degradation_pb.ImageDegradation;
      reader.readMessage(value,vivacity_core_image_degradation_pb.ImageDegradation.deserializeBinaryFromReader);
      msg.setImageDegradation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.FrameMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.FrameMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.FrameMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FrameMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutputTensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getOpticalFlow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getDisplayMetaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vivacity_core_nvosd_params_pb.NvDsDisplayMeta.serializeBinaryToWriter
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPredictionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.TrackHead.serializeBinaryToWriter);
  }
  f = message.getDeletedDuplicateDetectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      vivacity_core_track_head_pb.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTotalIouMatchScore();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLowConfidenceDetectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      vivacity_core_track_head_pb.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTrackIdCounter();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTotalTrackLength();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getDeletedMaskedTrackHeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      vivacity_core_track_head_pb.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getGpuHistogramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      vivacity_core_gpu_histogram_pb.GpuHistogram.serializeBinaryToWriter
    );
  }
  f = message.getGlareAnalysisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      vivacity_core_glare_analysis_pb.GlareAnalysis.serializeBinaryToWriter
    );
  }
  f = message.getImageDegradation();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_core_image_degradation_pb.ImageDegradation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tensor output_tensors = 1;
 * @return {!Array<!proto.vivacity.core.Tensor>}
 */
proto.vivacity.core.FrameMetadata.prototype.getOutputTensorsList = function() {
  return /** @type{!Array<!proto.vivacity.core.Tensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_tensor_pb.Tensor, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.Tensor>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setOutputTensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.Tensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor}
 */
proto.vivacity.core.FrameMetadata.prototype.addOutputTensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.Tensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearOutputTensorsList = function() {
  return this.setOutputTensorsList([]);
};


/**
 * optional Tensor optical_flow = 2;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.FrameMetadata.prototype.getOpticalFlow = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 2));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setOpticalFlow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearOpticalFlow = function() {
  return this.setOpticalFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FrameMetadata.prototype.hasOpticalFlow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated NvDsDisplayMeta display_meta = 3;
 * @return {!Array<!proto.vivacity.core.NvDsDisplayMeta>}
 */
proto.vivacity.core.FrameMetadata.prototype.getDisplayMetaList = function() {
  return /** @type{!Array<!proto.vivacity.core.NvDsDisplayMeta>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_nvosd_params_pb.NvDsDisplayMeta, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.NvDsDisplayMeta>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setDisplayMetaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.NvDsDisplayMeta=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NvDsDisplayMeta}
 */
proto.vivacity.core.FrameMetadata.prototype.addDisplayMeta = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.NvDsDisplayMeta, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearDisplayMetaList = function() {
  return this.setDisplayMetaList([]);
};


/**
 * optional int32 height = 4;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 width = 5;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * map<uint32, TrackHead> predictions = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.FrameMetadata.prototype.getPredictionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.TrackHead>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.vivacity.core.TrackHead));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearPredictionsMap = function() {
  this.getPredictionsMap().clear();
  return this;};


/**
 * repeated TrackHead deleted_duplicate_detections = 7;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.FrameMetadata.prototype.getDeletedDuplicateDetectionsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_track_head_pb.TrackHead, 7));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setDeletedDuplicateDetectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.FrameMetadata.prototype.addDeletedDuplicateDetections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearDeletedDuplicateDetectionsList = function() {
  return this.setDeletedDuplicateDetectionsList([]);
};


/**
 * optional float total_iou_match_score = 8;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getTotalIouMatchScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setTotalIouMatchScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * repeated TrackHead low_confidence_detections = 9;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.FrameMetadata.prototype.getLowConfidenceDetectionsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_track_head_pb.TrackHead, 9));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setLowConfidenceDetectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.FrameMetadata.prototype.addLowConfidenceDetections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearLowConfidenceDetectionsList = function() {
  return this.setLowConfidenceDetectionsList([]);
};


/**
 * optional int32 track_id_counter = 10;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getTrackIdCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setTrackIdCounter = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 total_track_length = 11;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getTotalTrackLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setTotalTrackLength = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated TrackHead deleted_masked_track_heads = 12;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.FrameMetadata.prototype.getDeletedMaskedTrackHeadsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_track_head_pb.TrackHead, 12));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setDeletedMaskedTrackHeadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.FrameMetadata.prototype.addDeletedMaskedTrackHeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearDeletedMaskedTrackHeadsList = function() {
  return this.setDeletedMaskedTrackHeadsList([]);
};


/**
 * repeated GpuHistogram gpu_histograms = 13;
 * @return {!Array<!proto.vivacity.core.GpuHistogram>}
 */
proto.vivacity.core.FrameMetadata.prototype.getGpuHistogramsList = function() {
  return /** @type{!Array<!proto.vivacity.core.GpuHistogram>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_gpu_histogram_pb.GpuHistogram, 13));
};


/**
 * @param {!Array<!proto.vivacity.core.GpuHistogram>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setGpuHistogramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vivacity.core.GpuHistogram=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.GpuHistogram}
 */
proto.vivacity.core.FrameMetadata.prototype.addGpuHistograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vivacity.core.GpuHistogram, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearGpuHistogramsList = function() {
  return this.setGpuHistogramsList([]);
};


/**
 * repeated GlareAnalysis glare_analysis = 14;
 * @return {!Array<!proto.vivacity.core.GlareAnalysis>}
 */
proto.vivacity.core.FrameMetadata.prototype.getGlareAnalysisList = function() {
  return /** @type{!Array<!proto.vivacity.core.GlareAnalysis>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_glare_analysis_pb.GlareAnalysis, 14));
};


/**
 * @param {!Array<!proto.vivacity.core.GlareAnalysis>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setGlareAnalysisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.vivacity.core.GlareAnalysis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.GlareAnalysis}
 */
proto.vivacity.core.FrameMetadata.prototype.addGlareAnalysis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.vivacity.core.GlareAnalysis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearGlareAnalysisList = function() {
  return this.setGlareAnalysisList([]);
};


/**
 * optional ImageDegradation image_degradation = 15;
 * @return {?proto.vivacity.core.ImageDegradation}
 */
proto.vivacity.core.FrameMetadata.prototype.getImageDegradation = function() {
  return /** @type{?proto.vivacity.core.ImageDegradation} */ (
    jspb.Message.getWrapperField(this, vivacity_core_image_degradation_pb.ImageDegradation, 15));
};


/**
 * @param {?proto.vivacity.core.ImageDegradation|undefined} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setImageDegradation = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearImageDegradation = function() {
  return this.setImageDegradation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FrameMetadata.prototype.hasImageDegradation = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.FragmentedDetectorTrackerFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.FragmentedDetectorTrackerFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.toObject = function(includeInstance, msg) {
  var f, obj = {
    fragmentNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fragmentTotal: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalTrackHeads: jspb.Message.getFieldWithDefault(msg, 3, 0),
    detectorTrackerFrameFragment: (f = msg.getDetectorTrackerFrameFragment()) && proto.vivacity.core.DetectorTrackerFrame.toObject(includeInstance, f),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.FragmentedDetectorTrackerFrame;
  return proto.vivacity.core.FragmentedDetectorTrackerFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.FragmentedDetectorTrackerFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFragmentNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFragmentTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalTrackHeads(value);
      break;
    case 4:
      var value = new proto.vivacity.core.DetectorTrackerFrame;
      reader.readMessage(value,proto.vivacity.core.DetectorTrackerFrame.deserializeBinaryFromReader);
      msg.setDetectorTrackerFrameFragment(value);
      break;
    case 5:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.FragmentedDetectorTrackerFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.FragmentedDetectorTrackerFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFragmentNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFragmentTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotalTrackHeads();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDetectorTrackerFrameFragment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.core.DetectorTrackerFrame.serializeBinaryToWriter
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 fragment_number = 1;
 * @return {number}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getFragmentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setFragmentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 fragment_total = 2;
 * @return {number}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getFragmentTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setFragmentTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_track_heads = 3;
 * @return {number}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getTotalTrackHeads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setTotalTrackHeads = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional DetectorTrackerFrame detector_tracker_frame_fragment = 4;
 * @return {?proto.vivacity.core.DetectorTrackerFrame}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getDetectorTrackerFrameFragment = function() {
  return /** @type{?proto.vivacity.core.DetectorTrackerFrame} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DetectorTrackerFrame, 4));
};


/**
 * @param {?proto.vivacity.core.DetectorTrackerFrame|undefined} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
*/
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setDetectorTrackerFrameFragment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.clearDetectorTrackerFrameFragment = function() {
  return this.setDetectorTrackerFrameFragment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.hasDetectorTrackerFrameFragment = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TracingContext tracing_ctx = 5;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 5));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
*/
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.AssembledTrack.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AssembledTrack.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AssembledTrack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AssembledTrack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AssembledTrack.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackHeadsList: jspb.Message.toObjectList(msg.getTrackHeadsList(),
    vivacity_core_track_head_pb.TrackHead.toObject, includeInstance),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    turningMovementZonesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AssembledTrack}
 */
proto.vivacity.core.AssembledTrack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AssembledTrack;
  return proto.vivacity.core.AssembledTrack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AssembledTrack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AssembledTrack}
 */
proto.vivacity.core.AssembledTrack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_track_head_pb.TrackHead;
      reader.readMessage(value,vivacity_core_track_head_pb.TrackHead.deserializeBinaryFromReader);
      msg.addTrackHeads(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 3:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTurningMovementZones(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AssembledTrack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AssembledTrack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AssembledTrack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AssembledTrack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackHeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      vivacity_core_track_head_pb.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTurningMovementZonesList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
};


/**
 * repeated TrackHead track_heads = 1;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.AssembledTrack.prototype.getTrackHeadsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_track_head_pb.TrackHead, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
*/
proto.vivacity.core.AssembledTrack.prototype.setTrackHeadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.AssembledTrack.prototype.addTrackHeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.clearTrackHeadsList = function() {
  return this.setTrackHeadsList([]);
};


/**
 * optional uint32 vision_program_id = 2;
 * @return {number}
 */
proto.vivacity.core.AssembledTrack.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TracingContext tracing_ctx = 3;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.AssembledTrack.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 3));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
*/
proto.vivacity.core.AssembledTrack.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AssembledTrack.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 restart_number = 4;
 * @return {number}
 */
proto.vivacity.core.AssembledTrack.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated uint32 turning_movement_zones = 5;
 * @return {!Array<number>}
 */
proto.vivacity.core.AssembledTrack.prototype.getTurningMovementZonesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.setTurningMovementZonesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.addTurningMovementZones = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.clearTurningMovementZonesList = function() {
  return this.setTurningMovementZonesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.AssembledTrackBatch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AssembledTrackBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AssembledTrackBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AssembledTrackBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AssembledTrackBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    assembledTracksList: jspb.Message.toObjectList(msg.getAssembledTracksList(),
    proto.vivacity.core.AssembledTrack.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AssembledTrackBatch}
 */
proto.vivacity.core.AssembledTrackBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AssembledTrackBatch;
  return proto.vivacity.core.AssembledTrackBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AssembledTrackBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AssembledTrackBatch}
 */
proto.vivacity.core.AssembledTrackBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.AssembledTrack;
      reader.readMessage(value,proto.vivacity.core.AssembledTrack.deserializeBinaryFromReader);
      msg.addAssembledTracks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AssembledTrackBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AssembledTrackBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AssembledTrackBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AssembledTrackBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssembledTracksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.core.AssembledTrack.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssembledTrack assembled_tracks = 1;
 * @return {!Array<!proto.vivacity.core.AssembledTrack>}
 */
proto.vivacity.core.AssembledTrackBatch.prototype.getAssembledTracksList = function() {
  return /** @type{!Array<!proto.vivacity.core.AssembledTrack>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.AssembledTrack, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.AssembledTrack>} value
 * @return {!proto.vivacity.core.AssembledTrackBatch} returns this
*/
proto.vivacity.core.AssembledTrackBatch.prototype.setAssembledTracksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.AssembledTrack=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AssembledTrack}
 */
proto.vivacity.core.AssembledTrackBatch.prototype.addAssembledTracks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.AssembledTrack, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AssembledTrackBatch} returns this
 */
proto.vivacity.core.AssembledTrackBatch.prototype.clearAssembledTracksList = function() {
  return this.setAssembledTracksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TrackSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TrackSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TrackSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    trackNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    trackHeadStart: (f = msg.getTrackHeadStart()) && vivacity_core_track_head_pb.TrackHead.toObject(includeInstance, f),
    trackHeadEnd: (f = msg.getTrackHeadEnd()) && vivacity_core_track_head_pb.TrackHead.toObject(includeInstance, f),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TrackSegment}
 */
proto.vivacity.core.TrackSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TrackSegment;
  return proto.vivacity.core.TrackSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TrackSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TrackSegment}
 */
proto.vivacity.core.TrackSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber(value);
      break;
    case 2:
      var value = new vivacity_core_track_head_pb.TrackHead;
      reader.readMessage(value,vivacity_core_track_head_pb.TrackHead.deserializeBinaryFromReader);
      msg.setTrackHeadStart(value);
      break;
    case 3:
      var value = new vivacity_core_track_head_pb.TrackHead;
      reader.readMessage(value,vivacity_core_track_head_pb.TrackHead.deserializeBinaryFromReader);
      msg.setTrackHeadEnd(value);
      break;
    case 4:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TrackSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TrackSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TrackSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTrackHeadStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_core_track_head_pb.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTrackHeadEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_track_head_pb.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.core.TrackSegment.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 track_number = 9;
 * @return {number}
 */
proto.vivacity.core.TrackSegment.prototype.getTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.setTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional TrackHead track_head_start = 2;
 * @return {?proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.TrackSegment.prototype.getTrackHeadStart = function() {
  return /** @type{?proto.vivacity.core.TrackHead} */ (
    jspb.Message.getWrapperField(this, vivacity_core_track_head_pb.TrackHead, 2));
};


/**
 * @param {?proto.vivacity.core.TrackHead|undefined} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
*/
proto.vivacity.core.TrackSegment.prototype.setTrackHeadStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.clearTrackHeadStart = function() {
  return this.setTrackHeadStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackSegment.prototype.hasTrackHeadStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TrackHead track_head_end = 3;
 * @return {?proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.TrackSegment.prototype.getTrackHeadEnd = function() {
  return /** @type{?proto.vivacity.core.TrackHead} */ (
    jspb.Message.getWrapperField(this, vivacity_core_track_head_pb.TrackHead, 3));
};


/**
 * @param {?proto.vivacity.core.TrackHead|undefined} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
*/
proto.vivacity.core.TrackSegment.prototype.setTrackHeadEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.clearTrackHeadEnd = function() {
  return this.setTrackHeadEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackSegment.prototype.hasTrackHeadEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TracingContext tracing_ctx = 4;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.TrackSegment.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 4));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
*/
proto.vivacity.core.TrackSegment.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackSegment.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 restart_number = 5;
 * @return {number}
 */
proto.vivacity.core.TrackSegment.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.BrokenTrackStitchingMessage.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.BrokenTrackStitchingMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.BrokenTrackStitchingMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.BrokenTrackStitchingMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    restartNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    linkedTrackNumbersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.BrokenTrackStitchingMessage;
  return proto.vivacity.core.BrokenTrackStitchingMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.BrokenTrackStitchingMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLinkedTrackNumbers(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.BrokenTrackStitchingMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.BrokenTrackStitchingMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.BrokenTrackStitchingMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLinkedTrackNumbersList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 restart_number = 1;
 * @return {number}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated uint32 linked_track_numbers = 2;
 * @return {!Array<number>}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.getLinkedTrackNumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.setLinkedTrackNumbersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.addLinkedTrackNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.clearLinkedTrackNumbersList = function() {
  return this.setLinkedTrackNumbersList([]);
};


/**
 * optional uint32 vision_program_id = 3;
 * @return {number}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.vivacity.core);
