/* eslint-disable */
// source: vivacity/config/zone_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
goog.exportSymbol('proto.vivacity.config.VisionProgramZonesConfig', null, global);
goog.exportSymbol('proto.vivacity.config.ZonalWindowedClassConfig', null, global);
goog.exportSymbol('proto.vivacity.config.ZoneConfig', null, global);
goog.exportSymbol('proto.vivacity.config.ZoneRealWorldSpace', null, global);
goog.exportSymbol('proto.vivacity.config.ZonesConfigChange', null, global);
goog.exportSymbol('proto.vivacity.config.ZonesConfigChange.OperationType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ZonesConfigChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.ZonesConfigChange.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.ZonesConfigChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ZonesConfigChange.displayName = 'proto.vivacity.config.ZonesConfigChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramZonesConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.VisionProgramZonesConfig.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.VisionProgramZonesConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramZonesConfig.displayName = 'proto.vivacity.config.VisionProgramZonesConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ZoneConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.ZoneConfig.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.ZoneConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ZoneConfig.displayName = 'proto.vivacity.config.ZoneConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ZonalWindowedClassConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ZonalWindowedClassConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ZonalWindowedClassConfig.displayName = 'proto.vivacity.config.ZonalWindowedClassConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ZoneRealWorldSpace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.ZoneRealWorldSpace.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.ZoneRealWorldSpace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ZoneRealWorldSpace.displayName = 'proto.vivacity.config.ZoneRealWorldSpace';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.ZonesConfigChange.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ZonesConfigChange.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ZonesConfigChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ZonesConfigChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZonesConfigChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zonesList: jspb.Message.toObjectList(msg.getZonesList(),
    proto.vivacity.config.VisionProgramZonesConfig.toObject, includeInstance),
    defaultZoneCheckPointProportion: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    defaultThresholdAngleDegrees: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    calculateOccupancyOnTrackSegments: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    movementDetectionBoxThresholdProportion: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ZonesConfigChange}
 */
proto.vivacity.config.ZonesConfigChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ZonesConfigChange;
  return proto.vivacity.config.ZonesConfigChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ZonesConfigChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ZonesConfigChange}
 */
proto.vivacity.config.ZonesConfigChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.ZonesConfigChange.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = new proto.vivacity.config.VisionProgramZonesConfig;
      reader.readMessage(value,proto.vivacity.config.VisionProgramZonesConfig.deserializeBinaryFromReader);
      msg.addZones(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefaultZoneCheckPointProportion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefaultThresholdAngleDegrees(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCalculateOccupancyOnTrackSegments(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMovementDetectionBoxThresholdProportion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ZonesConfigChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ZonesConfigChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ZonesConfigChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZonesConfigChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.config.VisionProgramZonesConfig.serializeBinaryToWriter
    );
  }
  f = message.getDefaultZoneCheckPointProportion();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDefaultThresholdAngleDegrees();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getCalculateOccupancyOnTrackSegments();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMovementDetectionBoxThresholdProportion();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ZonesConfigChange.OperationType = {
  UNKNOWN: 0,
  UPDATE: 1,
  DELETE: 2,
  SET: 3,
  CREATE: 4
};

/**
 * optional OperationType operation_type = 1;
 * @return {!proto.vivacity.config.ZonesConfigChange.OperationType}
 */
proto.vivacity.config.ZonesConfigChange.prototype.getOperationType = function() {
  return /** @type {!proto.vivacity.config.ZonesConfigChange.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.ZonesConfigChange.OperationType} value
 * @return {!proto.vivacity.config.ZonesConfigChange} returns this
 */
proto.vivacity.config.ZonesConfigChange.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated VisionProgramZonesConfig zones = 2;
 * @return {!Array<!proto.vivacity.config.VisionProgramZonesConfig>}
 */
proto.vivacity.config.ZonesConfigChange.prototype.getZonesList = function() {
  return /** @type{!Array<!proto.vivacity.config.VisionProgramZonesConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.VisionProgramZonesConfig, 2));
};


/**
 * @param {!Array<!proto.vivacity.config.VisionProgramZonesConfig>} value
 * @return {!proto.vivacity.config.ZonesConfigChange} returns this
*/
proto.vivacity.config.ZonesConfigChange.prototype.setZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.config.VisionProgramZonesConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramZonesConfig}
 */
proto.vivacity.config.ZonesConfigChange.prototype.addZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.config.VisionProgramZonesConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ZonesConfigChange} returns this
 */
proto.vivacity.config.ZonesConfigChange.prototype.clearZonesList = function() {
  return this.setZonesList([]);
};


/**
 * optional float default_zone_check_point_proportion = 3;
 * @return {number}
 */
proto.vivacity.config.ZonesConfigChange.prototype.getDefaultZoneCheckPointProportion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonesConfigChange} returns this
 */
proto.vivacity.config.ZonesConfigChange.prototype.setDefaultZoneCheckPointProportion = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float default_threshold_angle_degrees = 4;
 * @return {number}
 */
proto.vivacity.config.ZonesConfigChange.prototype.getDefaultThresholdAngleDegrees = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonesConfigChange} returns this
 */
proto.vivacity.config.ZonesConfigChange.prototype.setDefaultThresholdAngleDegrees = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool calculate_occupancy_on_track_segments = 5;
 * @return {boolean}
 */
proto.vivacity.config.ZonesConfigChange.prototype.getCalculateOccupancyOnTrackSegments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZonesConfigChange} returns this
 */
proto.vivacity.config.ZonesConfigChange.prototype.setCalculateOccupancyOnTrackSegments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional float movement_detection_box_threshold_proportion = 6;
 * @return {number}
 */
proto.vivacity.config.ZonesConfigChange.prototype.getMovementDetectionBoxThresholdProportion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonesConfigChange} returns this
 */
proto.vivacity.config.ZonesConfigChange.prototype.setMovementDetectionBoxThresholdProportion = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.VisionProgramZonesConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramZonesConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramZonesConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramZonesConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramZonesConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zonesList: jspb.Message.toObjectList(msg.getZonesList(),
    proto.vivacity.config.ZoneConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramZonesConfig}
 */
proto.vivacity.config.VisionProgramZonesConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramZonesConfig;
  return proto.vivacity.config.VisionProgramZonesConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramZonesConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramZonesConfig}
 */
proto.vivacity.config.VisionProgramZonesConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = new proto.vivacity.config.ZoneConfig;
      reader.readMessage(value,proto.vivacity.config.ZoneConfig.deserializeBinaryFromReader);
      msg.addZones(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramZonesConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramZonesConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramZonesConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramZonesConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.config.ZoneConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.config.VisionProgramZonesConfig.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramZonesConfig} returns this
 */
proto.vivacity.config.VisionProgramZonesConfig.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ZoneConfig zones = 2;
 * @return {!Array<!proto.vivacity.config.ZoneConfig>}
 */
proto.vivacity.config.VisionProgramZonesConfig.prototype.getZonesList = function() {
  return /** @type{!Array<!proto.vivacity.config.ZoneConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.ZoneConfig, 2));
};


/**
 * @param {!Array<!proto.vivacity.config.ZoneConfig>} value
 * @return {!proto.vivacity.config.VisionProgramZonesConfig} returns this
*/
proto.vivacity.config.VisionProgramZonesConfig.prototype.setZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.config.ZoneConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.ZoneConfig}
 */
proto.vivacity.config.VisionProgramZonesConfig.prototype.addZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.config.ZoneConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramZonesConfig} returns this
 */
proto.vivacity.config.VisionProgramZonesConfig.prototype.clearZonesList = function() {
  return this.setZonesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.ZoneConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ZoneConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ZoneConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ZoneConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZoneConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    geometryRingList: jspb.Message.toObjectList(msg.getGeometryRingList(),
    vivacity_core_point_pb.Point.toObject, includeInstance),
    zoneCheckPointProportion: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    deleteInsideZone: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    thresholdAngleDegrees: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    useGroundCenterPointIfAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    isMask: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isLethalMask: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isOccupancy: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isTurning: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isTrackTerminatedCumulativeDwellTime: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isLoadedTrackTerminatedCumulativeDwellTimeForTrigger: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    isTrackTerminatedCumulativeAnprConfidence: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    isSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isTrackTerminatedAverageSpeed: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    isNearMiss: jspb.Message.getBooleanFieldWithDefault(msg, 19, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ZoneConfig}
 */
proto.vivacity.config.ZoneConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ZoneConfig;
  return proto.vivacity.config.ZoneConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ZoneConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ZoneConfig}
 */
proto.vivacity.config.ZoneConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.addGeometryRing(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZoneCheckPointProportion(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteInsideZone(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThresholdAngleDegrees(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseGroundCenterPointIfAvailable(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMask(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLethalMask(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOccupancy(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTurning(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTrackTerminatedCumulativeDwellTime(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLoadedTrackTerminatedCumulativeDwellTimeForTrigger(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTrackTerminatedCumulativeAnprConfidence(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSpeed(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTrackTerminatedAverageSpeed(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNearMiss(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ZoneConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ZoneConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ZoneConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZoneConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGeometryRingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getZoneCheckPointProportion();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDeleteInsideZone();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getThresholdAngleDegrees();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getUseGroundCenterPointIfAvailable();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getIsMask();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsLethalMask();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsOccupancy();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsTurning();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsTrackTerminatedCumulativeDwellTime();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsLoadedTrackTerminatedCumulativeDwellTimeForTrigger();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getIsTrackTerminatedCumulativeAnprConfidence();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getIsSpeed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsTrackTerminatedAverageSpeed();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getIsNearMiss();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
};


/**
 * optional uint32 zone_id = 1;
 * @return {number}
 */
proto.vivacity.config.ZoneConfig.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.vivacity.config.ZoneConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.vivacity.config.ZoneConfig.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated vivacity.core.Point geometry_ring = 2;
 * @return {!Array<!proto.vivacity.core.Point>}
 */
proto.vivacity.config.ZoneConfig.prototype.getGeometryRingList = function() {
  return /** @type{!Array<!proto.vivacity.core.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_point_pb.Point, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.Point>} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
*/
proto.vivacity.config.ZoneConfig.prototype.setGeometryRingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Point}
 */
proto.vivacity.config.ZoneConfig.prototype.addGeometryRing = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.clearGeometryRingList = function() {
  return this.setGeometryRingList([]);
};


/**
 * optional float zone_check_point_proportion = 3;
 * @return {number}
 */
proto.vivacity.config.ZoneConfig.prototype.getZoneCheckPointProportion = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setZoneCheckPointProportion = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool delete_inside_zone = 10;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getDeleteInsideZone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setDeleteInsideZone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional float threshold_angle_degrees = 11;
 * @return {number}
 */
proto.vivacity.config.ZoneConfig.prototype.getThresholdAngleDegrees = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setThresholdAngleDegrees = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool use_ground_center_point_if_available = 16;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getUseGroundCenterPointIfAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setUseGroundCenterPointIfAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool is_mask = 9;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsMask = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsMask = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_lethal_mask = 12;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsLethalMask = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsLethalMask = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_occupancy = 4;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsOccupancy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsOccupancy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_turning = 6;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsTurning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsTurning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_track_terminated_cumulative_dwell_time = 13;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsTrackTerminatedCumulativeDwellTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsTrackTerminatedCumulativeDwellTime = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_trigger_for_loaded_track_terminated_cumulative_dwell_time_zones = 14;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsTriggerForLoadedTrackTerminatedCumulativeDwellTimeZones = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_loaded_track_terminated_cumulative_dwell_time_for_trigger = 15;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsLoadedTrackTerminatedCumulativeDwellTimeForTrigger = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsLoadedTrackTerminatedCumulativeDwellTimeForTrigger = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool is_track_terminated_cumulative_anpr_confidence = 17;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsTrackTerminatedCumulativeAnprConfidence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsTrackTerminatedCumulativeAnprConfidence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool is_speed = 5;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_track_terminated_average_speed = 18;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsTrackTerminatedAverageSpeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsTrackTerminatedAverageSpeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool is_near_miss = 19;
 * @return {boolean}
 */
proto.vivacity.config.ZoneConfig.prototype.getIsNearMiss = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ZoneConfig} returns this
 */
proto.vivacity.config.ZoneConfig.prototype.setIsNearMiss = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ZonalWindowedClassConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ZonalWindowedClassConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ZonalWindowedClassConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZonalWindowedClassConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageSpaceZoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pb_class: jspb.Message.getFieldWithDefault(msg, 2, 0),
    classScalingFactor: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ZonalWindowedClassConfig}
 */
proto.vivacity.config.ZonalWindowedClassConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ZonalWindowedClassConfig;
  return proto.vivacity.config.ZonalWindowedClassConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ZonalWindowedClassConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ZonalWindowedClassConfig}
 */
proto.vivacity.config.ZonalWindowedClassConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setImageSpaceZoneId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClass(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClassScalingFactor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ZonalWindowedClassConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ZonalWindowedClassConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ZonalWindowedClassConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZonalWindowedClassConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageSpaceZoneId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getClass();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getClassScalingFactor();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 image_space_zone_id = 1;
 * @return {number}
 */
proto.vivacity.config.ZonalWindowedClassConfig.prototype.getImageSpaceZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonalWindowedClassConfig} returns this
 */
proto.vivacity.config.ZonalWindowedClassConfig.prototype.setImageSpaceZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional vivacity.core.ClassifyingDetectorClassTypes class = 2;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.config.ZonalWindowedClassConfig.prototype.getClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.config.ZonalWindowedClassConfig} returns this
 */
proto.vivacity.config.ZonalWindowedClassConfig.prototype.setClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 class_scaling_factor = 3;
 * @return {number}
 */
proto.vivacity.config.ZonalWindowedClassConfig.prototype.getClassScalingFactor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonalWindowedClassConfig} returns this
 */
proto.vivacity.config.ZonalWindowedClassConfig.prototype.setClassScalingFactor = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.ZoneRealWorldSpace.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ZoneRealWorldSpace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ZoneRealWorldSpace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZoneRealWorldSpace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneImageSpaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gpsCoordinatesList: jspb.Message.toObjectList(msg.getGpsCoordinatesList(),
    vivacity_core_point_pb.PointD.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ZoneRealWorldSpace}
 */
proto.vivacity.config.ZoneRealWorldSpace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ZoneRealWorldSpace;
  return proto.vivacity.config.ZoneRealWorldSpace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ZoneRealWorldSpace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ZoneRealWorldSpace}
 */
proto.vivacity.config.ZoneRealWorldSpace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneImageSpaceId(value);
      break;
    case 3:
      var value = new vivacity_core_point_pb.PointD;
      reader.readMessage(value,vivacity_core_point_pb.PointD.deserializeBinaryFromReader);
      msg.addGpsCoordinates(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ZoneRealWorldSpace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ZoneRealWorldSpace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZoneRealWorldSpace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getZoneImageSpaceId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGpsCoordinatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vivacity_core_point_pb.PointD.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZoneRealWorldSpace} returns this
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 zone_image_space_id = 2;
 * @return {number}
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.getZoneImageSpaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZoneRealWorldSpace} returns this
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.setZoneImageSpaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated vivacity.core.PointD gps_coordinates = 3;
 * @return {!Array<!proto.vivacity.core.PointD>}
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.getGpsCoordinatesList = function() {
  return /** @type{!Array<!proto.vivacity.core.PointD>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_point_pb.PointD, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.PointD>} value
 * @return {!proto.vivacity.config.ZoneRealWorldSpace} returns this
*/
proto.vivacity.config.ZoneRealWorldSpace.prototype.setGpsCoordinatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.PointD=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.PointD}
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.addGpsCoordinates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.PointD, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ZoneRealWorldSpace} returns this
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.clearGpsCoordinatesList = function() {
  return this.setGpsCoordinatesList([]);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ZoneRealWorldSpace} returns this
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ZoneRealWorldSpace} returns this
 */
proto.vivacity.config.ZoneRealWorldSpace.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.vivacity.config);
