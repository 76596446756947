/* eslint-disable */
// source: vivacity/config/alerts_config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_alerts_pb = require('../../vivacity/core/alerts_pb.js');
goog.object.extend(proto, vivacity_core_alerts_pb);
goog.exportSymbol('proto.vivacity.config.AlertConfig', null, global);
goog.exportSymbol('proto.vivacity.config.AlertThresholdConfigChange', null, global);
goog.exportSymbol('proto.vivacity.config.AlertThresholdConfigChange.OperationType', null, global);
goog.exportSymbol('proto.vivacity.config.ThresholdGroupConfig', null, global);
goog.exportSymbol('proto.vivacity.config.ZonalAlertConfig', null, global);
goog.exportSymbol('proto.vivacity.config.ZonalAlertConfig.AlertType', null, global);
goog.exportSymbol('proto.vivacity.config.ZonalAlertConfigChange', null, global);
goog.exportSymbol('proto.vivacity.config.ZonalAlertConfigChange.OperationType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.AlertThresholdConfigChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.AlertThresholdConfigChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.AlertThresholdConfigChange.displayName = 'proto.vivacity.config.AlertThresholdConfigChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.AlertConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.AlertConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.AlertConfig.displayName = 'proto.vivacity.config.AlertConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ThresholdGroupConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.ThresholdGroupConfig.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.ThresholdGroupConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ThresholdGroupConfig.displayName = 'proto.vivacity.config.ThresholdGroupConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ZonalAlertConfigChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.ZonalAlertConfigChange.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.ZonalAlertConfigChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ZonalAlertConfigChange.displayName = 'proto.vivacity.config.ZonalAlertConfigChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ZonalAlertConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ZonalAlertConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ZonalAlertConfig.displayName = 'proto.vivacity.config.ZonalAlertConfig';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.AlertThresholdConfigChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.AlertThresholdConfigChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.AlertThresholdConfigChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    alertsMap: (f = msg.getAlertsMap()) ? f.toObject(includeInstance, proto.vivacity.config.AlertConfig.toObject) : [],
    metaAlertMessageKeyName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    brokenFeedVisionProgramId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    brokenFeedTimeBeforeAlertSeconds: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.AlertThresholdConfigChange}
 */
proto.vivacity.config.AlertThresholdConfigChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.AlertThresholdConfigChange;
  return proto.vivacity.config.AlertThresholdConfigChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.AlertThresholdConfigChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.AlertThresholdConfigChange}
 */
proto.vivacity.config.AlertThresholdConfigChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.AlertThresholdConfigChange.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = msg.getAlertsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.config.AlertConfig.deserializeBinaryFromReader, "", new proto.vivacity.config.AlertConfig());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetaAlertMessageKeyName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrokenFeedVisionProgramId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrokenFeedTimeBeforeAlertSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.AlertThresholdConfigChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.AlertThresholdConfigChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.AlertThresholdConfigChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAlertsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.config.AlertConfig.serializeBinaryToWriter);
  }
  f = message.getMetaAlertMessageKeyName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBrokenFeedVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBrokenFeedTimeBeforeAlertSeconds();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.AlertThresholdConfigChange.OperationType = {
  UNKNOWN: 0,
  ADD: 1,
  DELETE: 2,
  SET: 3
};

/**
 * optional OperationType operation_type = 1;
 * @return {!proto.vivacity.config.AlertThresholdConfigChange.OperationType}
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.getOperationType = function() {
  return /** @type {!proto.vivacity.config.AlertThresholdConfigChange.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.AlertThresholdConfigChange.OperationType} value
 * @return {!proto.vivacity.config.AlertThresholdConfigChange} returns this
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * map<string, AlertConfig> alerts = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.config.AlertConfig>}
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.getAlertsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.config.AlertConfig>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.vivacity.config.AlertConfig));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.config.AlertThresholdConfigChange} returns this
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.clearAlertsMap = function() {
  this.getAlertsMap().clear();
  return this;};


/**
 * optional string meta_alert_message_key_name = 3;
 * @return {string}
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.getMetaAlertMessageKeyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.AlertThresholdConfigChange} returns this
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.setMetaAlertMessageKeyName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 broken_feed_vision_program_id = 4;
 * @return {number}
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.getBrokenFeedVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.AlertThresholdConfigChange} returns this
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.setBrokenFeedVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 broken_feed_time_before_alert_seconds = 5;
 * @return {number}
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.getBrokenFeedTimeBeforeAlertSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.AlertThresholdConfigChange} returns this
 */
proto.vivacity.config.AlertThresholdConfigChange.prototype.setBrokenFeedTimeBeforeAlertSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.AlertConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.AlertConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.AlertConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.AlertConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    partitionKeysMap: (f = msg.getPartitionKeysMap()) ? f.toObject(includeInstance, proto.vivacity.config.ThresholdGroupConfig.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.AlertConfig}
 */
proto.vivacity.config.AlertConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.AlertConfig;
  return proto.vivacity.config.AlertConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.AlertConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.AlertConfig}
 */
proto.vivacity.config.AlertConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = msg.getPartitionKeysMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.config.ThresholdGroupConfig.deserializeBinaryFromReader, "", new proto.vivacity.config.ThresholdGroupConfig());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.AlertConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.AlertConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.AlertConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.AlertConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartitionKeysMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.config.ThresholdGroupConfig.serializeBinaryToWriter);
  }
};


/**
 * map<string, ThresholdGroupConfig> partition_keys = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.config.ThresholdGroupConfig>}
 */
proto.vivacity.config.AlertConfig.prototype.getPartitionKeysMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.config.ThresholdGroupConfig>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.vivacity.config.ThresholdGroupConfig));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.config.AlertConfig} returns this
 */
proto.vivacity.config.AlertConfig.prototype.clearPartitionKeysMap = function() {
  this.getPartitionKeysMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.ThresholdGroupConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ThresholdGroupConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ThresholdGroupConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ThresholdGroupConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ThresholdGroupConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    expression: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thresholdsList: jspb.Message.toObjectList(msg.getThresholdsList(),
    vivacity_core_alerts_pb.Threshold.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ThresholdGroupConfig}
 */
proto.vivacity.config.ThresholdGroupConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ThresholdGroupConfig;
  return proto.vivacity.config.ThresholdGroupConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ThresholdGroupConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ThresholdGroupConfig}
 */
proto.vivacity.config.ThresholdGroupConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpression(value);
      break;
    case 2:
      var value = new vivacity_core_alerts_pb.Threshold;
      reader.readMessage(value,vivacity_core_alerts_pb.Threshold.deserializeBinaryFromReader);
      msg.addThresholds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ThresholdGroupConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ThresholdGroupConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ThresholdGroupConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ThresholdGroupConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpression();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThresholdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      vivacity_core_alerts_pb.Threshold.serializeBinaryToWriter
    );
  }
};


/**
 * optional string expression = 1;
 * @return {string}
 */
proto.vivacity.config.ThresholdGroupConfig.prototype.getExpression = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ThresholdGroupConfig} returns this
 */
proto.vivacity.config.ThresholdGroupConfig.prototype.setExpression = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated vivacity.core.Threshold thresholds = 2;
 * @return {!Array<!proto.vivacity.core.Threshold>}
 */
proto.vivacity.config.ThresholdGroupConfig.prototype.getThresholdsList = function() {
  return /** @type{!Array<!proto.vivacity.core.Threshold>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_alerts_pb.Threshold, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.Threshold>} value
 * @return {!proto.vivacity.config.ThresholdGroupConfig} returns this
*/
proto.vivacity.config.ThresholdGroupConfig.prototype.setThresholdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.Threshold=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Threshold}
 */
proto.vivacity.config.ThresholdGroupConfig.prototype.addThresholds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.Threshold, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ThresholdGroupConfig} returns this
 */
proto.vivacity.config.ThresholdGroupConfig.prototype.clearThresholdsList = function() {
  return this.setThresholdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.ZonalAlertConfigChange.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ZonalAlertConfigChange.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ZonalAlertConfigChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ZonalAlertConfigChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZonalAlertConfigChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zonalAlertConfigsList: jspb.Message.toObjectList(msg.getZonalAlertConfigsList(),
    proto.vivacity.config.ZonalAlertConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ZonalAlertConfigChange}
 */
proto.vivacity.config.ZonalAlertConfigChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ZonalAlertConfigChange;
  return proto.vivacity.config.ZonalAlertConfigChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ZonalAlertConfigChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ZonalAlertConfigChange}
 */
proto.vivacity.config.ZonalAlertConfigChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.ZonalAlertConfigChange.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = new proto.vivacity.config.ZonalAlertConfig;
      reader.readMessage(value,proto.vivacity.config.ZonalAlertConfig.deserializeBinaryFromReader);
      msg.addZonalAlertConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ZonalAlertConfigChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ZonalAlertConfigChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ZonalAlertConfigChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZonalAlertConfigChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZonalAlertConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.config.ZonalAlertConfig.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ZonalAlertConfigChange.OperationType = {
  UNKNOWN: 0,
  UPDATE: 1,
  DELETE: 2,
  SET: 3,
  CREATE: 4
};

/**
 * optional OperationType operation_type = 1;
 * @return {!proto.vivacity.config.ZonalAlertConfigChange.OperationType}
 */
proto.vivacity.config.ZonalAlertConfigChange.prototype.getOperationType = function() {
  return /** @type {!proto.vivacity.config.ZonalAlertConfigChange.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.ZonalAlertConfigChange.OperationType} value
 * @return {!proto.vivacity.config.ZonalAlertConfigChange} returns this
 */
proto.vivacity.config.ZonalAlertConfigChange.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated ZonalAlertConfig zonal_alert_configs = 2;
 * @return {!Array<!proto.vivacity.config.ZonalAlertConfig>}
 */
proto.vivacity.config.ZonalAlertConfigChange.prototype.getZonalAlertConfigsList = function() {
  return /** @type{!Array<!proto.vivacity.config.ZonalAlertConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.ZonalAlertConfig, 2));
};


/**
 * @param {!Array<!proto.vivacity.config.ZonalAlertConfig>} value
 * @return {!proto.vivacity.config.ZonalAlertConfigChange} returns this
*/
proto.vivacity.config.ZonalAlertConfigChange.prototype.setZonalAlertConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.config.ZonalAlertConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.ZonalAlertConfig}
 */
proto.vivacity.config.ZonalAlertConfigChange.prototype.addZonalAlertConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.config.ZonalAlertConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ZonalAlertConfigChange} returns this
 */
proto.vivacity.config.ZonalAlertConfigChange.prototype.clearZonalAlertConfigsList = function() {
  return this.setZonalAlertConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ZonalAlertConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ZonalAlertConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZonalAlertConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneImageSpaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    alertType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    windowDurationMilliseconds: jspb.Message.getFieldWithDefault(msg, 5, 0),
    forMilliseconds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    evalIntervalMilliseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    description: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ZonalAlertConfig}
 */
proto.vivacity.config.ZonalAlertConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ZonalAlertConfig;
  return proto.vivacity.config.ZonalAlertConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ZonalAlertConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ZonalAlertConfig}
 */
proto.vivacity.config.ZonalAlertConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setZoneImageSpaceId(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.config.ZonalAlertConfig.AlertType} */ (reader.readEnum());
      msg.setAlertType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWindowDurationMilliseconds(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setForMilliseconds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEvalIntervalMilliseconds(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ZonalAlertConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ZonalAlertConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ZonalAlertConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getZoneImageSpaceId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAlertType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getWindowDurationMilliseconds();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getForMilliseconds();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEvalIntervalMilliseconds();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ZonalAlertConfig.AlertType = {
  UNKNOWN: 0,
  ZONAL_SPEED_LOW: 1,
  ZONAL_SPEED_HIGH: 2,
  ZONAL_OCCUPANCY_LOW: 3,
  ZONAL_OCCUPANCY_HIGH: 4,
  ZONAL_FLOW_LOW: 5,
  ZONAL_FLOW_HIGH: 6,
  ZONAL_CONGESTION_FLOW_AND_OCCUPANCY: 7,
  ZONAL_CONGESTION_SPEED_AND_OCCUPANCY: 8
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 zone_image_space_id = 2;
 * @return {number}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getZoneImageSpaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setZoneImageSpaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional AlertType alert_type = 3;
 * @return {!proto.vivacity.config.ZonalAlertConfig.AlertType}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getAlertType = function() {
  return /** @type {!proto.vivacity.config.ZonalAlertConfig.AlertType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.config.ZonalAlertConfig.AlertType} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setAlertType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float threshold = 4;
 * @return {number}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional uint32 window_duration_milliseconds = 5;
 * @return {number}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getWindowDurationMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setWindowDurationMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 for_milliseconds = 6;
 * @return {number}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getForMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setForMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 eval_interval_milliseconds = 7;
 * @return {number}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getEvalIntervalMilliseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setEvalIntervalMilliseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.vivacity.config.ZonalAlertConfig.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ZonalAlertConfig} returns this
 */
proto.vivacity.config.ZonalAlertConfig.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


goog.object.extend(exports, proto.vivacity.config);
