/* eslint-disable */
// source: vivacity/core/nvosd_params.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
goog.exportSymbol('proto.vivacity.core.NvDsDisplayMeta', null, global);
goog.exportSymbol('proto.vivacity.core.NvOSDArrowParams', null, global);
goog.exportSymbol('proto.vivacity.core.NvOSDArrowParams.ArrowHeadDirection', null, global);
goog.exportSymbol('proto.vivacity.core.NvOSDCircleParams', null, global);
goog.exportSymbol('proto.vivacity.core.NvOSDColorParams', null, global);
goog.exportSymbol('proto.vivacity.core.NvOSDFontParams', null, global);
goog.exportSymbol('proto.vivacity.core.NvOSDLineParams', null, global);
goog.exportSymbol('proto.vivacity.core.NvOSDRectParams', null, global);
goog.exportSymbol('proto.vivacity.core.NvOSDTextParams', null, global);
goog.exportSymbol('proto.vivacity.core.PolyParams', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NvOSDColorParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NvOSDColorParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NvOSDColorParams.displayName = 'proto.vivacity.core.NvOSDColorParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NvOSDFontParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NvOSDFontParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NvOSDFontParams.displayName = 'proto.vivacity.core.NvOSDFontParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NvOSDTextParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NvOSDTextParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NvOSDTextParams.displayName = 'proto.vivacity.core.NvOSDTextParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NvOSDRectParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NvOSDRectParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NvOSDRectParams.displayName = 'proto.vivacity.core.NvOSDRectParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NvOSDLineParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NvOSDLineParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NvOSDLineParams.displayName = 'proto.vivacity.core.NvOSDLineParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NvOSDCircleParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NvOSDCircleParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NvOSDCircleParams.displayName = 'proto.vivacity.core.NvOSDCircleParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NvOSDArrowParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.NvOSDArrowParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NvOSDArrowParams.displayName = 'proto.vivacity.core.NvOSDArrowParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.PolyParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.PolyParams.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.PolyParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.PolyParams.displayName = 'proto.vivacity.core.PolyParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.NvDsDisplayMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.NvDsDisplayMeta.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.NvDsDisplayMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.NvDsDisplayMeta.displayName = 'proto.vivacity.core.NvDsDisplayMeta';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NvOSDColorParams.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NvOSDColorParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NvOSDColorParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDColorParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    red: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    green: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    blue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    alpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDColorParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NvOSDColorParams;
  return proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NvOSDColorParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGreen(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBlue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAlpha(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NvOSDColorParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NvOSDColorParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRed();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getGreen();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAlpha();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double red = 1;
 * @return {number}
 */
proto.vivacity.core.NvOSDColorParams.prototype.getRed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDColorParams} returns this
 */
proto.vivacity.core.NvOSDColorParams.prototype.setRed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double green = 2;
 * @return {number}
 */
proto.vivacity.core.NvOSDColorParams.prototype.getGreen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDColorParams} returns this
 */
proto.vivacity.core.NvOSDColorParams.prototype.setGreen = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double blue = 3;
 * @return {number}
 */
proto.vivacity.core.NvOSDColorParams.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDColorParams} returns this
 */
proto.vivacity.core.NvOSDColorParams.prototype.setBlue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double alpha = 4;
 * @return {number}
 */
proto.vivacity.core.NvOSDColorParams.prototype.getAlpha = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDColorParams} returns this
 */
proto.vivacity.core.NvOSDColorParams.prototype.setAlpha = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NvOSDFontParams.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NvOSDFontParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NvOSDFontParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDFontParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    fontName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fontSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fontColor: (f = msg.getFontColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NvOSDFontParams}
 */
proto.vivacity.core.NvOSDFontParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NvOSDFontParams;
  return proto.vivacity.core.NvOSDFontParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NvOSDFontParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NvOSDFontParams}
 */
proto.vivacity.core.NvOSDFontParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFontName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFontSize(value);
      break;
    case 3:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setFontColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NvOSDFontParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NvOSDFontParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NvOSDFontParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDFontParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFontName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFontSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFontColor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string font_name = 1;
 * @return {string}
 */
proto.vivacity.core.NvOSDFontParams.prototype.getFontName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.NvOSDFontParams} returns this
 */
proto.vivacity.core.NvOSDFontParams.prototype.setFontName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 font_size = 2;
 * @return {number}
 */
proto.vivacity.core.NvOSDFontParams.prototype.getFontSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDFontParams} returns this
 */
proto.vivacity.core.NvOSDFontParams.prototype.setFontSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional NvOSDColorParams font_color = 3;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDFontParams.prototype.getFontColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 3));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDFontParams} returns this
*/
proto.vivacity.core.NvOSDFontParams.prototype.setFontColor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDFontParams} returns this
 */
proto.vivacity.core.NvOSDFontParams.prototype.clearFontColor = function() {
  return this.setFontColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDFontParams.prototype.hasFontColor = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NvOSDTextParams.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NvOSDTextParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NvOSDTextParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDTextParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    xOffset: jspb.Message.getFieldWithDefault(msg, 4, 0),
    yOffset: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fontParams: (f = msg.getFontParams()) && proto.vivacity.core.NvOSDFontParams.toObject(includeInstance, f),
    textBgClr: (f = msg.getTextBgClr()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NvOSDTextParams}
 */
proto.vivacity.core.NvOSDTextParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NvOSDTextParams;
  return proto.vivacity.core.NvOSDTextParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NvOSDTextParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NvOSDTextParams}
 */
proto.vivacity.core.NvOSDTextParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setXOffset(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYOffset(value);
      break;
    case 6:
      var value = new proto.vivacity.core.NvOSDFontParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDFontParams.deserializeBinaryFromReader);
      msg.setFontParams(value);
      break;
    case 7:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setTextBgClr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NvOSDTextParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NvOSDTextParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NvOSDTextParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDTextParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getXOffset();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getYOffset();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFontParams();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vivacity.core.NvOSDFontParams.serializeBinaryToWriter
    );
  }
  f = message.getTextBgClr();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.core.NvOSDTextParams.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
 */
proto.vivacity.core.NvOSDTextParams.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.vivacity.core.NvOSDTextParams.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
 */
proto.vivacity.core.NvOSDTextParams.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_text = 3;
 * @return {string}
 */
proto.vivacity.core.NvOSDTextParams.prototype.getDisplayText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
 */
proto.vivacity.core.NvOSDTextParams.prototype.setDisplayText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 x_offset = 4;
 * @return {number}
 */
proto.vivacity.core.NvOSDTextParams.prototype.getXOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
 */
proto.vivacity.core.NvOSDTextParams.prototype.setXOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 y_offset = 5;
 * @return {number}
 */
proto.vivacity.core.NvOSDTextParams.prototype.getYOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
 */
proto.vivacity.core.NvOSDTextParams.prototype.setYOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional NvOSDFontParams font_params = 6;
 * @return {?proto.vivacity.core.NvOSDFontParams}
 */
proto.vivacity.core.NvOSDTextParams.prototype.getFontParams = function() {
  return /** @type{?proto.vivacity.core.NvOSDFontParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDFontParams, 6));
};


/**
 * @param {?proto.vivacity.core.NvOSDFontParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
*/
proto.vivacity.core.NvOSDTextParams.prototype.setFontParams = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
 */
proto.vivacity.core.NvOSDTextParams.prototype.clearFontParams = function() {
  return this.setFontParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDTextParams.prototype.hasFontParams = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NvOSDColorParams text_bg_clr = 7;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDTextParams.prototype.getTextBgClr = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 7));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
*/
proto.vivacity.core.NvOSDTextParams.prototype.setTextBgClr = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDTextParams} returns this
 */
proto.vivacity.core.NvOSDTextParams.prototype.clearTextBgClr = function() {
  return this.setTextBgClr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDTextParams.prototype.hasTextBgClr = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NvOSDRectParams.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NvOSDRectParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NvOSDRectParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDRectParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    left: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    top: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    borderWidth: jspb.Message.getFieldWithDefault(msg, 7, 0),
    borderColor: (f = msg.getBorderColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f),
    bgColor: (f = msg.getBgColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f),
    hasColorInfo: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    colorId: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NvOSDRectParams}
 */
proto.vivacity.core.NvOSDRectParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NvOSDRectParams;
  return proto.vivacity.core.NvOSDRectParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NvOSDRectParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NvOSDRectParams}
 */
proto.vivacity.core.NvOSDRectParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLeft(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTop(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeight(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBorderWidth(value);
      break;
    case 8:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setBorderColor(value);
      break;
    case 9:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setBgColor(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasColorInfo(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NvOSDRectParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NvOSDRectParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NvOSDRectParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDRectParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLeft();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTop();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getBorderWidth();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getBorderColor();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
  f = message.getBgColor();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
  f = message.getHasColorInfo();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getColorId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float left = 3;
 * @return {number}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getLeft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setLeft = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float top = 4;
 * @return {number}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getTop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setTop = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float width = 5;
 * @return {number}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float height = 6;
 * @return {number}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional uint32 border_width = 7;
 * @return {number}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getBorderWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setBorderWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional NvOSDColorParams border_color = 8;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getBorderColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 8));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
*/
proto.vivacity.core.NvOSDRectParams.prototype.setBorderColor = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.clearBorderColor = function() {
  return this.setBorderColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDRectParams.prototype.hasBorderColor = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NvOSDColorParams bg_color = 9;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getBgColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 9));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
*/
proto.vivacity.core.NvOSDRectParams.prototype.setBgColor = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.clearBgColor = function() {
  return this.setBgColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDRectParams.prototype.hasBgColor = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool has_color_info = 10;
 * @return {boolean}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getHasColorInfo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setHasColorInfo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 color_id = 11;
 * @return {number}
 */
proto.vivacity.core.NvOSDRectParams.prototype.getColorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDRectParams} returns this
 */
proto.vivacity.core.NvOSDRectParams.prototype.setColorId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NvOSDLineParams.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NvOSDLineParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NvOSDLineParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDLineParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    x1: jspb.Message.getFieldWithDefault(msg, 3, 0),
    y1: jspb.Message.getFieldWithDefault(msg, 4, 0),
    x2: jspb.Message.getFieldWithDefault(msg, 5, 0),
    y2: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lineWidth: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lineColor: (f = msg.getLineColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NvOSDLineParams}
 */
proto.vivacity.core.NvOSDLineParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NvOSDLineParams;
  return proto.vivacity.core.NvOSDLineParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NvOSDLineParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NvOSDLineParams}
 */
proto.vivacity.core.NvOSDLineParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setX1(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setY1(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setX2(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setY2(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLineWidth(value);
      break;
    case 8:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setLineColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NvOSDLineParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NvOSDLineParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NvOSDLineParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDLineParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getX1();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getY1();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getX2();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getY2();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLineWidth();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getLineColor();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.core.NvOSDLineParams.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
 */
proto.vivacity.core.NvOSDLineParams.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.vivacity.core.NvOSDLineParams.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
 */
proto.vivacity.core.NvOSDLineParams.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 x1 = 3;
 * @return {number}
 */
proto.vivacity.core.NvOSDLineParams.prototype.getX1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
 */
proto.vivacity.core.NvOSDLineParams.prototype.setX1 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 y1 = 4;
 * @return {number}
 */
proto.vivacity.core.NvOSDLineParams.prototype.getY1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
 */
proto.vivacity.core.NvOSDLineParams.prototype.setY1 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 x2 = 5;
 * @return {number}
 */
proto.vivacity.core.NvOSDLineParams.prototype.getX2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
 */
proto.vivacity.core.NvOSDLineParams.prototype.setX2 = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 y2 = 6;
 * @return {number}
 */
proto.vivacity.core.NvOSDLineParams.prototype.getY2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
 */
proto.vivacity.core.NvOSDLineParams.prototype.setY2 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 line_width = 7;
 * @return {number}
 */
proto.vivacity.core.NvOSDLineParams.prototype.getLineWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
 */
proto.vivacity.core.NvOSDLineParams.prototype.setLineWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional NvOSDColorParams line_color = 8;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDLineParams.prototype.getLineColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 8));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
*/
proto.vivacity.core.NvOSDLineParams.prototype.setLineColor = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDLineParams} returns this
 */
proto.vivacity.core.NvOSDLineParams.prototype.clearLineColor = function() {
  return this.setLineColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDLineParams.prototype.hasLineColor = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NvOSDCircleParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NvOSDCircleParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDCircleParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    xc: jspb.Message.getFieldWithDefault(msg, 3, 0),
    yc: jspb.Message.getFieldWithDefault(msg, 4, 0),
    radius: jspb.Message.getFieldWithDefault(msg, 5, 0),
    circleColor: (f = msg.getCircleColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f),
    bgColor: (f = msg.getBgColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NvOSDCircleParams}
 */
proto.vivacity.core.NvOSDCircleParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NvOSDCircleParams;
  return proto.vivacity.core.NvOSDCircleParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NvOSDCircleParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NvOSDCircleParams}
 */
proto.vivacity.core.NvOSDCircleParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setXc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYc(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRadius(value);
      break;
    case 6:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setCircleColor(value);
      break;
    case 7:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setBgColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NvOSDCircleParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NvOSDCircleParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDCircleParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getXc();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getYc();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCircleColor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
  f = message.getBgColor();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
 */
proto.vivacity.core.NvOSDCircleParams.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
 */
proto.vivacity.core.NvOSDCircleParams.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 xc = 3;
 * @return {number}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.getXc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
 */
proto.vivacity.core.NvOSDCircleParams.prototype.setXc = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 yc = 4;
 * @return {number}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.getYc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
 */
proto.vivacity.core.NvOSDCircleParams.prototype.setYc = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 radius = 5;
 * @return {number}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
 */
proto.vivacity.core.NvOSDCircleParams.prototype.setRadius = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional NvOSDColorParams circle_color = 6;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.getCircleColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 6));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
*/
proto.vivacity.core.NvOSDCircleParams.prototype.setCircleColor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
 */
proto.vivacity.core.NvOSDCircleParams.prototype.clearCircleColor = function() {
  return this.setCircleColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.hasCircleColor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NvOSDColorParams bg_color = 7;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.getBgColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 7));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
*/
proto.vivacity.core.NvOSDCircleParams.prototype.setBgColor = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDCircleParams} returns this
 */
proto.vivacity.core.NvOSDCircleParams.prototype.clearBgColor = function() {
  return this.setBgColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDCircleParams.prototype.hasBgColor = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NvOSDArrowParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NvOSDArrowParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDArrowParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    x1: jspb.Message.getFieldWithDefault(msg, 3, 0),
    y1: jspb.Message.getFieldWithDefault(msg, 4, 0),
    x2: jspb.Message.getFieldWithDefault(msg, 5, 0),
    y2: jspb.Message.getFieldWithDefault(msg, 6, 0),
    arrowWidth: jspb.Message.getFieldWithDefault(msg, 7, 0),
    arrowHead: jspb.Message.getFieldWithDefault(msg, 8, 0),
    arrowColor: (f = msg.getArrowColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NvOSDArrowParams}
 */
proto.vivacity.core.NvOSDArrowParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NvOSDArrowParams;
  return proto.vivacity.core.NvOSDArrowParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NvOSDArrowParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NvOSDArrowParams}
 */
proto.vivacity.core.NvOSDArrowParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setX1(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setY1(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setX2(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setY2(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setArrowWidth(value);
      break;
    case 8:
      var value = /** @type {!proto.vivacity.core.NvOSDArrowParams.ArrowHeadDirection} */ (reader.readEnum());
      msg.setArrowHead(value);
      break;
    case 9:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setArrowColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NvOSDArrowParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NvOSDArrowParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvOSDArrowParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getX1();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getY1();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getX2();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getY2();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getArrowWidth();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getArrowHead();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getArrowColor();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.NvOSDArrowParams.ArrowHeadDirection = {
  START_HEAD: 0,
  END_HEAD: 1,
  BOTH_HEAD: 2
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 x1 = 3;
 * @return {number}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getX1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.setX1 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 y1 = 4;
 * @return {number}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getY1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.setY1 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 x2 = 5;
 * @return {number}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getX2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.setX2 = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 y2 = 6;
 * @return {number}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getY2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.setY2 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 arrow_width = 7;
 * @return {number}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getArrowWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.setArrowWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional ArrowHeadDirection arrow_head = 8;
 * @return {!proto.vivacity.core.NvOSDArrowParams.ArrowHeadDirection}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getArrowHead = function() {
  return /** @type {!proto.vivacity.core.NvOSDArrowParams.ArrowHeadDirection} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vivacity.core.NvOSDArrowParams.ArrowHeadDirection} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.setArrowHead = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional NvOSDColorParams arrow_color = 9;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.getArrowColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 9));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
*/
proto.vivacity.core.NvOSDArrowParams.prototype.setArrowColor = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.NvOSDArrowParams} returns this
 */
proto.vivacity.core.NvOSDArrowParams.prototype.clearArrowColor = function() {
  return this.setArrowColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.NvOSDArrowParams.prototype.hasArrowColor = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.PolyParams.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.PolyParams.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.PolyParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.PolyParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.PolyParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pointList: jspb.Message.toObjectList(msg.getPointList(),
    vivacity_core_point_pb.Point.toObject, includeInstance),
    borderWidth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    borderColor: (f = msg.getBorderColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f),
    bgColor: (f = msg.getBgColor()) && proto.vivacity.core.NvOSDColorParams.toObject(includeInstance, f),
    hasColorInfo: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    colorId: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.PolyParams}
 */
proto.vivacity.core.PolyParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.PolyParams;
  return proto.vivacity.core.PolyParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.PolyParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.PolyParams}
 */
proto.vivacity.core.PolyParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.addPoint(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBorderWidth(value);
      break;
    case 5:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setBorderColor(value);
      break;
    case 6:
      var value = new proto.vivacity.core.NvOSDColorParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDColorParams.deserializeBinaryFromReader);
      msg.setBgColor(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasColorInfo(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.PolyParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.PolyParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.PolyParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.PolyParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPointList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBorderWidth();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBorderColor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
  f = message.getBgColor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vivacity.core.NvOSDColorParams.serializeBinaryToWriter
    );
  }
  f = message.getHasColorInfo();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getColorId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.core.PolyParams.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.PolyParams} returns this
 */
proto.vivacity.core.PolyParams.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.vivacity.core.PolyParams.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.PolyParams} returns this
 */
proto.vivacity.core.PolyParams.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Point point = 3;
 * @return {!Array<!proto.vivacity.core.Point>}
 */
proto.vivacity.core.PolyParams.prototype.getPointList = function() {
  return /** @type{!Array<!proto.vivacity.core.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_point_pb.Point, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.Point>} value
 * @return {!proto.vivacity.core.PolyParams} returns this
*/
proto.vivacity.core.PolyParams.prototype.setPointList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Point}
 */
proto.vivacity.core.PolyParams.prototype.addPoint = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.PolyParams} returns this
 */
proto.vivacity.core.PolyParams.prototype.clearPointList = function() {
  return this.setPointList([]);
};


/**
 * optional uint32 border_width = 4;
 * @return {number}
 */
proto.vivacity.core.PolyParams.prototype.getBorderWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.PolyParams} returns this
 */
proto.vivacity.core.PolyParams.prototype.setBorderWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional NvOSDColorParams border_color = 5;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.PolyParams.prototype.getBorderColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 5));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.PolyParams} returns this
*/
proto.vivacity.core.PolyParams.prototype.setBorderColor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PolyParams} returns this
 */
proto.vivacity.core.PolyParams.prototype.clearBorderColor = function() {
  return this.setBorderColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PolyParams.prototype.hasBorderColor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NvOSDColorParams bg_color = 6;
 * @return {?proto.vivacity.core.NvOSDColorParams}
 */
proto.vivacity.core.PolyParams.prototype.getBgColor = function() {
  return /** @type{?proto.vivacity.core.NvOSDColorParams} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.NvOSDColorParams, 6));
};


/**
 * @param {?proto.vivacity.core.NvOSDColorParams|undefined} value
 * @return {!proto.vivacity.core.PolyParams} returns this
*/
proto.vivacity.core.PolyParams.prototype.setBgColor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PolyParams} returns this
 */
proto.vivacity.core.PolyParams.prototype.clearBgColor = function() {
  return this.setBgColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PolyParams.prototype.hasBgColor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool has_color_info = 7;
 * @return {boolean}
 */
proto.vivacity.core.PolyParams.prototype.getHasColorInfo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.PolyParams} returns this
 */
proto.vivacity.core.PolyParams.prototype.setHasColorInfo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 color_id = 8;
 * @return {number}
 */
proto.vivacity.core.PolyParams.prototype.getColorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.PolyParams} returns this
 */
proto.vivacity.core.PolyParams.prototype.setColorId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.NvDsDisplayMeta.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.NvDsDisplayMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.NvDsDisplayMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvDsDisplayMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    rectParamsList: jspb.Message.toObjectList(msg.getRectParamsList(),
    proto.vivacity.core.NvOSDRectParams.toObject, includeInstance),
    textParamsList: jspb.Message.toObjectList(msg.getTextParamsList(),
    proto.vivacity.core.NvOSDTextParams.toObject, includeInstance),
    lineParamsList: jspb.Message.toObjectList(msg.getLineParamsList(),
    proto.vivacity.core.NvOSDLineParams.toObject, includeInstance),
    arrowParamsList: jspb.Message.toObjectList(msg.getArrowParamsList(),
    proto.vivacity.core.NvOSDArrowParams.toObject, includeInstance),
    circleParamsList: jspb.Message.toObjectList(msg.getCircleParamsList(),
    proto.vivacity.core.NvOSDCircleParams.toObject, includeInstance),
    polyParamsList: jspb.Message.toObjectList(msg.getPolyParamsList(),
    proto.vivacity.core.PolyParams.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.NvDsDisplayMeta}
 */
proto.vivacity.core.NvDsDisplayMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.NvDsDisplayMeta;
  return proto.vivacity.core.NvDsDisplayMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.NvDsDisplayMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.NvDsDisplayMeta}
 */
proto.vivacity.core.NvDsDisplayMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.NvOSDRectParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDRectParams.deserializeBinaryFromReader);
      msg.addRectParams(value);
      break;
    case 2:
      var value = new proto.vivacity.core.NvOSDTextParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDTextParams.deserializeBinaryFromReader);
      msg.addTextParams(value);
      break;
    case 3:
      var value = new proto.vivacity.core.NvOSDLineParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDLineParams.deserializeBinaryFromReader);
      msg.addLineParams(value);
      break;
    case 4:
      var value = new proto.vivacity.core.NvOSDArrowParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDArrowParams.deserializeBinaryFromReader);
      msg.addArrowParams(value);
      break;
    case 5:
      var value = new proto.vivacity.core.NvOSDCircleParams;
      reader.readMessage(value,proto.vivacity.core.NvOSDCircleParams.deserializeBinaryFromReader);
      msg.addCircleParams(value);
      break;
    case 6:
      var value = new proto.vivacity.core.PolyParams;
      reader.readMessage(value,proto.vivacity.core.PolyParams.deserializeBinaryFromReader);
      msg.addPolyParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.NvDsDisplayMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.NvDsDisplayMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.NvDsDisplayMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRectParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.core.NvOSDRectParams.serializeBinaryToWriter
    );
  }
  f = message.getTextParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.core.NvOSDTextParams.serializeBinaryToWriter
    );
  }
  f = message.getLineParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.core.NvOSDLineParams.serializeBinaryToWriter
    );
  }
  f = message.getArrowParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vivacity.core.NvOSDArrowParams.serializeBinaryToWriter
    );
  }
  f = message.getCircleParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.vivacity.core.NvOSDCircleParams.serializeBinaryToWriter
    );
  }
  f = message.getPolyParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.vivacity.core.PolyParams.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NvOSDRectParams rect_params = 1;
 * @return {!Array<!proto.vivacity.core.NvOSDRectParams>}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.getRectParamsList = function() {
  return /** @type{!Array<!proto.vivacity.core.NvOSDRectParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NvOSDRectParams, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.NvOSDRectParams>} value
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
*/
proto.vivacity.core.NvDsDisplayMeta.prototype.setRectParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.NvOSDRectParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NvOSDRectParams}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.addRectParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.NvOSDRectParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.clearRectParamsList = function() {
  return this.setRectParamsList([]);
};


/**
 * repeated NvOSDTextParams text_params = 2;
 * @return {!Array<!proto.vivacity.core.NvOSDTextParams>}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.getTextParamsList = function() {
  return /** @type{!Array<!proto.vivacity.core.NvOSDTextParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NvOSDTextParams, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.NvOSDTextParams>} value
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
*/
proto.vivacity.core.NvDsDisplayMeta.prototype.setTextParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.NvOSDTextParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NvOSDTextParams}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.addTextParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.NvOSDTextParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.clearTextParamsList = function() {
  return this.setTextParamsList([]);
};


/**
 * repeated NvOSDLineParams line_params = 3;
 * @return {!Array<!proto.vivacity.core.NvOSDLineParams>}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.getLineParamsList = function() {
  return /** @type{!Array<!proto.vivacity.core.NvOSDLineParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NvOSDLineParams, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.NvOSDLineParams>} value
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
*/
proto.vivacity.core.NvDsDisplayMeta.prototype.setLineParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.NvOSDLineParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NvOSDLineParams}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.addLineParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.NvOSDLineParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.clearLineParamsList = function() {
  return this.setLineParamsList([]);
};


/**
 * repeated NvOSDArrowParams arrow_params = 4;
 * @return {!Array<!proto.vivacity.core.NvOSDArrowParams>}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.getArrowParamsList = function() {
  return /** @type{!Array<!proto.vivacity.core.NvOSDArrowParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NvOSDArrowParams, 4));
};


/**
 * @param {!Array<!proto.vivacity.core.NvOSDArrowParams>} value
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
*/
proto.vivacity.core.NvDsDisplayMeta.prototype.setArrowParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vivacity.core.NvOSDArrowParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NvOSDArrowParams}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.addArrowParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vivacity.core.NvOSDArrowParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.clearArrowParamsList = function() {
  return this.setArrowParamsList([]);
};


/**
 * repeated NvOSDCircleParams circle_params = 5;
 * @return {!Array<!proto.vivacity.core.NvOSDCircleParams>}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.getCircleParamsList = function() {
  return /** @type{!Array<!proto.vivacity.core.NvOSDCircleParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.NvOSDCircleParams, 5));
};


/**
 * @param {!Array<!proto.vivacity.core.NvOSDCircleParams>} value
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
*/
proto.vivacity.core.NvDsDisplayMeta.prototype.setCircleParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vivacity.core.NvOSDCircleParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NvOSDCircleParams}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.addCircleParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vivacity.core.NvOSDCircleParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.clearCircleParamsList = function() {
  return this.setCircleParamsList([]);
};


/**
 * repeated PolyParams poly_params = 6;
 * @return {!Array<!proto.vivacity.core.PolyParams>}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.getPolyParamsList = function() {
  return /** @type{!Array<!proto.vivacity.core.PolyParams>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.PolyParams, 6));
};


/**
 * @param {!Array<!proto.vivacity.core.PolyParams>} value
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
*/
proto.vivacity.core.NvDsDisplayMeta.prototype.setPolyParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vivacity.core.PolyParams=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.PolyParams}
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.addPolyParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vivacity.core.PolyParams, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.NvDsDisplayMeta} returns this
 */
proto.vivacity.core.NvDsDisplayMeta.prototype.clearPolyParamsList = function() {
  return this.setPolyParamsList([]);
};


goog.object.extend(exports, proto.vivacity.core);
