/* eslint-disable */
// source: vivacity/command/docker.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.vivacity.command.DockerImage', null, global);
goog.exportSymbol('proto.vivacity.command.DockerImageCommand', null, global);
goog.exportSymbol('proto.vivacity.command.DockerImageCommand.CommandType', null, global);
goog.exportSymbol('proto.vivacity.command.DockerImageResponse', null, global);
goog.exportSymbol('proto.vivacity.command.DockerImageResponse.ResponseType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.command.DockerImageCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.command.DockerImageCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.command.DockerImageCommand.displayName = 'proto.vivacity.command.DockerImageCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.command.DockerImageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.command.DockerImageResponse.repeatedFields_, null);
};
goog.inherits(proto.vivacity.command.DockerImageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.command.DockerImageResponse.displayName = 'proto.vivacity.command.DockerImageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.command.DockerImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.command.DockerImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.command.DockerImage.displayName = 'proto.vivacity.command.DockerImage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.command.DockerImageCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.command.DockerImageCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.command.DockerImageCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.DockerImageCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    commandType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    image: (f = msg.getImage()) && proto.vivacity.command.DockerImage.toObject(includeInstance, f),
    response: (f = msg.getResponse()) && proto.vivacity.command.DockerImageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.command.DockerImageCommand}
 */
proto.vivacity.command.DockerImageCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.command.DockerImageCommand;
  return proto.vivacity.command.DockerImageCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.command.DockerImageCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.command.DockerImageCommand}
 */
proto.vivacity.command.DockerImageCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.command.DockerImageCommand.CommandType} */ (reader.readEnum());
      msg.setCommandType(value);
      break;
    case 2:
      var value = new proto.vivacity.command.DockerImage;
      reader.readMessage(value,proto.vivacity.command.DockerImage.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 4:
      var value = new proto.vivacity.command.DockerImageResponse;
      reader.readMessage(value,proto.vivacity.command.DockerImageResponse.deserializeBinaryFromReader);
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.command.DockerImageCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.command.DockerImageCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.command.DockerImageCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.DockerImageCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommandType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.command.DockerImage.serializeBinaryToWriter
    );
  }
  f = message.getResponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.command.DockerImageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.command.DockerImageCommand.CommandType = {
  UNKNOWN: 0,
  PULL: 1,
  DELETE: 2,
  PRUNE: 3,
  LIST_IMAGES: 4
};

/**
 * optional CommandType command_type = 1;
 * @return {!proto.vivacity.command.DockerImageCommand.CommandType}
 */
proto.vivacity.command.DockerImageCommand.prototype.getCommandType = function() {
  return /** @type {!proto.vivacity.command.DockerImageCommand.CommandType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.command.DockerImageCommand.CommandType} value
 * @return {!proto.vivacity.command.DockerImageCommand} returns this
 */
proto.vivacity.command.DockerImageCommand.prototype.setCommandType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional DockerImage image = 2;
 * @return {?proto.vivacity.command.DockerImage}
 */
proto.vivacity.command.DockerImageCommand.prototype.getImage = function() {
  return /** @type{?proto.vivacity.command.DockerImage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.command.DockerImage, 2));
};


/**
 * @param {?proto.vivacity.command.DockerImage|undefined} value
 * @return {!proto.vivacity.command.DockerImageCommand} returns this
*/
proto.vivacity.command.DockerImageCommand.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.command.DockerImageCommand} returns this
 */
proto.vivacity.command.DockerImageCommand.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.command.DockerImageCommand.prototype.hasImage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DockerImageResponse response = 4;
 * @return {?proto.vivacity.command.DockerImageResponse}
 */
proto.vivacity.command.DockerImageCommand.prototype.getResponse = function() {
  return /** @type{?proto.vivacity.command.DockerImageResponse} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.command.DockerImageResponse, 4));
};


/**
 * @param {?proto.vivacity.command.DockerImageResponse|undefined} value
 * @return {!proto.vivacity.command.DockerImageCommand} returns this
*/
proto.vivacity.command.DockerImageCommand.prototype.setResponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.command.DockerImageCommand} returns this
 */
proto.vivacity.command.DockerImageCommand.prototype.clearResponse = function() {
  return this.setResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.command.DockerImageCommand.prototype.hasResponse = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.command.DockerImageResponse.repeatedFields_ = [2,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.command.DockerImageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.command.DockerImageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.command.DockerImageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.DockerImageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dockerImagesList: jspb.Message.toObjectList(msg.getDockerImagesList(),
    proto.vivacity.command.DockerImage.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    progress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deletedList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    untaggedList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    spaceReclaimed: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.command.DockerImageResponse}
 */
proto.vivacity.command.DockerImageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.command.DockerImageResponse;
  return proto.vivacity.command.DockerImageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.command.DockerImageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.command.DockerImageResponse}
 */
proto.vivacity.command.DockerImageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.command.DockerImageResponse.ResponseType} */ (reader.readEnum());
      msg.setResponseType(value);
      break;
    case 2:
      var value = new proto.vivacity.command.DockerImage;
      reader.readMessage(value,proto.vivacity.command.DockerImage.deserializeBinaryFromReader);
      msg.addDockerImages(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeleted(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addUntagged(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpaceReclaimed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.command.DockerImageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.command.DockerImageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.command.DockerImageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.DockerImageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDockerImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.command.DockerImage.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProgress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeletedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getUntaggedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getSpaceReclaimed();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.command.DockerImageResponse.ResponseType = {
  UNKNOWN: 0,
  SUCCESS: 1,
  ERROR_EMPTY_DOCKER_COMMAND_MESSAGE: 2,
  ERROR_DOCKER_CLIENT_START_FAILED: 3,
  ERROR_EMPTY_IMAGE_NAME: 4,
  ERROR_PULL_FAILED: 5,
  ERROR_DECODING_RESPONSE: 6,
  ERROR_REMOVING_IMAGE: 7,
  ERROR_DID_NOT_GET_SUCCESS_RESPONSE: 8,
  ERROR_WITH_PRUNING: 9,
  ERROR_WITH_LISTING_IMAGES: 10,
  ERROR_DECODING_LISTED_IMAGES: 11,
  ERROR_UNEXPECTED_DOCKER_IMAGE_COMMAND: 12
};

/**
 * optional ResponseType response_type = 1;
 * @return {!proto.vivacity.command.DockerImageResponse.ResponseType}
 */
proto.vivacity.command.DockerImageResponse.prototype.getResponseType = function() {
  return /** @type {!proto.vivacity.command.DockerImageResponse.ResponseType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.command.DockerImageResponse.ResponseType} value
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.setResponseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated DockerImage docker_images = 2;
 * @return {!Array<!proto.vivacity.command.DockerImage>}
 */
proto.vivacity.command.DockerImageResponse.prototype.getDockerImagesList = function() {
  return /** @type{!Array<!proto.vivacity.command.DockerImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.command.DockerImage, 2));
};


/**
 * @param {!Array<!proto.vivacity.command.DockerImage>} value
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
*/
proto.vivacity.command.DockerImageResponse.prototype.setDockerImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.command.DockerImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.command.DockerImage}
 */
proto.vivacity.command.DockerImageResponse.prototype.addDockerImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.command.DockerImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.clearDockerImagesList = function() {
  return this.setDockerImagesList([]);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.vivacity.command.DockerImageResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.vivacity.command.DockerImageResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string progress = 5;
 * @return {string}
 */
proto.vivacity.command.DockerImageResponse.prototype.getProgress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.setProgress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string deleted = 6;
 * @return {!Array<string>}
 */
proto.vivacity.command.DockerImageResponse.prototype.getDeletedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.setDeletedList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.addDeleted = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.clearDeletedList = function() {
  return this.setDeletedList([]);
};


/**
 * repeated string untagged = 7;
 * @return {!Array<string>}
 */
proto.vivacity.command.DockerImageResponse.prototype.getUntaggedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.setUntaggedList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.addUntagged = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.clearUntaggedList = function() {
  return this.setUntaggedList([]);
};


/**
 * optional float space_reclaimed = 8;
 * @return {number}
 */
proto.vivacity.command.DockerImageResponse.prototype.getSpaceReclaimed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.command.DockerImageResponse} returns this
 */
proto.vivacity.command.DockerImageResponse.prototype.setSpaceReclaimed = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.command.DockerImage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.command.DockerImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.command.DockerImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.DockerImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageTag: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imageHash: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.command.DockerImage}
 */
proto.vivacity.command.DockerImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.command.DockerImage;
  return proto.vivacity.command.DockerImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.command.DockerImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.command.DockerImage}
 */
proto.vivacity.command.DockerImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageTag(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.command.DockerImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.command.DockerImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.command.DockerImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.command.DockerImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageTag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImageHash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string image_name = 1;
 * @return {string}
 */
proto.vivacity.command.DockerImage.prototype.getImageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.DockerImage} returns this
 */
proto.vivacity.command.DockerImage.prototype.setImageName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_tag = 2;
 * @return {string}
 */
proto.vivacity.command.DockerImage.prototype.getImageTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.DockerImage} returns this
 */
proto.vivacity.command.DockerImage.prototype.setImageTag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string image_hash = 3;
 * @return {string}
 */
proto.vivacity.command.DockerImage.prototype.getImageHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.command.DockerImage} returns this
 */
proto.vivacity.command.DockerImage.prototype.setImageHash = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.vivacity.command);
