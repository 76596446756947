/* eslint-disable */
// source: vivacity/core/windowed_state.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_detection_quality_pb = require('../../vivacity/core/detection_quality_pb.js');
goog.object.extend(proto, vivacity_core_detection_quality_pb);
goog.exportSymbol('proto.vivacity.core.BitTimeSeries', null, global);
goog.exportSymbol('proto.vivacity.core.DetectionFeatureTimeSeries', null, global);
goog.exportSymbol('proto.vivacity.core.DetectionFeatureTimeSeriesByClass', null, global);
goog.exportSymbol('proto.vivacity.core.InInterstageByStreamIndex', null, global);
goog.exportSymbol('proto.vivacity.core.LatestStageByStreamIndex', null, global);
goog.exportSymbol('proto.vivacity.core.Limits', null, global);
goog.exportSymbol('proto.vivacity.core.ReplyBitsTimeSeries', null, global);
goog.exportSymbol('proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex', null, global);
goog.exportSymbol('proto.vivacity.core.Stages', null, global);
goog.exportSymbol('proto.vivacity.core.UnhealthyTimeSeries', null, global);
goog.exportSymbol('proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex', null, global);
goog.exportSymbol('proto.vivacity.core.ValidNextStagesByStreamIndex', null, global);
goog.exportSymbol('proto.vivacity.core.WindowedState', null, global);
goog.exportSymbol('proto.vivacity.core.WindowedStateDataFakeness', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.WindowedState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.WindowedState.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.WindowedState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.WindowedState.displayName = 'proto.vivacity.core.WindowedState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ValidNextStagesByStreamIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ValidNextStagesByStreamIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ValidNextStagesByStreamIndex.displayName = 'proto.vivacity.core.ValidNextStagesByStreamIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Stages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.Stages.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.Stages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Stages.displayName = 'proto.vivacity.core.Stages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.LatestStageByStreamIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.LatestStageByStreamIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.LatestStageByStreamIndex.displayName = 'proto.vivacity.core.LatestStageByStreamIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.InInterstageByStreamIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.InInterstageByStreamIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.InInterstageByStreamIndex.displayName = 'proto.vivacity.core.InInterstageByStreamIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.DetectionFeatureTimeSeriesByClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DetectionFeatureTimeSeriesByClass.displayName = 'proto.vivacity.core.DetectionFeatureTimeSeriesByClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DetectionFeatureTimeSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.DetectionFeatureTimeSeries.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.DetectionFeatureTimeSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DetectionFeatureTimeSeries.displayName = 'proto.vivacity.core.DetectionFeatureTimeSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.displayName = 'proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ReplyBitsTimeSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ReplyBitsTimeSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ReplyBitsTimeSeries.displayName = 'proto.vivacity.core.ReplyBitsTimeSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.BitTimeSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.BitTimeSeries.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.BitTimeSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.BitTimeSeries.displayName = 'proto.vivacity.core.BitTimeSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.displayName = 'proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.UnhealthyTimeSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.UnhealthyTimeSeries.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.UnhealthyTimeSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.UnhealthyTimeSeries.displayName = 'proto.vivacity.core.UnhealthyTimeSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.Limits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.Limits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.Limits.displayName = 'proto.vivacity.core.Limits';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.WindowedState.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.WindowedState.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.WindowedState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.WindowedState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WindowedState.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampsMicrosecondsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    classCrossingsTimeSeriesByCountlineIdMap: (f = msg.getClassCrossingsTimeSeriesByCountlineIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.toObject) : [],
    aggregatedCrossingsTimeSeriesByCountlineIdMap: (f = msg.getAggregatedCrossingsTimeSeriesByCountlineIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    classFilteredCrossingsTimeSeriesByCountlineIdMap: (f = msg.getClassFilteredCrossingsTimeSeriesByCountlineIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    classOccupancyTimeSeriesByZoneIdMap: (f = msg.getClassOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.toObject) : [],
    stoppedClassOccupancyTimeSeriesByZoneIdMap: (f = msg.getStoppedClassOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.toObject) : [],
    directionalClassOccupancyTimeSeriesByZoneIdMap: (f = msg.getDirectionalClassOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.toObject) : [],
    stoppedDirectionalClassOccupancyTimeSeriesByZoneIdMap: (f = msg.getStoppedDirectionalClassOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.toObject) : [],
    aggregatedOccupancyTimeSeriesByZoneIdMap: (f = msg.getAggregatedOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    stoppedAggregatedOccupancyTimeSeriesByZoneIdMap: (f = msg.getStoppedAggregatedOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    directionalAggregatedOccupancyTimeSeriesByZoneIdMap: (f = msg.getDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    stoppedDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap: (f = msg.getStoppedDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    classFilteredOccupancyTimeSeriesByZoneIdMap: (f = msg.getClassFilteredOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    stoppedClassFilteredOccupancyTimeSeriesByZoneIdMap: (f = msg.getStoppedClassFilteredOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    directionalClassFilteredOccupancyTimeSeriesByZoneIdMap: (f = msg.getDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    stoppedDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap: (f = msg.getStoppedDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : [],
    replyBitsTimeSeriesByStreamIndexByControllerSiteIdMap: (f = msg.getReplyBitsTimeSeriesByStreamIndexByControllerSiteIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.toObject) : [],
    unhealthyTimeSeriesByStreamIndexByControllerSiteIdMap: (f = msg.getUnhealthyTimeSeriesByStreamIndexByControllerSiteIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.toObject) : [],
    intervalMicroseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    numSamples: jspb.Message.getFieldWithDefault(msg, 9, 0),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    inInterstageByStreamIndexByControllerSiteIdMap: (f = msg.getInInterstageByStreamIndexByControllerSiteIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.InInterstageByStreamIndex.toObject) : [],
    latestStageByStreamIndexByControllerSiteIdMap: (f = msg.getLatestStageByStreamIndexByControllerSiteIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.LatestStageByStreamIndex.toObject) : [],
    validNextStagesByStreamIndexByControllerSiteIdMap: (f = msg.getValidNextStagesByStreamIndexByControllerSiteIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.ValidNextStagesByStreamIndex.toObject) : [],
    limitsByControllerSiteIdMap: (f = msg.getLimitsByControllerSiteIdMap()) ? f.toObject(includeInstance, proto.vivacity.core.Limits.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.WindowedState}
 */
proto.vivacity.core.WindowedState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.WindowedState;
  return proto.vivacity.core.WindowedState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.WindowedState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.WindowedState}
 */
proto.vivacity.core.WindowedState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTimestampsMicroseconds(values[i]);
      }
      break;
    case 2:
      var value = msg.getClassCrossingsTimeSeriesByCountlineIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeriesByClass());
         });
      break;
    case 3:
      var value = msg.getAggregatedCrossingsTimeSeriesByCountlineIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 18:
      var value = msg.getClassFilteredCrossingsTimeSeriesByCountlineIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 4:
      var value = msg.getClassOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeriesByClass());
         });
      break;
    case 19:
      var value = msg.getStoppedClassOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeriesByClass());
         });
      break;
    case 14:
      var value = msg.getDirectionalClassOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeriesByClass());
         });
      break;
    case 20:
      var value = msg.getStoppedDirectionalClassOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeriesByClass());
         });
      break;
    case 5:
      var value = msg.getAggregatedOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 21:
      var value = msg.getStoppedAggregatedOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 15:
      var value = msg.getDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 22:
      var value = msg.getStoppedDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 16:
      var value = msg.getClassFilteredOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 24:
      var value = msg.getStoppedClassFilteredOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 17:
      var value = msg.getDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 23:
      var value = msg.getStoppedDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    case 6:
      var value = msg.getReplyBitsTimeSeriesByStreamIndexByControllerSiteIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.deserializeBinaryFromReader, "", new proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex());
         });
      break;
    case 7:
      var value = msg.getUnhealthyTimeSeriesByStreamIndexByControllerSiteIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.deserializeBinaryFromReader, "", new proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex());
         });
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIntervalMicroseconds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumSamples(value);
      break;
    case 10:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 11:
      var value = msg.getInInterstageByStreamIndexByControllerSiteIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.InInterstageByStreamIndex.deserializeBinaryFromReader, "", new proto.vivacity.core.InInterstageByStreamIndex());
         });
      break;
    case 12:
      var value = msg.getLatestStageByStreamIndexByControllerSiteIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.LatestStageByStreamIndex.deserializeBinaryFromReader, "", new proto.vivacity.core.LatestStageByStreamIndex());
         });
      break;
    case 13:
      var value = msg.getValidNextStagesByStreamIndexByControllerSiteIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.ValidNextStagesByStreamIndex.deserializeBinaryFromReader, "", new proto.vivacity.core.ValidNextStagesByStreamIndex());
         });
      break;
    case 25:
      var value = msg.getLimitsByControllerSiteIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.Limits.deserializeBinaryFromReader, "", new proto.vivacity.core.Limits());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.WindowedState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.WindowedState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.WindowedState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.WindowedState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampsMicrosecondsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getClassCrossingsTimeSeriesByCountlineIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.serializeBinaryToWriter);
  }
  f = message.getAggregatedCrossingsTimeSeriesByCountlineIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getClassFilteredCrossingsTimeSeriesByCountlineIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(18, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getClassOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.serializeBinaryToWriter);
  }
  f = message.getStoppedClassOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(19, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.serializeBinaryToWriter);
  }
  f = message.getDirectionalClassOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(14, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.serializeBinaryToWriter);
  }
  f = message.getStoppedDirectionalClassOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(20, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeriesByClass.serializeBinaryToWriter);
  }
  f = message.getAggregatedOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getStoppedAggregatedOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(21, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getStoppedDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(22, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getClassFilteredOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getStoppedClassFilteredOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(24, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(17, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getStoppedDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(23, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
  f = message.getReplyBitsTimeSeriesByStreamIndexByControllerSiteIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.serializeBinaryToWriter);
  }
  f = message.getUnhealthyTimeSeriesByStreamIndexByControllerSiteIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.serializeBinaryToWriter);
  }
  f = message.getIntervalMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getNumSamples();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getInInterstageByStreamIndexByControllerSiteIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.InInterstageByStreamIndex.serializeBinaryToWriter);
  }
  f = message.getLatestStageByStreamIndexByControllerSiteIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(12, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.LatestStageByStreamIndex.serializeBinaryToWriter);
  }
  f = message.getValidNextStagesByStreamIndexByControllerSiteIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(13, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.ValidNextStagesByStreamIndex.serializeBinaryToWriter);
  }
  f = message.getLimitsByControllerSiteIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(25, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.Limits.serializeBinaryToWriter);
  }
};


/**
 * repeated uint64 timestamps_microseconds = 1;
 * @return {!Array<number>}
 */
proto.vivacity.core.WindowedState.prototype.getTimestampsMicrosecondsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.setTimestampsMicrosecondsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.addTimestampsMicroseconds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearTimestampsMicrosecondsList = function() {
  return this.setTimestampsMicrosecondsList([]);
};


/**
 * map<uint32, DetectionFeatureTimeSeriesByClass> class_crossings_time_series_by_countline_id = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>}
 */
proto.vivacity.core.WindowedState.prototype.getClassCrossingsTimeSeriesByCountlineIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeriesByClass));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearClassCrossingsTimeSeriesByCountlineIdMap = function() {
  this.getClassCrossingsTimeSeriesByCountlineIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> aggregated_crossings_time_series_by_countline_id = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getAggregatedCrossingsTimeSeriesByCountlineIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearAggregatedCrossingsTimeSeriesByCountlineIdMap = function() {
  this.getAggregatedCrossingsTimeSeriesByCountlineIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> class_filtered_crossings_time_series_by_countline_id = 18;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getClassFilteredCrossingsTimeSeriesByCountlineIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 18, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearClassFilteredCrossingsTimeSeriesByCountlineIdMap = function() {
  this.getClassFilteredCrossingsTimeSeriesByCountlineIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeriesByClass> class_occupancy_time_series_by_zone_id = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>}
 */
proto.vivacity.core.WindowedState.prototype.getClassOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeriesByClass));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearClassOccupancyTimeSeriesByZoneIdMap = function() {
  this.getClassOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeriesByClass> stopped_class_occupancy_time_series_by_zone_id = 19;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>}
 */
proto.vivacity.core.WindowedState.prototype.getStoppedClassOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>} */ (
      jspb.Message.getMapField(this, 19, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeriesByClass));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearStoppedClassOccupancyTimeSeriesByZoneIdMap = function() {
  this.getStoppedClassOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeriesByClass> directional_class_occupancy_time_series_by_zone_id = 14;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>}
 */
proto.vivacity.core.WindowedState.prototype.getDirectionalClassOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>} */ (
      jspb.Message.getMapField(this, 14, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeriesByClass));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearDirectionalClassOccupancyTimeSeriesByZoneIdMap = function() {
  this.getDirectionalClassOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeriesByClass> stopped_directional_class_occupancy_time_series_by_zone_id = 20;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>}
 */
proto.vivacity.core.WindowedState.prototype.getStoppedDirectionalClassOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeriesByClass>} */ (
      jspb.Message.getMapField(this, 20, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeriesByClass));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearStoppedDirectionalClassOccupancyTimeSeriesByZoneIdMap = function() {
  this.getStoppedDirectionalClassOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> aggregated_occupancy_time_series_by_zone_id = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getAggregatedOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearAggregatedOccupancyTimeSeriesByZoneIdMap = function() {
  this.getAggregatedOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> stopped_aggregated_occupancy_time_series_by_zone_id = 21;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getStoppedAggregatedOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 21, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearStoppedAggregatedOccupancyTimeSeriesByZoneIdMap = function() {
  this.getStoppedAggregatedOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> directional_aggregated_occupancy_time_series_by_zone_id = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap = function() {
  this.getDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> stopped_directional_aggregated_occupancy_time_series_by_zone_id = 22;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getStoppedDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 22, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearStoppedDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap = function() {
  this.getStoppedDirectionalAggregatedOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> class_filtered_occupancy_time_series_by_zone_id = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getClassFilteredOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearClassFilteredOccupancyTimeSeriesByZoneIdMap = function() {
  this.getClassFilteredOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> stopped_class_filtered_occupancy_time_series_by_zone_id = 24;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getStoppedClassFilteredOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 24, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearStoppedClassFilteredOccupancyTimeSeriesByZoneIdMap = function() {
  this.getStoppedClassFilteredOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> directional_class_filtered_occupancy_time_series_by_zone_id = 17;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 17, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap = function() {
  this.getDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<uint32, DetectionFeatureTimeSeries> stopped_directional_class_filtered_occupancy_time_series_by_zone_id = 23;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.WindowedState.prototype.getStoppedDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 23, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearStoppedDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap = function() {
  this.getStoppedDirectionalClassFilteredOccupancyTimeSeriesByZoneIdMap().clear();
  return this;};


/**
 * map<string, ReplyBitsTimeSeriesByStreamIndex> reply_bits_time_series_by_stream_index_by_controller_site_id = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex>}
 */
proto.vivacity.core.WindowedState.prototype.getReplyBitsTimeSeriesByStreamIndexByControllerSiteIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearReplyBitsTimeSeriesByStreamIndexByControllerSiteIdMap = function() {
  this.getReplyBitsTimeSeriesByStreamIndexByControllerSiteIdMap().clear();
  return this;};


/**
 * map<string, UnhealthyTimeSeriesByStreamIndex> unhealthy_time_series_by_stream_index_by_controller_site_id = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex>}
 */
proto.vivacity.core.WindowedState.prototype.getUnhealthyTimeSeriesByStreamIndexByControllerSiteIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearUnhealthyTimeSeriesByStreamIndexByControllerSiteIdMap = function() {
  this.getUnhealthyTimeSeriesByStreamIndexByControllerSiteIdMap().clear();
  return this;};


/**
 * optional uint64 interval_microseconds = 8;
 * @return {number}
 */
proto.vivacity.core.WindowedState.prototype.getIntervalMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.setIntervalMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 num_samples = 9;
 * @return {number}
 */
proto.vivacity.core.WindowedState.prototype.getNumSamples = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.setNumSamples = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional TracingContext tracing_ctx = 10;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.WindowedState.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 10));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.WindowedState} returns this
*/
proto.vivacity.core.WindowedState.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.WindowedState.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * map<string, InInterstageByStreamIndex> in_interstage_by_stream_index_by_controller_site_id = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.InInterstageByStreamIndex>}
 */
proto.vivacity.core.WindowedState.prototype.getInInterstageByStreamIndexByControllerSiteIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.InInterstageByStreamIndex>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      proto.vivacity.core.InInterstageByStreamIndex));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearInInterstageByStreamIndexByControllerSiteIdMap = function() {
  this.getInInterstageByStreamIndexByControllerSiteIdMap().clear();
  return this;};


/**
 * map<string, LatestStageByStreamIndex> latest_stage_by_stream_index_by_controller_site_id = 12;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.LatestStageByStreamIndex>}
 */
proto.vivacity.core.WindowedState.prototype.getLatestStageByStreamIndexByControllerSiteIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.LatestStageByStreamIndex>} */ (
      jspb.Message.getMapField(this, 12, opt_noLazyCreate,
      proto.vivacity.core.LatestStageByStreamIndex));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearLatestStageByStreamIndexByControllerSiteIdMap = function() {
  this.getLatestStageByStreamIndexByControllerSiteIdMap().clear();
  return this;};


/**
 * map<string, ValidNextStagesByStreamIndex> valid_next_stages_by_stream_index_by_controller_site_id = 13;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.ValidNextStagesByStreamIndex>}
 */
proto.vivacity.core.WindowedState.prototype.getValidNextStagesByStreamIndexByControllerSiteIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.ValidNextStagesByStreamIndex>} */ (
      jspb.Message.getMapField(this, 13, opt_noLazyCreate,
      proto.vivacity.core.ValidNextStagesByStreamIndex));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearValidNextStagesByStreamIndexByControllerSiteIdMap = function() {
  this.getValidNextStagesByStreamIndexByControllerSiteIdMap().clear();
  return this;};


/**
 * map<string, Limits> limits_by_controller_site_id = 25;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.Limits>}
 */
proto.vivacity.core.WindowedState.prototype.getLimitsByControllerSiteIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.Limits>} */ (
      jspb.Message.getMapField(this, 25, opt_noLazyCreate,
      proto.vivacity.core.Limits));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.WindowedState} returns this
 */
proto.vivacity.core.WindowedState.prototype.clearLimitsByControllerSiteIdMap = function() {
  this.getLimitsByControllerSiteIdMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ValidNextStagesByStreamIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ValidNextStagesByStreamIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ValidNextStagesByStreamIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ValidNextStagesByStreamIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    validNextStagesMap: (f = msg.getValidNextStagesMap()) ? f.toObject(includeInstance, proto.vivacity.core.Stages.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ValidNextStagesByStreamIndex}
 */
proto.vivacity.core.ValidNextStagesByStreamIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ValidNextStagesByStreamIndex;
  return proto.vivacity.core.ValidNextStagesByStreamIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ValidNextStagesByStreamIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ValidNextStagesByStreamIndex}
 */
proto.vivacity.core.ValidNextStagesByStreamIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getValidNextStagesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.Stages.deserializeBinaryFromReader, 0, new proto.vivacity.core.Stages());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ValidNextStagesByStreamIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ValidNextStagesByStreamIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ValidNextStagesByStreamIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ValidNextStagesByStreamIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValidNextStagesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.Stages.serializeBinaryToWriter);
  }
};


/**
 * map<uint32, Stages> valid_next_stages = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.Stages>}
 */
proto.vivacity.core.ValidNextStagesByStreamIndex.prototype.getValidNextStagesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.Stages>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.vivacity.core.Stages));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.ValidNextStagesByStreamIndex} returns this
 */
proto.vivacity.core.ValidNextStagesByStreamIndex.prototype.clearValidNextStagesMap = function() {
  this.getValidNextStagesMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.Stages.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Stages.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Stages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Stages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Stages.toObject = function(includeInstance, msg) {
  var f, obj = {
    stagesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    extraStagesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Stages}
 */
proto.vivacity.core.Stages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Stages;
  return proto.vivacity.core.Stages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Stages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Stages}
 */
proto.vivacity.core.Stages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStages(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExtraStages(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Stages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Stages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Stages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Stages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
  f = message.getExtraStagesList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
};


/**
 * repeated uint32 stages = 1;
 * @return {!Array<number>}
 */
proto.vivacity.core.Stages.prototype.getStagesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Stages} returns this
 */
proto.vivacity.core.Stages.prototype.setStagesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Stages} returns this
 */
proto.vivacity.core.Stages.prototype.addStages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Stages} returns this
 */
proto.vivacity.core.Stages.prototype.clearStagesList = function() {
  return this.setStagesList([]);
};


/**
 * repeated uint32 extra_stages = 3;
 * @return {!Array<number>}
 */
proto.vivacity.core.Stages.prototype.getExtraStagesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.Stages} returns this
 */
proto.vivacity.core.Stages.prototype.setExtraStagesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Stages} returns this
 */
proto.vivacity.core.Stages.prototype.addExtraStages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.Stages} returns this
 */
proto.vivacity.core.Stages.prototype.clearExtraStagesList = function() {
  return this.setExtraStagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.LatestStageByStreamIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.LatestStageByStreamIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.LatestStageByStreamIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.LatestStageByStreamIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    latestStageMap: (f = msg.getLatestStageMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.LatestStageByStreamIndex}
 */
proto.vivacity.core.LatestStageByStreamIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.LatestStageByStreamIndex;
  return proto.vivacity.core.LatestStageByStreamIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.LatestStageByStreamIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.LatestStageByStreamIndex}
 */
proto.vivacity.core.LatestStageByStreamIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getLatestStageMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.LatestStageByStreamIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.LatestStageByStreamIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.LatestStageByStreamIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.LatestStageByStreamIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatestStageMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
};


/**
 * map<uint32, uint32> latest_stage = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.LatestStageByStreamIndex.prototype.getLatestStageMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.LatestStageByStreamIndex} returns this
 */
proto.vivacity.core.LatestStageByStreamIndex.prototype.clearLatestStageMap = function() {
  this.getLatestStageMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.InInterstageByStreamIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.InInterstageByStreamIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.InInterstageByStreamIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.InInterstageByStreamIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    inInterstageMap: (f = msg.getInInterstageMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.InInterstageByStreamIndex}
 */
proto.vivacity.core.InInterstageByStreamIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.InInterstageByStreamIndex;
  return proto.vivacity.core.InInterstageByStreamIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.InInterstageByStreamIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.InInterstageByStreamIndex}
 */
proto.vivacity.core.InInterstageByStreamIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getInInterstageMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readBool, null, 0, false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.InInterstageByStreamIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.InInterstageByStreamIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.InInterstageByStreamIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.InInterstageByStreamIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInInterstageMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * map<uint32, bool> in_interstage = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.vivacity.core.InInterstageByStreamIndex.prototype.getInInterstageMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,boolean>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.InInterstageByStreamIndex} returns this
 */
proto.vivacity.core.InInterstageByStreamIndex.prototype.clearInInterstageMap = function() {
  this.getInInterstageMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DetectionFeatureTimeSeriesByClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DetectionFeatureTimeSeriesByClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureTimeSeriesByClassMap: (f = msg.getFeatureTimeSeriesByClassMap()) ? f.toObject(includeInstance, proto.vivacity.core.DetectionFeatureTimeSeries.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeriesByClass}
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DetectionFeatureTimeSeriesByClass;
  return proto.vivacity.core.DetectionFeatureTimeSeriesByClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DetectionFeatureTimeSeriesByClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeriesByClass}
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getFeatureTimeSeriesByClassMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.DetectionFeatureTimeSeries());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DetectionFeatureTimeSeriesByClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DetectionFeatureTimeSeriesByClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureTimeSeriesByClassMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter);
  }
};


/**
 * map<int32, DetectionFeatureTimeSeries> feature_time_series_by_class = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>}
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass.prototype.getFeatureTimeSeriesByClassMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.DetectionFeatureTimeSeries>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.vivacity.core.DetectionFeatureTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeriesByClass} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeriesByClass.prototype.clearFeatureTimeSeriesByClassMap = function() {
  this.getFeatureTimeSeriesByClassMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.DetectionFeatureTimeSeries.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DetectionFeatureTimeSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DetectionFeatureTimeSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionFeatureTimeSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_class: jspb.Message.getFieldWithDefault(msg, 1, 0),
    valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    fakenessList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    detectionQualityList: jspb.Message.toObjectList(msg.getDetectionQualityList(),
    vivacity_core_detection_quality_pb.DetectionQuality.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DetectionFeatureTimeSeries;
  return proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DetectionFeatureTimeSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClass(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<!proto.vivacity.core.WindowedStateDataFakeness>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFakeness(values[i]);
      }
      break;
    case 4:
      var value = new vivacity_core_detection_quality_pb.DetectionQuality;
      reader.readMessage(value,vivacity_core_detection_quality_pb.DetectionQuality.deserializeBinaryFromReader);
      msg.addDetectionQuality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DetectionFeatureTimeSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionFeatureTimeSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      2,
      f
    );
  }
  f = message.getFakenessList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getDetectionQualityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      vivacity_core_detection_quality_pb.DetectionQuality.serializeBinaryToWriter
    );
  }
};


/**
 * optional ClassifyingDetectorClassTypes class = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.getClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.setClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated float values = 2;
 * @return {!Array<number>}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * repeated WindowedStateDataFakeness fakeness = 3;
 * @return {!Array<!proto.vivacity.core.WindowedStateDataFakeness>}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.getFakenessList = function() {
  return /** @type {!Array<!proto.vivacity.core.WindowedStateDataFakeness>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.WindowedStateDataFakeness>} value
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.setFakenessList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.vivacity.core.WindowedStateDataFakeness} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.addFakeness = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.clearFakenessList = function() {
  return this.setFakenessList([]);
};


/**
 * repeated DetectionQuality detection_quality = 4;
 * @return {!Array<!proto.vivacity.core.DetectionQuality>}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.getDetectionQualityList = function() {
  return /** @type{!Array<!proto.vivacity.core.DetectionQuality>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_detection_quality_pb.DetectionQuality, 4));
};


/**
 * @param {!Array<!proto.vivacity.core.DetectionQuality>} value
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
*/
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.setDetectionQualityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vivacity.core.DetectionQuality=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.DetectionQuality}
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.addDetectionQuality = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vivacity.core.DetectionQuality, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionFeatureTimeSeries} returns this
 */
proto.vivacity.core.DetectionFeatureTimeSeries.prototype.clearDetectionQualityList = function() {
  return this.setDetectionQualityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    replyBitsTimeSeriesMap: (f = msg.getReplyBitsTimeSeriesMap()) ? f.toObject(includeInstance, proto.vivacity.core.ReplyBitsTimeSeries.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex}
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex;
  return proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex}
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getReplyBitsTimeSeriesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.ReplyBitsTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.ReplyBitsTimeSeries());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReplyBitsTimeSeriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.ReplyBitsTimeSeries.serializeBinaryToWriter);
  }
};


/**
 * map<uint32, ReplyBitsTimeSeries> reply_bits_time_series = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.ReplyBitsTimeSeries>}
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.prototype.getReplyBitsTimeSeriesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.ReplyBitsTimeSeries>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.vivacity.core.ReplyBitsTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex} returns this
 */
proto.vivacity.core.ReplyBitsTimeSeriesByStreamIndex.prototype.clearReplyBitsTimeSeriesMap = function() {
  this.getReplyBitsTimeSeriesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ReplyBitsTimeSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ReplyBitsTimeSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ReplyBitsTimeSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ReplyBitsTimeSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    gnAsMapMap: (f = msg.getGnAsMapMap()) ? f.toObject(includeInstance, proto.vivacity.core.BitTimeSeries.toObject) : [],
    specialBitsMap: (f = msg.getSpecialBitsMap()) ? f.toObject(includeInstance, proto.vivacity.core.BitTimeSeries.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ReplyBitsTimeSeries}
 */
proto.vivacity.core.ReplyBitsTimeSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ReplyBitsTimeSeries;
  return proto.vivacity.core.ReplyBitsTimeSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ReplyBitsTimeSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ReplyBitsTimeSeries}
 */
proto.vivacity.core.ReplyBitsTimeSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getGnAsMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.BitTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.BitTimeSeries());
         });
      break;
    case 2:
      var value = msg.getSpecialBitsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.BitTimeSeries.deserializeBinaryFromReader, "", new proto.vivacity.core.BitTimeSeries());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ReplyBitsTimeSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ReplyBitsTimeSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ReplyBitsTimeSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ReplyBitsTimeSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGnAsMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.BitTimeSeries.serializeBinaryToWriter);
  }
  f = message.getSpecialBitsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.BitTimeSeries.serializeBinaryToWriter);
  }
};


/**
 * map<uint32, BitTimeSeries> Gn_as_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.BitTimeSeries>}
 */
proto.vivacity.core.ReplyBitsTimeSeries.prototype.getGnAsMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.BitTimeSeries>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.vivacity.core.BitTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.ReplyBitsTimeSeries} returns this
 */
proto.vivacity.core.ReplyBitsTimeSeries.prototype.clearGnAsMapMap = function() {
  this.getGnAsMapMap().clear();
  return this;};


/**
 * map<string, BitTimeSeries> special_bits = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.BitTimeSeries>}
 */
proto.vivacity.core.ReplyBitsTimeSeries.prototype.getSpecialBitsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.BitTimeSeries>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.vivacity.core.BitTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.ReplyBitsTimeSeries} returns this
 */
proto.vivacity.core.ReplyBitsTimeSeries.prototype.clearSpecialBitsMap = function() {
  this.getSpecialBitsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.BitTimeSeries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.BitTimeSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.BitTimeSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.BitTimeSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.BitTimeSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    bitsList: (f = jspb.Message.getRepeatedBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.BitTimeSeries}
 */
proto.vivacity.core.BitTimeSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.BitTimeSeries;
  return proto.vivacity.core.BitTimeSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.BitTimeSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.BitTimeSeries}
 */
proto.vivacity.core.BitTimeSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBits(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.BitTimeSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.BitTimeSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.BitTimeSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.BitTimeSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBitsList();
  if (f.length > 0) {
    writer.writePackedBool(
      1,
      f
    );
  }
};


/**
 * repeated bool bits = 1;
 * @return {!Array<boolean>}
 */
proto.vivacity.core.BitTimeSeries.prototype.getBitsList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 1));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.vivacity.core.BitTimeSeries} returns this
 */
proto.vivacity.core.BitTimeSeries.prototype.setBitsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.BitTimeSeries} returns this
 */
proto.vivacity.core.BitTimeSeries.prototype.addBits = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.BitTimeSeries} returns this
 */
proto.vivacity.core.BitTimeSeries.prototype.clearBitsList = function() {
  return this.setBitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    unhealthyTimeSeriesMap: (f = msg.getUnhealthyTimeSeriesMap()) ? f.toObject(includeInstance, proto.vivacity.core.UnhealthyTimeSeries.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex}
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex;
  return proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex}
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getUnhealthyTimeSeriesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.UnhealthyTimeSeries.deserializeBinaryFromReader, 0, new proto.vivacity.core.UnhealthyTimeSeries());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnhealthyTimeSeriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.UnhealthyTimeSeries.serializeBinaryToWriter);
  }
};


/**
 * map<uint32, UnhealthyTimeSeries> unhealthy_time_series = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.UnhealthyTimeSeries>}
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.prototype.getUnhealthyTimeSeriesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.UnhealthyTimeSeries>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.vivacity.core.UnhealthyTimeSeries));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex} returns this
 */
proto.vivacity.core.UnhealthyTimeSeriesByStreamIndex.prototype.clearUnhealthyTimeSeriesMap = function() {
  this.getUnhealthyTimeSeriesMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.UnhealthyTimeSeries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.UnhealthyTimeSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.UnhealthyTimeSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.UnhealthyTimeSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.UnhealthyTimeSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    unhealthyList: (f = jspb.Message.getRepeatedBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.UnhealthyTimeSeries}
 */
proto.vivacity.core.UnhealthyTimeSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.UnhealthyTimeSeries;
  return proto.vivacity.core.UnhealthyTimeSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.UnhealthyTimeSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.UnhealthyTimeSeries}
 */
proto.vivacity.core.UnhealthyTimeSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnhealthy(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.UnhealthyTimeSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.UnhealthyTimeSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.UnhealthyTimeSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.UnhealthyTimeSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnhealthyList();
  if (f.length > 0) {
    writer.writePackedBool(
      1,
      f
    );
  }
};


/**
 * repeated bool unhealthy = 1;
 * @return {!Array<boolean>}
 */
proto.vivacity.core.UnhealthyTimeSeries.prototype.getUnhealthyList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 1));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.vivacity.core.UnhealthyTimeSeries} returns this
 */
proto.vivacity.core.UnhealthyTimeSeries.prototype.setUnhealthyList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.UnhealthyTimeSeries} returns this
 */
proto.vivacity.core.UnhealthyTimeSeries.prototype.addUnhealthy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.UnhealthyTimeSeries} returns this
 */
proto.vivacity.core.UnhealthyTimeSeries.prototype.clearUnhealthyList = function() {
  return this.setUnhealthyList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.Limits.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.Limits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.Limits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Limits.toObject = function(includeInstance, msg) {
  var f, obj = {
    phaseUnservedDeadlineByPhaseMicrosecondsMap: (f = msg.getPhaseUnservedDeadlineByPhaseMicrosecondsMap()) ? f.toObject(includeInstance, undefined) : [],
    stageTooLongDeadlineByStreamIndexMicrosecondsMap: (f = msg.getStageTooLongDeadlineByStreamIndexMicrosecondsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.Limits}
 */
proto.vivacity.core.Limits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.Limits;
  return proto.vivacity.core.Limits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.Limits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.Limits}
 */
proto.vivacity.core.Limits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getPhaseUnservedDeadlineByPhaseMicrosecondsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readUint64, null, "", 0);
         });
      break;
    case 2:
      var value = msg.getStageTooLongDeadlineByStreamIndexMicrosecondsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.Limits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.Limits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.Limits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.Limits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhaseUnservedDeadlineByPhaseMicrosecondsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getStageTooLongDeadlineByStreamIndexMicrosecondsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
};


/**
 * map<string, uint64> phase_unserved_deadline_by_phase_microseconds = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.vivacity.core.Limits.prototype.getPhaseUnservedDeadlineByPhaseMicrosecondsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Limits} returns this
 */
proto.vivacity.core.Limits.prototype.clearPhaseUnservedDeadlineByPhaseMicrosecondsMap = function() {
  this.getPhaseUnservedDeadlineByPhaseMicrosecondsMap().clear();
  return this;};


/**
 * map<uint32, uint64> stage_too_long_deadline_by_stream_index_microseconds = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.Limits.prototype.getStageTooLongDeadlineByStreamIndexMicrosecondsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.Limits} returns this
 */
proto.vivacity.core.Limits.prototype.clearStageTooLongDeadlineByStreamIndexMicrosecondsMap = function() {
  this.getStageTooLongDeadlineByStreamIndexMicrosecondsMap().clear();
  return this;};


/**
 * @enum {number}
 */
proto.vivacity.core.WindowedStateDataFakeness = {
  REAL: 0,
  FORWARD_FILLED: 1,
  FAIL_HIGH: 2,
  ZERO_FILLED: 3,
  REPRESENTATIVE_HISTORICAL: 4,
  SUBSTITUTE: 5
};

goog.object.extend(exports, proto.vivacity.core);
