import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Slider,
  Typography,
} from "@mui/material";

import { useStores } from "../../hooks/useStores.hook";
import { FreneticityButton } from "../styledElements";
import { DataGrid, GridAutoSizer, GridColDef } from "@mui/x-data-grid";
import { ClassifyingDetectorClassTypeNumber, classLookup } from "../../workers/utils";
import _ from "lodash";
import { CountlineID, FrameNumber, TrackID } from "../../domain";
import { ComputerVisionRun, CVRunCountlineCrossing, ValidationRunCountlineCrossing } from "../../interfaces";
import { ClassifyingDetectorClassTypes } from "../../vivacity/core/classifying_detector_class_types_pb";
import { GridFilterModel } from "@mui/x-data-grid/models/gridFilterModel";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { GridFilterItem } from "@mui/x-data-grid/models/gridFilterItem";

interface FormatResultsModalProps {
  isOpen: boolean;
  onClose: () => void;
  snackbarText?: (text: string) => void;
  selectedCVRunOutsideOfVideo?: ComputerVisionRun;
  validationRunOutsideOfVideo?: number;
}

interface ResultRow {
  id: number;
  countlineID: number;
  classID: number;
  className: string;
  cvClockwise: number;
  cvAntiClockwise: number;
  humanClockwise: number;
  humanAntiClockwise: number;
}

interface ANPRResultRow {
  id: number;
  plate: string;
  countlineID: CountlineID;
  anprVehicleClass?: ClassifyingDetectorClassTypeNumber;
  className: string;
  humanFrameNumber: FrameNumber;
  cvPlateCorrect: boolean;
  cvClassCorrect: boolean;
  cvDirectionCorrect: boolean;
  cvPlateRank?: number;
  cvTrackNumber?: TrackID;
  cvFrameNumber?: FrameNumber;
}

const anprPlateColumn: GridColDef<ANPRResultRow, string> = {
  field: "plate",
  headerName: "Plate",
  flex: 0.3,
  renderCell: ({ row }) => {
    return <Typography>{row.plate}</Typography>;
  },
};

const anprCountlineColumn: GridColDef<ANPRResultRow, string> = {
  field: "countlineID",
  headerName: "Countline ID",
  flex: 0.3,
  renderCell: ({ row }) => {
    return <Typography>{row.countlineID}</Typography>;
  },
};

const anprClassNameColumn: GridColDef<ANPRResultRow, string> = {
  field: "anprVehicleClass",
  headerName: "Vehicle Class",
  flex: 0.3,
  renderCell: ({ row }) => {
    return <Typography>{classLookup[row.anprVehicleClass ?? 0]}</Typography>;
  },
};

const anprHumanFrameNumberColumn: GridColDef<ANPRResultRow, string> = {
  field: "humanFrameNumber",
  headerName: "Frame Number",
  flex: 0.3,
  renderCell: ({ row }) => {
    return <Typography>{row.humanFrameNumber}</Typography>;
  },
};

const anprCvPlateCorrectColumn: GridColDef<ANPRResultRow, string> = {
  field: "cvPlateCorrect",
  headerName: "Plate Correct",
  flex: 0.3,
  renderCell: ({ row }) => {
    return <Typography>{row.cvPlateCorrect ? 1 : 0}</Typography>;
  },
};

const anprCvClassCorrectColumn: GridColDef<ANPRResultRow, string> = {
  field: "cvClassCorrect",
  headerName: "Class correct",
  flex: 0.3,
  renderCell: ({ row }) => {
    return <Typography>{row.cvClassCorrect ? 1 : 0}</Typography>;
  },
};

const anprCvDirectionCorrectColumn: GridColDef<ANPRResultRow, string> = {
  field: "cvDirectionCorrect",
  headerName: "Direction correct",
  flex: 0.3,
  valueFormatter: v => v.value,
  renderCell: ({ row }) => {
    return <Typography>{row.cvDirectionCorrect ? 1 : 0}</Typography>;
  },
};

const anprCvPlateRankColumn: GridColDef<ANPRResultRow, string> = {
  field: "cvPlateRank",
  headerName: "Rank",
  flex: 0.3,
  renderCell: ({ row }) => {
    return <Typography>{row.cvPlateRank ?? "N/A"}</Typography>;
  },
};

const anprCvTrackNumberColumn: GridColDef<ANPRResultRow, string> = {
  field: "cvTrackNumber",
  headerName: "CV Track Number",
  flex: 0.3,
  valueFormatter: v => v.value,
  renderCell: ({ row }) => {
    return <Typography>{row.cvTrackNumber ?? "N/A"}</Typography>;
  },
};

const anprCvFrameNumberColumn: GridColDef<ANPRResultRow, string> = {
  field: "cvFrameNumber",
  headerName: "CV Frame Number ",
  flex: 0.3,
  valueFormatter: v => v.value,
  renderCell: ({ row }) => {
    return <Typography>{row.cvFrameNumber ?? "N/A"}</Typography>;
  },
};

const classColumn: GridColDef<ResultRow, number> = {
  field: "classID",
  headerName: "ClassID",
  flex: 0.3,
  valueFormatter: v => v.value,
};

const countlineIDColumn: GridColDef<ResultRow, string> = {
  field: "countlineID",
  headerName: "Countline ID",
  flex: 0.3,
  valueFormatter: v => v.value,
  renderCell: ({ row }) => {
    return <Typography>{row.countlineID}</Typography>;
  },
};

const classNameColumn: GridColDef<ResultRow, string> = {
  field: "className",
  headerName: "Class",
  flex: 0.3,
  valueFormatter: v => v.value,
  renderCell: ({ row }) => {
    return <Typography>{row.className}</Typography>;
  },
};

const cvClockwiseColumn: GridColDef<ResultRow, string> = {
  field: "cvClockwise",
  headerName: "CV Clockwise",
  flex: 0.3,
  renderCell: ({ row }) => {
    return (
      <Typography color={row.humanClockwise === row.cvClockwise ? "#000" : "#FF0000"}>{row.cvClockwise}</Typography>
    );
  },
};

const cvAntiClockwiseColumn: GridColDef<ResultRow, string> = {
  field: "cvAntiClockwise",
  headerName: "CV Anti-clockwise",
  flex: 0.3,
  renderCell: ({ row }) => {
    return (
      <Typography color={row.humanAntiClockwise === row.cvAntiClockwise ? "#000" : "#FF0000"}>
        {row.cvAntiClockwise}
      </Typography>
    );
  },
};

const valClockwiseColumn: GridColDef<ResultRow, string> = {
  field: "humanClockwise",
  headerName: "Human Clockwise",
  flex: 0.3,
  renderCell: ({ row }) => {
    return (
      <Typography color={row.humanClockwise === row.cvClockwise ? "#000" : "#FF0000"}>{row.humanClockwise}</Typography>
    );
  },
};

const valAntiClockwiseColumn: GridColDef<ResultRow, string> = {
  field: "humanAntiClockwise",
  headerName: "Human Anti-clockwise",
  flex: 0.3,
  renderCell: ({ row }) => {
    return (
      <Typography color={row.humanAntiClockwise === row.cvAntiClockwise ? "#000" : "#FF0000"}>
        {row.humanAntiClockwise}
      </Typography>
    );
  },
};

export const FormatResultsModal: FC<FormatResultsModalProps> = observer(
  ({ isOpen, onClose, snackbarText, selectedCVRunOutsideOfVideo, validationRunOutsideOfVideo }) => {
    const { urlStore, countlineValidationRunStore, cvRunStore, validationRunStore, videoStore } = useStores();

    const [totalFrames, setTotalFrames] = useState<number>(0);
    const [crossingFilter, setCrossingFilter] = useState<GridFilterItem | null>(null);
    const [anprFilter, setAnprFilter] = useState<GridFilterItem | null>(null);
    const [selectedFrameNumberStart, setSelectedFrameNumberStart] = useState<number>(0);
    const [selectedFrameNumberEnd, setSelectedFrameNumberEnd] = useState<number>(totalFrames);

    const [cvTotals, setCvTotals] = useState<
      Map<number, Map<number, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>
    >(new Map<number, Map<number, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>());
    const [valRunTotals, setValRunTotals] = useState<
      Map<number, Map<number, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>
    >(new Map<number, Map<number, Map<boolean, Map<ClassifyingDetectorClassTypeNumber, number>>>>());

    const [selectedCVRun, setSelectedCVRun] = useState<number>(0);

    useEffect(() => {
      if (urlStore.selectedVideo) {
        setTotalFrames(videoStore.fetchVideoTotalFrames(urlStore.selectedVideo));
      }
    }, [urlStore.selectedVideo, videoStore]);

    useEffect(() => {
      setSelectedFrameNumberEnd(totalFrames);
    }, [totalFrames]);

    useEffect(() => {
      const fetchTotalCv = async () => {
        const results = await cvRunStore.CVRunCrossingsRunningTotals(selectedCVRun);
        setCvTotals(results);
      };
      fetchTotalCv();
    }, [selectedCVRun]);

    useEffect(() => {
      if (urlStore.selectedVideo) {
        setTotalFrames(videoStore.fetchVideoTotalFrames(urlStore.selectedVideo));
      }
      const fetchTotalVal = async () => {
        const results = await validationRunStore.ValidationRunCrossingsRunningTotals(validationRunOutsideOfVideo);
        setValRunTotals(results);
      };
      fetchTotalVal();
    }, [isOpen]);

    if (selectedCVRunOutsideOfVideo && selectedCVRunOutsideOfVideo.id !== selectedCVRun) {
      setSelectedCVRun(selectedCVRunOutsideOfVideo.id);
    } else if (!selectedCVRunOutsideOfVideo && selectedCVRun === 0) {
      if (!urlStore.selectedComputerVisionRun) {
        return null;
      }
      setSelectedCVRun(urlStore.selectedComputerVisionRun);
    }

    if (!urlStore.selectedVisionProgram) {
      return null;
    }
    const vpid = urlStore.selectedVisionProgram;

    const onSliderChange = (e: Event, val: number | number[]) => {
      setSelectedFrameNumberStart(val[0]);
      setSelectedFrameNumberEnd(val[1]);
    };

    const onStartChange = (e: ChangeEvent<HTMLInputElement>) => {
      const val = parseInt(e.target.value, 10);
      setSelectedFrameNumberStart(val);
    };

    const onEndChange = (e: ChangeEvent<HTMLInputElement>) => {
      const val = parseInt(e.target.value, 10);
      setSelectedFrameNumberEnd(val);
    };

    const onBlur = () => {
      if (selectedFrameNumberStart < 0) {
        setSelectedFrameNumberStart(0);
      }
      if (selectedFrameNumberEnd > totalFrames) {
        setSelectedFrameNumberEnd(totalFrames);
      }
    };

    const startCV = cvTotals.get(selectedFrameNumberStart);
    const endCV = cvTotals.get(selectedFrameNumberEnd);
    const startVal = valRunTotals.get(selectedFrameNumberStart);
    const endVal = valRunTotals.get(selectedFrameNumberEnd);

    const countlines = Array.from(endCV?.keys() ?? []);
    const classes: ClassifyingDetectorClassTypeNumber[] = [];
    const omittedCountlines: CountlineID[] = [];
    const valRunCrossingsInRange: { [key: CountlineID]: ValidationRunCountlineCrossing[] } = {};
    const cvCrossingsInRange: { [key: CountlineID]: CVRunCountlineCrossing[] } = {};

    startCV?.forEach((countsByDirection, countlineID) => {
      countsByDirection.forEach((countsByClass, clockwise) => {
        countsByClass.forEach((count, classID) => {
          classes.push(classID);
        });
      });
    });

    if (selectedCVRun) {
      const cvRun = cvRunStore.computerVisionRuns.get(selectedCVRun);
      if (cvRun) {
        cvRun.countlineCrossings?.forEach((crossingsByFrame, countlineID) => {
          const crossings: CVRunCountlineCrossing[] = [];
          crossingsByFrame.forEach((crossing, frameNumber) => {
            if (frameNumber >= selectedFrameNumberStart && frameNumber < selectedFrameNumberEnd) {
              crossings.push(crossing);
            }
          });
          cvCrossingsInRange[countlineID] = crossings;
        });
        cvRun.imageSpaceCountlines?.countlines.forEach(countlineConfig => {
          const crossings: ValidationRunCountlineCrossing[] = [];
          if (urlStore.selectedValidationRun) {
            const clValRun = countlineValidationRunStore.countlineValidationRuns.get(
              `${urlStore.selectedValidationRun}-${countlineConfig.countline_id}`
            );
            if (clValRun) {
              if (clValRun.status === "OMITTED") {
                omittedCountlines.push(countlineConfig.countline_id);
              }
              clValRun.validationRunCountlineCrossings.forEach((valRunCrossing, frameNumber) => {
                if (frameNumber >= selectedFrameNumberStart && frameNumber < selectedFrameNumberEnd) {
                  crossings.push(valRunCrossing);
                }
              });
              valRunCrossingsInRange[countlineConfig.countline_id] = crossings;
            }
          }
        });
      }
    }

    const dedupedClasses = _.uniq(classes);

    let counter = 0;
    const rows: ResultRow[] = _.flatten(
      _.flatten(
        dedupedClasses.map(classNumber => {
          return countlines.map(countlineID => {
            counter += 1;
            return {
              id: counter,
              countlineID: countlineID,
              classID: classNumber,
              className: classLookup[classNumber],
              cvAntiClockwise:
                (endCV?.get(countlineID)?.get(false)?.get(classNumber) ?? 0) -
                (startCV?.get(countlineID)?.get(false)?.get(classNumber) ?? 0),
              cvClockwise:
                (endCV?.get(countlineID)?.get(true)?.get(classNumber) ?? 0) -
                (startCV?.get(countlineID)?.get(true)?.get(classNumber) ?? 0),
              humanAntiClockwise:
                (endVal?.get(countlineID)?.get(false)?.get(classNumber) ?? 0) -
                (startVal?.get(countlineID)?.get(false)?.get(classNumber) ?? 0),
              humanClockwise:
                (endVal?.get(countlineID)?.get(true)?.get(classNumber) ?? 0) -
                (startVal?.get(countlineID)?.get(true)?.get(classNumber) ?? 0),
            };
          });
        })
      )
    );

    _.remove(rows, row => _.includes(omittedCountlines, row.countlineID));

    let anprCounter = 0;
    const anprCrossings: ANPRResultRow[] = _.flatten(
      Object.entries(valRunCrossingsInRange).map(([countlineIDStr, crossings]) => {
        const countlineID = parseInt(countlineIDStr, 10);
        return crossings
          .filter(
            crossing =>
              crossing.plate !== "" && crossing.detectionClassV2Id === ClassifyingDetectorClassTypes.LICENSE_PLATE
          )
          .map(anprCrossing => {
            const cvCrossings = cvCrossingsInRange[countlineID];
            let plateMatch: CVRunCountlineCrossing | undefined;
            if (cvCrossings) {
              plateMatch = cvCrossings.find(cvCrossing => {
                return (
                  cvCrossing.trackClass === ClassifyingDetectorClassTypes.LICENSE_PLATE &&
                  (cvCrossing.topRankedPlate === anprCrossing.plate ||
                    _.includes(cvCrossing.otherPlates, anprCrossing.plate))
                );
              });
            }
            anprCounter += 1;
            if (plateMatch) {
              const result: ANPRResultRow = {
                id: anprCounter,
                anprVehicleClass: anprCrossing.anprVehicleClass,
                className: classLookup[anprCrossing.anprVehicleClass ?? 0],
                humanFrameNumber: anprCrossing.frameNumber,
                countlineID: countlineID,
                cvClassCorrect: plateMatch.anprVehicleClass === anprCrossing.anprVehicleClass,
                cvDirectionCorrect: plateMatch.clockwise === anprCrossing.clockwise,
                cvPlateCorrect: true,
                cvPlateRank:
                  plateMatch.topRankedPlate === anprCrossing.plate
                    ? 1
                    : plateMatch.otherPlates.indexOf(anprCrossing.plate) + 1,
                plate: anprCrossing.plate,
                cvFrameNumber: plateMatch.frameNumber,
                cvTrackNumber: plateMatch.trackNumber,
              };
              return result;
            } else {
              const result: ANPRResultRow = {
                id: anprCounter,
                anprVehicleClass: anprCrossing.anprVehicleClass,
                className: classLookup[anprCrossing.anprVehicleClass ?? 0],
                humanFrameNumber: anprCrossing.frameNumber,
                countlineID: countlineID,
                cvClassCorrect: false,
                cvDirectionCorrect: false,
                cvPlateCorrect: false,
                plate: anprCrossing.plate,
              };
              return result;
            }
          });
      })
    ).sort((a, b) => a.humanFrameNumber - b.humanFrameNumber);

    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography>Validation Results</Typography>
        </DialogTitle>
        <DialogContent>
          <FormLabel>
            <Typography variant="body2" id="input-slider">
              Select the start and end frame to view results
            </Typography>
            <Grid container spacing={2} alignItems="center" justifyContent={"space-between"}>
              <Grid item>
                <FormControl>
                  <Input
                    value={selectedFrameNumberStart}
                    size="small"
                    onChange={onStartChange}
                    onBlur={onBlur}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: totalFrames,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <Input
                    value={selectedFrameNumberEnd}
                    size="small"
                    onChange={onEndChange}
                    onBlur={onBlur}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: totalFrames,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs>
              <Slider
                min={0}
                max={totalFrames}
                getAriaLabel={() => "Frame range"}
                value={[selectedFrameNumberStart, selectedFrameNumberEnd]}
                onChange={onSliderChange}
                valueLabelDisplay="auto"
                getAriaValueText={(value: number, index: number) => "Frame" + value.toString(10)}
              />
            </Grid>
          </FormLabel>
          <Grid container>
            <DataGrid
              autoHeight={true}
              rows={rows}
              onFilterModelChange={(model: GridFilterModel, details: GridCallbackDetails<"filter">) => {
                if (model.items.length) {
                  setCrossingFilter(model.items[0]);
                } else {
                  setCrossingFilter(null);
                }
              }}
              columns={[
                countlineIDColumn,
                classNameColumn,
                cvClockwiseColumn,
                valClockwiseColumn,
                cvAntiClockwiseColumn,
                valAntiClockwiseColumn,
              ]}
              initialState={{
                sorting: {
                  sortModel: [{ field: "classID", sort: "asc" }],
                },
              }}
            />
          </Grid>
          <Grid container>
            <DataGrid
              autoHeight={true}
              rows={anprCrossings}
              onFilterModelChange={(model: GridFilterModel, details: GridCallbackDetails<"filter">) => {
                if (model.items.length) {
                  setAnprFilter(model.items[0]);
                } else {
                  setAnprFilter(null);
                }
              }}
              columns={[
                anprPlateColumn,
                anprCountlineColumn,
                anprClassNameColumn,
                anprHumanFrameNumberColumn,
                anprCvPlateCorrectColumn,
                anprCvClassCorrectColumn,
                anprCvDirectionCorrectColumn,
                anprCvPlateRankColumn,
                anprCvTrackNumberColumn,
                anprCvFrameNumberColumn,
              ]}
              initialState={{
                sorting: {
                  sortModel: [{ field: "humanFrameNumber", sort: "asc" }],
                },
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <FreneticityButton onClick={onClose}>Cancel</FreneticityButton>
          <FreneticityButton
            disabled={!cvRunStore.computerVisionRuns.get(selectedCVRun)?.turningMovements}
            onClick={() => {
              let csvData = "Start Zone, End Zone, Start Timestamp, End Timestamp, Track Number, Track Class\n";
              if (cvRunStore.computerVisionRuns.get(selectedCVRun!)!.turningMovements) {
                cvRunStore.computerVisionRuns
                  .get(selectedCVRun!)!
                  .turningMovements!.forEach((movements, startZoneId) => {
                    movements.forEach((movement, endZoneId) => {
                      movement.forEach(row => {
                        csvData += startZoneId;
                        csvData += ",";
                        csvData += endZoneId;
                        csvData += ",";
                        csvData += row.startTimestampMicroseconds.toISOString();
                        csvData += ",";
                        csvData += row.endTimestampMicroseconds.toISOString();
                        csvData += ",";
                        csvData += row.trackNumber;
                        csvData += ",";
                        csvData += classLookup[row.trackClass];
                        csvData += "\n";
                      });
                    });
                  });
                navigator.clipboard.writeText(csvData).then(() => {
                  if (snackbarText) {
                    snackbarText("copied CSV to Clipboard");
                  }
                });
              }
            }}
          >
            Download Turning Movements
          </FreneticityButton>
          <FreneticityButton
            onClick={() => {
              navigator.clipboard
                .writeText(
                  JSON.stringify(
                    {
                      CVCrossings: cvCrossingsInRange,
                      HumanCrossings: valRunCrossingsInRange,
                    },
                    undefined,
                    "  "
                  )
                )
                .then(() => {
                  if (snackbarText) {
                    snackbarText("Copied JSON Crossings to clipboard");
                  }
                });
            }}
          >
            Download JSON crossings in time range
          </FreneticityButton>
          {anprCrossings.length ? (
            <FreneticityButton
              onClick={() => {
                let csvData =
                  "VPID, Countline ID, Frame Number, Plate, Plate Correct, Class Correct, Direction correct, Class ID, Class Name, CV Frame Number, CV Track Number, CV Plate Rank\n";
                anprCrossings
                  .filter(row => {
                    if (anprFilter && anprFilter.operatorValue) {
                      const value = row[anprFilter.columnField];
                      switch (anprFilter.operatorValue) {
                        case "contains":
                          return value.includes(anprFilter.value);
                        case "equals":
                          return anprFilter.value === value;
                        case "startsWith":
                          return value.startsWith(anprFilter.value);
                        case "endsWith":
                          return value.endsWith(anprFilter.value);
                        case "isEmpty":
                          return value === "";
                        case "isNotEmpty":
                          return value !== "";
                        case "isAnyOf":
                          return _.includes(anprFilter.value as string[], value);
                        default:
                          return anprFilter.value === value;
                      }
                    } else {
                      return true;
                    }
                  })
                  .forEach(row => {
                    csvData += vpid;
                    csvData += ",";
                    csvData += row.countlineID;
                    csvData += ",";
                    csvData += row.humanFrameNumber;
                    csvData += ",";
                    csvData += row.plate;
                    csvData += ",";
                    csvData += row.cvPlateCorrect ? 1 : 0;
                    csvData += ",";
                    csvData += row.cvClassCorrect ? 1 : 0;
                    csvData += ",";
                    csvData += row.cvDirectionCorrect ? 1 : 0;
                    csvData += ",";
                    csvData += row.anprVehicleClass ?? 0;
                    csvData += ",";
                    csvData += row.className;
                    csvData += ",";
                    csvData += row.cvFrameNumber ?? "N/A";
                    csvData += ",";
                    csvData += row.cvTrackNumber ?? "N/A";
                    csvData += ",";
                    csvData += row.cvPlateRank ?? "N/A";
                    csvData += "\n";
                  });
                navigator.clipboard.writeText(csvData).then(() => {
                  if (snackbarText) {
                    snackbarText("Copied CSV to clipboard");
                  }
                });
              }}
            >
              Download ANPR CSV
            </FreneticityButton>
          ) : null}
          <FreneticityButton
            onClick={() => {
              let csvData =
                "VPID, Countline ID, Class Number, Class Name, CV Clockwise, Human Clockwise, CV Anti-Clockwise, Human Anti-Clockwise\n";
              rows
                .filter(row => {
                  if (crossingFilter && crossingFilter.operatorValue) {
                    let value = row[crossingFilter.columnField];
                    if (typeof value !== "string") {
                      value = value.toString();
                    }
                    switch (crossingFilter.operatorValue) {
                      case "contains":
                        return value.includes(crossingFilter.value);
                      case "equals":
                        return crossingFilter.value === value;
                      case "startsWith":
                        return value.startsWith(crossingFilter.value);
                      case "endsWith":
                        return value.endsWith(crossingFilter.value);
                      case "isEmpty":
                        return value === "";
                      case "isNotEmpty":
                        return value !== "";
                      case "isAnyOf":
                        return _.includes(crossingFilter.value as string[], value);
                      default:
                        return crossingFilter.value === value;
                    }
                  } else {
                    return true;
                  }
                })
                .forEach(row => {
                  csvData += vpid;
                  csvData += ",";
                  csvData += row.countlineID;
                  csvData += ",";
                  csvData += row.classID;
                  csvData += ",";
                  csvData += row.className;
                  csvData += ",";
                  csvData += row.cvClockwise;
                  csvData += ",";
                  csvData += row.humanClockwise;
                  csvData += ",";
                  csvData += row.cvAntiClockwise;
                  csvData += ",";
                  csvData += row.humanAntiClockwise;
                  csvData += "\n";
                });
              navigator.clipboard.writeText(csvData).then(() => {
                if (snackbarText) {
                  snackbarText("Copied CSV to clipboard");
                }
              });
            }}
          >
            Download Summary CSV
          </FreneticityButton>
        </DialogActions>
      </Dialog>
    );
  }
);
