/* eslint-disable */
// source: vivacity/config_v2/crud_operation_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var vivacity_config_v2_shared_metadata_pb = require('../../vivacity/config_v2/shared_metadata_pb.js');
goog.object.extend(proto, vivacity_config_v2_shared_metadata_pb);
var vivacity_config_v2_primitive_wrappers_pb = require('../../vivacity/config_v2/primitive_wrappers_pb.js');
goog.object.extend(proto, vivacity_config_v2_primitive_wrappers_pb);
goog.exportSymbol('proto.vivacity.config_v2.CRUDOperation', null, global);
goog.exportSymbol('proto.vivacity.config_v2.CRUDOperation.OperationMetadata', null, global);
goog.exportSymbol('proto.vivacity.config_v2.CRUDOperation.OperationMetadata.OperationType', null, global);
goog.exportSymbol('proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status', null, global);
goog.exportSymbol('proto.vivacity.config_v2.CRUDOperationError', null, global);
goog.exportSymbol('proto.vivacity.config_v2.CRUDOperationError.ErrorType', null, global);
goog.exportSymbol('proto.vivacity.config_v2.TransactionalCRUDOperations', null, global);
goog.exportSymbol('proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.TransactionalCRUDOperations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config_v2.TransactionalCRUDOperations.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config_v2.TransactionalCRUDOperations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.TransactionalCRUDOperations.displayName = 'proto.vivacity.config_v2.TransactionalCRUDOperations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.displayName = 'proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.CRUDOperationError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.CRUDOperationError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.CRUDOperationError.displayName = 'proto.vivacity.config_v2.CRUDOperationError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.CRUDOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.CRUDOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.CRUDOperation.displayName = 'proto.vivacity.config_v2.CRUDOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config_v2.CRUDOperation.OperationMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config_v2.CRUDOperation.OperationMetadata.displayName = 'proto.vivacity.config_v2.CRUDOperation.OperationMetadata';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.TransactionalCRUDOperations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.TransactionalCRUDOperations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionMetadata: (f = msg.getTransactionMetadata()) && proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.toObject(includeInstance, f),
    requestOperationsList: jspb.Message.toObjectList(msg.getRequestOperationsList(),
    proto.vivacity.config_v2.CRUDOperation.toObject, includeInstance),
    operationResponsesList: jspb.Message.toObjectList(msg.getOperationResponsesList(),
    proto.vivacity.config_v2.CRUDOperation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.TransactionalCRUDOperations;
  return proto.vivacity.config_v2.TransactionalCRUDOperations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.TransactionalCRUDOperations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata;
      reader.readMessage(value,proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.deserializeBinaryFromReader);
      msg.setTransactionMetadata(value);
      break;
    case 2:
      var value = new proto.vivacity.config_v2.CRUDOperation;
      reader.readMessage(value,proto.vivacity.config_v2.CRUDOperation.deserializeBinaryFromReader);
      msg.addRequestOperations(value);
      break;
    case 3:
      var value = new proto.vivacity.config_v2.CRUDOperation;
      reader.readMessage(value,proto.vivacity.config_v2.CRUDOperation.deserializeBinaryFromReader);
      msg.addOperationResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.TransactionalCRUDOperations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.TransactionalCRUDOperations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getRequestOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.config_v2.CRUDOperation.serializeBinaryToWriter
    );
  }
  f = message.getOperationResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.config_v2.CRUDOperation.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    correlationId: (f = msg.getCorrelationId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    user: (f = msg.getUser()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.toObject(includeInstance, f),
    timestamp: (f = msg.getTimestamp()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata;
  return proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setCorrelationId(value);
      break;
    case 2:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrelationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional PrimitiveWrapper.UUIDValue correlation_id = 1;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.getCorrelationId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 1));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} returns this
*/
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.setCorrelationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} returns this
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.clearCorrelationId = function() {
  return this.setCorrelationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.hasCorrelationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PrimitiveWrapper.UserIDValue user = 2;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.getUser = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue, 2));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} returns this
*/
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} returns this
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue timestamp = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.getTimestamp = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} returns this
*/
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} returns this
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CRUDOperation.OperationMetadata.Status status = 4;
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.getStatus = function() {
  return /** @type {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status} value
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} returns this
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional TransactionMetadata transaction_metadata = 1;
 * @return {?proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.getTransactionMetadata = function() {
  return /** @type{?proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata, 1));
};


/**
 * @param {?proto.vivacity.config_v2.TransactionalCRUDOperations.TransactionMetadata|undefined} value
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations} returns this
*/
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.setTransactionMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations} returns this
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.clearTransactionMetadata = function() {
  return this.setTransactionMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.hasTransactionMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CRUDOperation request_operations = 2;
 * @return {!Array<!proto.vivacity.config_v2.CRUDOperation>}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.getRequestOperationsList = function() {
  return /** @type{!Array<!proto.vivacity.config_v2.CRUDOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config_v2.CRUDOperation, 2));
};


/**
 * @param {!Array<!proto.vivacity.config_v2.CRUDOperation>} value
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations} returns this
*/
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.setRequestOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.config_v2.CRUDOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.CRUDOperation}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.addRequestOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.config_v2.CRUDOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations} returns this
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.clearRequestOperationsList = function() {
  return this.setRequestOperationsList([]);
};


/**
 * repeated CRUDOperation operation_responses = 3;
 * @return {!Array<!proto.vivacity.config_v2.CRUDOperation>}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.getOperationResponsesList = function() {
  return /** @type{!Array<!proto.vivacity.config_v2.CRUDOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config_v2.CRUDOperation, 3));
};


/**
 * @param {!Array<!proto.vivacity.config_v2.CRUDOperation>} value
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations} returns this
*/
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.setOperationResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.config_v2.CRUDOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config_v2.CRUDOperation}
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.addOperationResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.config_v2.CRUDOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config_v2.TransactionalCRUDOperations} returns this
 */
proto.vivacity.config_v2.TransactionalCRUDOperations.prototype.clearOperationResponsesList = function() {
  return this.setOperationResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.CRUDOperationError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.CRUDOperationError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.CRUDOperationError.toObject = function(includeInstance, msg) {
  var f, obj = {
    originatingMessage: (f = msg.getOriginatingMessage()) && proto.vivacity.config_v2.CRUDOperation.toObject(includeInstance, f),
    errorType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.CRUDOperationError}
 */
proto.vivacity.config_v2.CRUDOperationError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.CRUDOperationError;
  return proto.vivacity.config_v2.CRUDOperationError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.CRUDOperationError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.CRUDOperationError}
 */
proto.vivacity.config_v2.CRUDOperationError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.CRUDOperation;
      reader.readMessage(value,proto.vivacity.config_v2.CRUDOperation.deserializeBinaryFromReader);
      msg.setOriginatingMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config_v2.CRUDOperationError.ErrorType} */ (reader.readEnum());
      msg.setErrorType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.CRUDOperationError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.CRUDOperationError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.CRUDOperationError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginatingMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config_v2.CRUDOperation.serializeBinaryToWriter
    );
  }
  f = message.getErrorType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config_v2.CRUDOperationError.ErrorType = {
  UNKNOWN_ERROR_TYPE: 0,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500
};

/**
 * optional CRUDOperation originating_message = 1;
 * @return {?proto.vivacity.config_v2.CRUDOperation}
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.getOriginatingMessage = function() {
  return /** @type{?proto.vivacity.config_v2.CRUDOperation} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.CRUDOperation, 1));
};


/**
 * @param {?proto.vivacity.config_v2.CRUDOperation|undefined} value
 * @return {!proto.vivacity.config_v2.CRUDOperationError} returns this
*/
proto.vivacity.config_v2.CRUDOperationError.prototype.setOriginatingMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.CRUDOperationError} returns this
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.clearOriginatingMessage = function() {
  return this.setOriginatingMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.hasOriginatingMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorType error_type = 2;
 * @return {!proto.vivacity.config_v2.CRUDOperationError.ErrorType}
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.getErrorType = function() {
  return /** @type {!proto.vivacity.config_v2.CRUDOperationError.ErrorType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config_v2.CRUDOperationError.ErrorType} value
 * @return {!proto.vivacity.config_v2.CRUDOperationError} returns this
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string error_message = 3;
 * @return {string}
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config_v2.CRUDOperationError} returns this
 */
proto.vivacity.config_v2.CRUDOperationError.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.CRUDOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.CRUDOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.CRUDOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.CRUDOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationMetadata: (f = msg.getOperationMetadata()) && proto.vivacity.config_v2.CRUDOperation.OperationMetadata.toObject(includeInstance, f),
    sharedMetadata: (f = msg.getSharedMetadata()) && vivacity_config_v2_shared_metadata_pb.SharedMetadata.toObject(includeInstance, f),
    crudOperationError: (f = msg.getCrudOperationError()) && proto.vivacity.config_v2.CRUDOperationError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.CRUDOperation}
 */
proto.vivacity.config_v2.CRUDOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.CRUDOperation;
  return proto.vivacity.config_v2.CRUDOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.CRUDOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.CRUDOperation}
 */
proto.vivacity.config_v2.CRUDOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config_v2.CRUDOperation.OperationMetadata;
      reader.readMessage(value,proto.vivacity.config_v2.CRUDOperation.OperationMetadata.deserializeBinaryFromReader);
      msg.setOperationMetadata(value);
      break;
    case 2:
      var value = new vivacity_config_v2_shared_metadata_pb.SharedMetadata;
      reader.readMessage(value,vivacity_config_v2_shared_metadata_pb.SharedMetadata.deserializeBinaryFromReader);
      msg.setSharedMetadata(value);
      break;
    case 3:
      var value = new proto.vivacity.config_v2.CRUDOperationError;
      reader.readMessage(value,proto.vivacity.config_v2.CRUDOperationError.deserializeBinaryFromReader);
      msg.setCrudOperationError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.CRUDOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.CRUDOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.CRUDOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.CRUDOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config_v2.CRUDOperation.OperationMetadata.serializeBinaryToWriter
    );
  }
  f = message.getSharedMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_config_v2_shared_metadata_pb.SharedMetadata.serializeBinaryToWriter
    );
  }
  f = message.getCrudOperationError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.config_v2.CRUDOperationError.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config_v2.CRUDOperation.OperationMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    correlationId: (f = msg.getCorrelationId()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.toObject(includeInstance, f),
    user: (f = msg.getUser()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.toObject(includeInstance, f),
    timestamp: (f = msg.getTimestamp()) && vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.toObject(includeInstance, f),
    isReadConstraint: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config_v2.CRUDOperation.OperationMetadata;
  return proto.vivacity.config_v2.CRUDOperation.OperationMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.deserializeBinaryFromReader);
      msg.setCorrelationId(value);
      break;
    case 4:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 5:
      var value = new vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue;
      reader.readMessage(value,vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReadConstraint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config_v2.CRUDOperation.OperationMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCorrelationId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue.serializeBinaryToWriter
    );
  }
  f = message.getIsReadConstraint();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.OperationType = {
  UNKNOWN_OPERATION_TYPE: 0,
  CREATE: 1,
  READ: 2,
  UPDATE: 3,
  DELETE: 4
};

/**
 * @enum {number}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status = {
  UNKNOWN_STATUS: 0,
  REQUEST: 1,
  SUCCESS: 2,
  ERROR: 3
};

/**
 * optional OperationType operation_type = 1;
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.OperationType}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.getOperationType = function() {
  return /** @type {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.OperationType} value
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Status status = 2;
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.getStatus = function() {
  return /** @type {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata.Status} value
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional PrimitiveWrapper.UUIDValue correlation_id = 3;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.getCorrelationId = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UUIDValue, 3));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UUIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
*/
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.setCorrelationId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.clearCorrelationId = function() {
  return this.setCorrelationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.hasCorrelationId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PrimitiveWrapper.UserIDValue user = 4;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.getUser = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.UserIDValue, 4));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.UserIDValue|undefined} value
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
*/
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.hasUser = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PrimitiveWrapper.TimestampValue timestamp = 5;
 * @return {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.getTimestamp = function() {
  return /** @type{?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_primitive_wrappers_pb.PrimitiveWrapper.TimestampValue, 5));
};


/**
 * @param {?proto.vivacity.config_v2.PrimitiveWrapper.TimestampValue|undefined} value
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
*/
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_read_constraint = 6;
 * @return {boolean}
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.getIsReadConstraint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config_v2.CRUDOperation.OperationMetadata} returns this
 */
proto.vivacity.config_v2.CRUDOperation.OperationMetadata.prototype.setIsReadConstraint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional OperationMetadata operation_metadata = 1;
 * @return {?proto.vivacity.config_v2.CRUDOperation.OperationMetadata}
 */
proto.vivacity.config_v2.CRUDOperation.prototype.getOperationMetadata = function() {
  return /** @type{?proto.vivacity.config_v2.CRUDOperation.OperationMetadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.CRUDOperation.OperationMetadata, 1));
};


/**
 * @param {?proto.vivacity.config_v2.CRUDOperation.OperationMetadata|undefined} value
 * @return {!proto.vivacity.config_v2.CRUDOperation} returns this
*/
proto.vivacity.config_v2.CRUDOperation.prototype.setOperationMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.CRUDOperation} returns this
 */
proto.vivacity.config_v2.CRUDOperation.prototype.clearOperationMetadata = function() {
  return this.setOperationMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.CRUDOperation.prototype.hasOperationMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SharedMetadata shared_metadata = 2;
 * @return {?proto.vivacity.config_v2.SharedMetadata}
 */
proto.vivacity.config_v2.CRUDOperation.prototype.getSharedMetadata = function() {
  return /** @type{?proto.vivacity.config_v2.SharedMetadata} */ (
    jspb.Message.getWrapperField(this, vivacity_config_v2_shared_metadata_pb.SharedMetadata, 2));
};


/**
 * @param {?proto.vivacity.config_v2.SharedMetadata|undefined} value
 * @return {!proto.vivacity.config_v2.CRUDOperation} returns this
*/
proto.vivacity.config_v2.CRUDOperation.prototype.setSharedMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.CRUDOperation} returns this
 */
proto.vivacity.config_v2.CRUDOperation.prototype.clearSharedMetadata = function() {
  return this.setSharedMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.CRUDOperation.prototype.hasSharedMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CRUDOperationError CRUD_operation_error = 3;
 * @return {?proto.vivacity.config_v2.CRUDOperationError}
 */
proto.vivacity.config_v2.CRUDOperation.prototype.getCrudOperationError = function() {
  return /** @type{?proto.vivacity.config_v2.CRUDOperationError} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config_v2.CRUDOperationError, 3));
};


/**
 * @param {?proto.vivacity.config_v2.CRUDOperationError|undefined} value
 * @return {!proto.vivacity.config_v2.CRUDOperation} returns this
*/
proto.vivacity.config_v2.CRUDOperation.prototype.setCrudOperationError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config_v2.CRUDOperation} returns this
 */
proto.vivacity.config_v2.CRUDOperation.prototype.clearCrudOperationError = function() {
  return this.setCrudOperationError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config_v2.CRUDOperation.prototype.hasCrudOperationError = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.vivacity.config_v2);
